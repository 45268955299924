import MuiDialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Chip, Container, DialogContent,  FormControl,  FormHelperText,  Grid, IconButton, Input, InputAdornment,
   InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { format } from '../../../_helpers/format';
import { DataDigaloProps } from '../../../models/data_digalo_props';
import { inscriptionActions } from '../../../_actions/inscription.actions';
import { Lending } from '../../../models/lending/lending';
import { Adviser } from '../../hierarchy/models/adviser';
import { paymentActions } from '../../../_actions/payment.action';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PaymentRequest } from '../../../models/payment/payment_request';
import WarningIcon from '@mui/icons-material/Warning';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import { Fee } from '../../../models/lending/fee';
import { PaymentRequestDetail } from '../../../models/payment/payment_request_detail';




export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = ((props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle   {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close"  onClick={onClose}
            sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}

>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});





export interface SimpleDialogProps {
    onClose: (value: Lending) => void;
  }
  
  function DialogRecordDetail(props: SimpleDialogProps) {
    const { onClose} = props;
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();

    const openLending = useSelector((state:any) => state.payments.openLending as DataDigaloProps<PaymentRequest> );
    const loading = useSelector((state:any) => state.payments.loading);
    const [submitted, setSubmitted] = React.useState(false);
    const  alert  = useSelector((state: any) => state.alert.message);
    const user = useSelector((state:any) => state.authentication.user);
    const advisers = useSelector((state:any)=> state.inscriptions.advisers as Adviser[])
    const [inputs, setInputs] = React.useState<PaymentRequest>(openLending?.select);
    const [totalPay, setTotalPay] = React.useState(0);
    useEffect(() => {
      setInputs(openLending?.select)
      setSubmitted(false)
    }, [openLending]);


    useEffect(() => {
      setInputs(new PaymentRequest(openLending.select));
      setSubmitted(false);
  }, [openLending.select]);

    
    useEffect(() => {
      if(advisers.length == 0){
        dispatch(inscriptionActions.getAdviser());
      }
    }, [advisers]);

  
    const statesLeft: number[] = [1, 4];

    const handleClose = (lending?:Lending) => {
      //onClose(lending ?? new Lending());//TODO 
      setSubmitted(false)
      dispatch(paymentActions.setOpenLendingScreen({
        open: false,
        select :lending ?? new Lending(),
        type : 0,
        loading:false
    }));
    };


    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.term && inputs.amount && openLending.select.customerID && inputs.durationID && inputs.adviserID) {
         // dispatch(inscriptionActions.calculateFeeRequest(inputs));
      }
    }

    function handleEdit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.term && inputs.amount && openLending.select.customerID && inputs.durationID && (inputs.fees?? []).length > 0 && inputs.adviserID) {
        inputs.postingBy = user.userID;
          // get return url from location state or default to home page
          // const { from } = location .state || { from: { pathname: "/" } };
         // dispatch(inscriptionActions.edit(inputs));
      }
    }

    function handlePosting(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.term && inputs.amount && openLending.select.customerID && inputs.durationID && (inputs.fees?? []).length > 0 && inputs.adviserID) {
        inputs.postingBy = user.userID;
          // get return url from location state or default to home page
         // dispatch(inscriptionActions.posting(inputs));
      }
    }

    function handleChange(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
  }


  function handleAdd(e:any) {
    e.preventDefault();
    setSubmitted(true);
    if (inputs.term && inputs.amount  && inputs.durationID && (inputs.fees?? []).length > 0 && inputs.adviserID) {
      inputs.createBy = user.userID;


      console.log(inputs)
        // get return url from location state or default to home page
        // const { from } = location .state || { from: { pathname: "/" } };
        // setTotalPay(0)
        inputs.userID = user.UserID;
        dispatch(paymentActions.post(inputs));
    }
  }

  function getTotal(){
    if((inputs.fees??[]).length>0){
      return (inputs.fees??[]).filter((item:PaymentRequestDetail)=> statesLeft.includes(item?.stateID??0)).map((item)=> ( isNaN(item?.pay??0)? 0: item?.pay??0  )).reduce((a, b) => a + b)
    }
    return 0;
  }

  const columns: GridColDef[] = [
    { field: 'lineNumber', headerName: '# Cuota', width: 95 },
    {
      field: 'expireDate',
      headerName: 'Fecha',
      width: 115,
      type:'date',
      valueFormatter: (params) => {
        if(params?.value){
          return new Date(params?.value as string).toLocaleDateString().substr(0,10)
        } 
     },

    },
    {
      field: 'day',
      headerName: 'Día',
      width: 100,
    },
    {
      field: 'amount',
      headerName: 'Cuota',
      width: 150,
      valueFormatter: (params) => {
        if(params?.value){
          var data: number = parseFloat(params?.value as string);
          return "L. "+ format( data,2)
        } 
     },
    },
    {
      field: 'fine',
      headerName: 'Multa',
      width: 130,
      valueFormatter: (params) => {
        if(params?.value){
          var data: number = parseFloat(params?.value as string);
          return "L. "+ format( data,2)
        } 
     },
    },
    {
      field: 'pay',
      headerName: 'Pago',
      width: 160,
      editable:false,
                
      valueFormatter: (params) => {
        if(params?.value){
          var data: number = parseFloat(params?.value as string);
          return "L. "+ format( data,2)
        } 
     },

     renderCell: (params) => {
      const handleChange = (prop:any) =>{
   
        let index = (inputs.fees??[]).findIndex((x)=> x.lineNumber == params.row.lineNumber);


        if(index> -1){
          let pay = parseFloat( prop.target.value);
          let amount =parseFloat((inputs.fees??[])[index].amount?.toString()??"0");
          let fine = parseFloat((inputs.fees??[])[index].fine?.toString()??"0");


          pay = isNaN(pay) ? 0: pay;
          amount = isNaN(amount) ? 0: amount;
          fine = isNaN(fine) ? 0: fine;

          (inputs.fees??[])[index].pay   = pay;
          (inputs.fees??[])[index].diff = pay > 0? -1 * ( (amount)  + (fine) - (pay) ): 0;

          setInputs(inputs);

          let total = 0;
          
          var feesPendient = (inputs.fees??[]).filter((item:PaymentRequestDetail)=> statesLeft.includes(item?.stateID??0))
          for (var i of feesPendient) {
            total += isNaN(i?.pay??0)? 0: i?.pay??0;
          }
         total = isNaN(total)? 0: total;
          setTotalPay(total)
        }
      };
    
      return (
        <>
        {
          (!params.row.isPay) ? 
            <FormControl  >
                <TextField
                  //label="Size"
                
                // defaultValue="Small"
                  id={params.row.lineNumber}
                  type="number"
                  variant="outlined"
                  onChange={handleChange}
                  size="small"
                />
                          
          </FormControl>
          :

          <span>{"L. "+ format( params.row.pay,2)}</span>
        }
        </>
      );
    },

    



    },
    {
      field: 'diff',
      headerName: 'Diferencia',
      width: 200,
      valueFormatter: (params) => {
        if(params?.value){
          var data: number = parseFloat(params?.value as string);
          return "L. "+ format( data,2)
        } 
     },
    },

    {
      field: 'stateID',
      headerName:'Estado',
      width:150,
      renderCell: (params: GridRenderCellParams) => (
          <Box>
          {
            ((params?.row.stateID) == 2 ) ?
              <Chip
                    icon={<DoneIcon />}
                    label={params?.row.state}
                    color="primary"
                    size="small"
                    style={{border: "1px solid #00c853", color: "#00c853"}}
                    variant="outlined"
                  />
            :
            ((params?.row.stateID) == 3 ) ?
              <Chip
                  icon={<WarningIcon />}
                  label={params?.row.state}
                  color="primary"
                  size="small"
                  style={{border: "1px solid #8bc34a", color: "#8bc34a"}}
                  variant="outlined"
                />
            :
            ((params?.row.stateID) == 4 ) ?
            <Box>
              
             
              <Chip
                  icon={<ErrorIcon />}
                  label={params?.row.state}
                  color="primary"
                  size="small"
                  style={{border: "1px solid #ff1744", color: "#ff1744"}}
                  variant="outlined"
                />
            </Box>
            :
            ((params?.row.stateID) == 1 ) ?
            <Box>
              
             
              <Chip
                  icon={<ErrorIcon />}
                  label={params?.row.state}
                  color="primary"
                  size="small"
                  style={{border: "1px solid #ff9800", color: "#ff9800"}}
                  variant="outlined"
                />
            </Box>
            : <Box></Box>
        
          }
        </Box>
      )
  },
  ];

    return (
      <Dialog id="recordDialog" fullWidth={true} maxWidth="lg"  aria-labelledby="simple-dialog-title" open={openLending?.open ??false}>
        <DialogTitle onClose={()=>{handleClose(new Lending())}}  id="simple-dialog-title" > Solicitud: {openLending.select.lendingID} </DialogTitle>
   
        <DialogContent dividers>    

        <Box my={2}>
            <Container >
                <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
                    
                <Box  my={2} mx={7}>
                    <Typography style={{color: "#f50057"}} variant="h6" gutterBottom component="div">
                       Total a Pagar: L. {format(getTotal(),2)}
                    </Typography>
                </Box>
{/* 
                    <Box my={2} >
                        <Button disabled={(inputs.fees??[]).length==0} variant="contained" color="primary" onClick={(e)=>{
                            console.log(inputs?.fees??[])
                            }
                        }>Calcular Pago </Button>
                    </Box> */}
                    <Box my={2} >
                        <Button disabled={(inputs.fees??[]).length==0} variant="contained" color="primary" onClick={(e)=>{
                            handleAdd(e);
                            }
                        }>Pagar </Button>
                    </Box>

                </Grid>
                {/* <Typography variant="h6" component="h2">
                    Solicitud Préstamo
                </Typography> */}
            <Grid container spacing={3}>

                    <Grid item xs={4}>
                    <TextField
                                    id="selectCustomer"
                                    label="Cliente"
                                    type="text"
                                    placeholder="Cliente"
                                    fullWidth
                                    size="small"
                                    name="customer"
                                    aria-readonly={true}
                                    value={inputs?.customer || ''}
                                    //error={(submitted && !inputs?.interest)}   helperText={(submitted && !inputs?.interest) ? "Es Requerido":""}
                        
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />                            
                    </Grid>
                    
                    <Grid item xs={3}> 
                        <TextField
                                    id="amount"
                                    label="Monto"
                                    type="text"
                                    placeholder="Monto"
                                    fullWidth
                                    size="small"
                                    name="amount"
                                    aria-readonly={true}
                                    // disabled={true}
                                    value={inputs?.amount || ""}
                                    //error={(submitted && !inputs?.interest)}   helperText={(submitted && !inputs?.interest) ? "Es Requerido":""}
                                    //onChange={handleChange}
                        
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>


                    <Grid item xs={3}> 
                    <TextField
                                    id="amountPay"
                                    label="Monto Pagado"
                                    type="text"
                                    placeholder="Monto Pagado"
                                    fullWidth
                                    size="small"
                                    name="amountPay"
                                    // disabled={true}
                                    value={inputs?.amountPay || ""}
                                    //error={(submitted && !inputs?.interest)}   helperText={(submitted && !inputs?.interest) ? "Es Requerido":""}
                                    aria-readonly={true}
                                    //onChange={handleChange}
                        
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>

                    <Grid item xs={2}> 
                        <TextField
                                    id="fine"
                                    label="Multa"
                                    type="text"
                                    size="small"
                                    placeholder="Multa"
                                    fullWidth
                                    name="fine"
                                    //disabled={true}
                                    value={inputs?.amountFine || ""}
                                    aria-readonly={true}
                                    //error={(submitted && !inputs?.interest)}   helperText={(submitted && !inputs?.interest) ? "Es Requerido":""}
                                    //onChange={handleChange}
                        
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>
                    <Grid item xs={3}> 
                                <TextField
                                    id="term"
                                    label="Plazo en numero"
                                    type="text"
                                    placeholder="Plazo en numero"
                                    fullWidth
                                    size="small"
                                    name="term"
                                    //disabled={true}
                                    aria-readonly={true}
                                    value={inputs?.term  || ''}
                                    error={(submitted && !inputs?.term)}   helperText={(submitted && !inputs?.term) ? "Es Requerido":""}
                                    //onChange={handleChange}
                              
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>
                    <Grid item xs={3}> 
                                <TextField
                                    id="pendientTerm"
                                    label="Plazo en numero pendientes"
                                    type="text"
                                    placeholder="Plazo en numero pendientes"
                                    fullWidth
                                    size="small"
                                    name="pendientTerm"
                                    //disabled={true}
                                    value={inputs?.pendientTerm  || ''}
                                    aria-readonly={true}
                                    error={(submitted && !inputs?.pendientTerm)}   helperText={(submitted && !inputs?.pendientTerm) ? "Es Requerido":""}
                                    //onChange={handleChange}
                              
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>


                    <Grid item xs={3}> 
                        <TextField
                                    id="amountExpired"
                                    label="Monto Vencido"
                                    type="text"
                                    placeholder="Monto Vencido"
                                    fullWidth
                                    size="small"
                                    name="amountExpired"
                                    //disabled={true}
                                    aria-readonly={true}
                                    value={inputs?.amountExpired || ''}
                                    //error={(submitted && !inputs?.amountExpired)}   helperText={(submitted && !inputs?.amount) ? "Es Requerido":""}
                                    //onChange={handleChange}
                        
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>


                    <Grid item xs={3}> 
                        <FormControl >
                        <InputLabel shrink  id="label" >
                            Asesor
                        </InputLabel>
                            <Select
                                value={inputs?.adviserID || ''}
                                onChange={handleChange}
                                displayEmpty
                                labelId="label" 
                                label="Asesor"
                                size="small"
                                placeholder="Seleccione un Asesor"
                                name="adviserID"
                                error={(submitted && !inputs?.adviserID)}   
                                // helperText=
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {
                                advisers.map((item: Adviser)=>{
                                    return <MenuItem value={item.adviserID}> {item.adviserID} - {item.name}</MenuItem>
                                })
                                }
                            </Select>
                            {
                                (submitted && !inputs?.adviserID) ? <FormHelperText error >Es requerido</FormHelperText>:""
                            }
                        </FormControl>

                    </Grid>

                    
                </Grid>
                <Box my={4}>
                    <Typography variant="h6" gutterBottom component="div">
                       Cuotas Pendientes
                    </Typography>
                </Box>
            
                <DataGrid autoHeight 
                    rows={inputs?.fees??[]}
                    loading= {loading}
                    getRowId={(r) => r.lineNumber}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    nonce="No se encontraron resultados"
                    density="compact"
                    checkboxSelection={false}
                    disableSelectionOnClick
                />
            </Container>
        </Box>
  
        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { DialogRecordDetail };