import { Fee } from "./fee";
import { LendingImage } from "./lending_image";

export class Lending {
    lendingID?: string;
    createDate?: Date;
    postingDate?: Date;
    createBy?: number;
    postingBy?: number;
    customer?: string;
    customerID?: string;
    adviserID?: number;
    amount?: number; 
    amountAfterInterest?: number;
    durationID?: number;   
    term?: number;
    interest?: number;
    status?: number;
    duration?: string;
    fees?: Fee[];
    images?:LendingImage[];
    userID?: number;
    
    constructor(datos?: Lending) {
      if (datos != null) {
        this.lendingID = datos.lendingID;
        this.createDate = datos.createDate;
        this.postingDate = datos.postingDate;
        this.createBy = datos.createBy;
        this.createBy = datos.createBy;
        this.customer = datos.customer;
        this.customerID = datos.customerID;
        this.amount = datos.amount;
        this.amountAfterInterest = datos.amountAfterInterest;
        this.durationID = datos.durationID;
        this.adviserID = datos.adviserID;
        this.term = datos.term;
        this.interest = datos.interest;
        this.status = datos.status;
        this.duration = datos.duration;
        this.fees = datos.fees;
        this.images = datos.images;
        this.userID = datos.userID;
        return;
      }
    }
  }
