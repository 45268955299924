import axios from 'axios';
import environment from '../../environment';
import { FilterParams } from './models/filter_params';


export const reportService = {
  getPaymentByDate,
  getFinancialStateByDate,
  getPlacingByDate,
  getRecoverByDate
};


 function getPaymentByDate(filter: FilterParams) {
   var filterOK =  {
    fromDate: filter.fromDate,
    toDate: filter.toDate,
    adviserIds: (filter.adviserIds??[]).map((item)=> item.adviserID)
   }

    return axios.post(environment.apiUri + "/report/PaymentByDate" , filterOK)
  };
 function getFinancialStateByDate(fromDate: string, toDate:string) {
    return axios.get(`${environment.apiUri}/report/GetFinancialState/${fromDate}/${toDate}`)
  };


 function getPlacingByDate(filter: FilterParams) {
   var filterOK =  {
    fromDate: filter.fromDate,
    toDate: filter.toDate,
    adviserIds: (filter.adviserIds??[]).map((item)=> item.adviserID)
   }

    return axios.post(environment.apiUri + "/report/PlacingByDate" , filterOK)
  };


 function getRecoverByDate(filter: FilterParams) {
   var filterOK =  {
    fromDate: filter.fromDate,
    toDate: filter.toDate,
    adviserIds: (filter.adviserIds??[]).map((item)=> item.adviserID)
   }

    return axios.post(environment.apiUri + "/report/RecoverByDate" , filterOK)
  };


  // function edit(setting: Setting) {
  //   return axios.put(environment.apiUri + "/common/editSetting",setting )
  // };
  
 