export const paymentConstants = {
    GET_ADVISER_REQUEST: 'GET_ADVISER_REQUEST',
    GET_ADVISER_SUCCESS: 'GET_ADVISER_SUCCESS',
    GET_ADVISER_FAILURE: 'GET_ADVISER_FAILURE' , 


    GET_PENDIENT_PAYMENT_REQUEST: 'GET_PENDIENT_PAYMENT_REQUEST',
    GET_PENDIENT_PAYMENT_SUCCESS: 'GET_PENDIENT_PAYMENT_SUCCESS',
    GET_PENDIENT_PAYMENT_FAILURE: 'GET_PENDIENT_PAYMENT_FAILURE' , 

    POSTING_PAYMENT_REQUEST: 'POSTING_PAYMENT_REQUEST',
    POSTING_PAYMENT_SUCCESS: 'POSTING_PAYMENT_SUCCESS',
    POSTING_PAYMENT_FAILURE: 'POSTING_PAYMENT_FAILURE' , 

    POST_PAYMENT_REQUEST: 'POST_PAYMENT_REQUEST',
    POST_PAYMENT_SUCCESS: 'POST_PAYMENT_SUCCESS',
    POST_PAYMENT_FAILURE: 'POST_PAYMENT_FAILURE' , 



    GET_EXPEDIENT_REQUEST: 'GET_EXPEDIENT_REQUEST',
    GET_EXPEDIENT_SUCCESS: 'GET_EXPEDIENT_SUCCESS',
    GET_EXPEDIENT_FAILURE: 'GET_EXPEDIENT_FAILURE' , 



    

    SET_OPEN_CUSTOMER_SUCCESS: 'SET_OPEN_CUSTOMER_SUCCESS' , 
    SET_OPEN_REQUEST_SUCCESS: 'SET_OPEN_REQUEST_SUCCESS' , 
    SET_OPEN_LENDING_SUCCESS: 'SET_OPEN_LENDING_SUCCESS' , 
};