import { useState, useEffect } from "react";
import {  myHistory } from "../_helpers/history";
import { useDispatch, useSelector } from "react-redux";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { NavLink } from "react-router-dom";
import { authActions } from "../_actions/auth.actions";
import { alertActions } from "../_actions/alert.actions";
import { AppBar, Badge,  Box,  Toolbar, Typography } from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import { AccountCircle } from "@mui/icons-material";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     grow: {
//       flexGrow: 1,
//     },
//     menuButton: {
//       marginRight: theme.spacing(2),
//     },
//     title: {
//       display: 'none',
//       [theme.breakpoints.up('sm')]: {
//         display: 'block',
//       },
//     },
//     search: {
//       position: 'relative',
//       borderRadius: theme.shape.borderRadius,
//       backgroundColor: alpha(theme.palette.common.white, 0.15),
//       '&:hover': {
//         backgroundColor: alpha(theme.palette.common.white, 0.25),
//       },
//       marginRight: theme.spacing(2),
//       marginLeft: 0,
//       width: '100%',
//       [theme.breakpoints.up('sm')]: {
//         marginLeft: theme.spacing(3),
//         width: 'auto',
//       },
//     },
//     searchIcon: {
//       padding: theme.spacing(0, 2),
//       height: '100%',
//       position: 'absolute',
//       pointerEvents: 'none',
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//     },
//     inputRoot: {
//       color: 'inherit',
//     },
//     inputInput: {
//       padding: theme.spacing(1, 1, 1, 0),
//       // vertical padding + font size from searchIcon
//       paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
//       transition: theme.transitions.create('width'),
//       width: '100%',
//       [theme.breakpoints.up('md')]: {
//         width: '20ch',
//       },
//     },
//     sectionDesktop: {
//       display: 'none',
//       [theme.breakpoints.up('md')]: {
//         display: 'flex',
//       },
//     },
//     sectionMobile: {
//       display: 'flex',
//       [theme.breakpoints.up('md')]: {
//         display: 'none',
//       },
//     },
//   }),
// );
const Header = () => {
  const [content, setContent] = useState("");
  // const classes = useStyles();
  
  // useEffect(() => {
  //   userService.getPublicContent().then(
  //     (response) => {
  //       setContent(response.data);
  //     },
  //     (error) => {
  //       const _content =
  //         (error.response && error.response.data) ||
  //         error.message ||
  //         error.toString();

  //       setContent(_content);
  //     }
  //   );
  // }, []);

  // const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  // const [showAdminBoard, setShowAdminBoard] = useState(false);

  const user = useSelector((state:any) => state.authentication.user);
  const dispatch = useDispatch();

  useEffect(() => {
    myHistory.listen((location) => {
      dispatch(alertActions.clear()); // clear message when changing location
    });
  }, [dispatch]);

  useEffect(() => {
    if (user) {
        // setShowModeratorBoard(currentUser.hierarchyID == 1);//.roles.includes("ROLE_MODERATOR")
        // setShowAdminBoard(currentUser.hierarchyID ==0);//roles.includes("ROLE_ADMIN")
    }
  }, [user]);

  const logOut = () => {
    dispatch(authActions.logout());
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElSetting, setAnchorElSetting] = React.useState<null | HTMLElement>(null);
  const [anchorElReport, setAnchorElReport] = React.useState<null | HTMLElement>(null);
  const [anchorElLending, setAnchorElLending] = React.useState<null | HTMLElement>(null);
  const [anchorElSpent, setAnchorElSpent] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickSetting = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElSetting(event.currentTarget);
  };
  const handleClickReport = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElReport(event.currentTarget);
  };
  const handleClickLending = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLending(event.currentTarget);
  };
  const handleClickSpent = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElSpent(event.currentTarget);
  };

  const handleCloseSetting = () => {
    setAnchorElSetting(null);
  };

  const handleCloseReport = () => {
    setAnchorElReport(null);
  };
  const handleCloseLending = () => {
    setAnchorElLending(null);
  };
  const handleCloseSpent = () => {
    setAnchorElSpent(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Mi Perfil</MenuItem>
      <MenuItem onClick={logOut}>Salir</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>

      <MenuItem >
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="secondary">

        <Toolbar  >
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}
          <Box m={1}>
            <img width={80} src="./logo.png" alt="" />
          </Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        
        
          <div style={{margin: 5, color: "white !import"}}>
            <Button exact={true}  activeClassName='active' variant="contained" disableElevation color="secondary" component={NavLink}  to={"/"}  >Inicio</Button>
            <Button exact={true} activeClassName='active' variant="contained" disableElevation color="secondary" component={NavLink}  to={"/clientes"} >Clientes</Button>
    
            <Button  aria-controls="menu-spent" aria-haspopup="true"  variant="contained" disableElevation color="secondary" onClick={handleClickSpent}>
                Gastos
            </Button>
            <Menu id="menu-spent" anchorEl={anchorElSpent} keepMounted open={Boolean(anchorElSpent)} onClose={handleCloseSpent} >
              <MenuItem activeClassName='active'  component={NavLink} color="secondary" to={"/gastos"} onClick={handleCloseSpent}>Gastos</MenuItem>
              <MenuItem activeClassName='active'  component={NavLink} color="secondary" to={"/tipo-gastos"} onClick={handleCloseSpent}>Tipos de Gasto</MenuItem>

            </Menu>



            <Button  aria-controls="menu-lending" aria-haspopup="true"  variant="contained" disableElevation color="secondary" onClick={handleClickLending}>
                Prestamos
            </Button>
            <Menu id="menu-lending" anchorEl={anchorElLending} keepMounted open={Boolean(anchorElLending)} onClose={handleCloseLending} >
              <MenuItem activeClassName='active'  component={NavLink} color="secondary" to={"/solicitud"} onClick={handleCloseLending}>Solicitar</MenuItem>
              <MenuItem activeClassName='active'  component={NavLink} color="secondary" to={"/solicitudes"} onClick={handleCloseLending}>Solicitudes Pendientes</MenuItem>
              <MenuItem activeClassName='active'  component={NavLink} color="secondary" to={"/expediente"} onClick={handleCloseLending}>Prestamos Pendientes</MenuItem>
                {/* <Button exact={true} activeClassName='active' variant="contained" disableElevation color="secondary" component={NavLink}  to={"/solicitud"}  >Solicitud Prestamo</Button>
                <Button exact={true} activeClassName='active' variant="contained" disableElevation color="secondary" component={NavLink} to={"/solicitudes"}  >Solicitudes</Button> */}
                {/* <Button exact={true} activeClassName='active' variant="contained" disableElevation color="secondary" component={NavLink}  to={"/expediente"}  >Prestamos Pendientes</Button> */}
            </Menu>


            
            {/* <Button activeClassName='active' variant="contained" disableElevation color="secondary" component={NavLink} to={"/pagar"}  >Pagar</Button> */}

            <Button  aria-controls="menu-setting" aria-haspopup="true"  variant="contained" disableElevation color="secondary" onClick={handleClickSetting}>
                Ajustes
            </Button>
            <Menu id="menu-setting" anchorEl={anchorElSetting} keepMounted open={Boolean(anchorElSetting)} onClose={handleCloseSetting} >
                <MenuItem activeClassName='active' component={NavLink} color="secondary" to={"/ajustes"} onClick={handleCloseSetting}>Datos Generales</MenuItem>
                <MenuItem activeClassName='active' onClick={handleCloseSetting} component={NavLink}  color="secondary" to={"/feriados"}  >Días Feriados</MenuItem>
                <MenuItem activeClassName='active' component={NavLink} color="secondary" to={"/jerarquia"} onClick={handleCloseSetting}>Jerarquía</MenuItem>
                <MenuItem activeClassName='active' component={NavLink} color="secondary" to={"/usuarios"} onClick={handleCloseSetting}>Usuarios</MenuItem>
                <MenuItem activeClassName='active' component={NavLink} color="secondary" to={"/publicacion"} onClick={handleCloseSetting}>Publicación Aplicación</MenuItem>
            </Menu>
          
            <Button  aria-controls="menu-report" aria-haspopup="true"  variant="contained" disableElevation color="secondary" onClick={handleClickReport}>
              Reportes
            </Button>
            <Menu id="menu-report" anchorEl={anchorElReport} keepMounted open={Boolean(anchorElReport)} onClose={handleCloseReport} >
              <MenuItem activeClassName='active' component={NavLink} color="secondary" to={"/pagos-por-fecha"} onClick={handleCloseReport}>Pagos por Fecha</MenuItem>
              <MenuItem activeClassName='active' component={NavLink} color="secondary" to={"/colocacion-por-fecha"} onClick={handleCloseReport}>Colocación por Fecha</MenuItem>
              <MenuItem activeClassName='active' component={NavLink} color="secondary" to={"/recuperado-por-fecha"} onClick={handleCloseReport}>Recuperación por Día</MenuItem>
              <MenuItem activeClassName='active' component={NavLink} color="secondary" to={"/estado-cuenta-historico"} onClick={handleCloseReport}>Estado Cuenta Historico</MenuItem>
            </Menu>

          </div>

          </Typography> 


          <div  />
          <div >

            {/* <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton
              edge="end"
              
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >     
              <Chip
              icon={<AccountCircle />}
              color="secondary"
              label={user.userName}
              
            />
            </IconButton>
          </div>
   
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      </Box>
  );

};

export default Header;



// import * as React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';

// export default function ButtonAppBar() {
//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <AppBar position="static">
//         <Toolbar>
//           <IconButton
//             size="large"
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             sx={{ mr: 2 }}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//             News
//           </Typography>
//           <Button color="inherit">Login</Button>
//         </Toolbar>
//       </AppBar>
//     </Box>
//   );
// }