import { HierarchySelect } from "../../hierarchy/models/hierarchy.select";
import { Permission } from "./permission";
import { Subordinate } from "./subordinate";

export class User {
    userID?: number;
    userName?: string;
    password?: string;
    active?: boolean;
    officeID?: string;
    roleID?: number;
    companyInterestPercent?: number;
    permission?: Permission[];
    subordinate?: Subordinate[];
    hirarchySelect?: HierarchySelect;
    constructor(datos?: User) {
      if (datos != null) {
        this.userID = datos.userID;
        this.userName = datos.userName;
        this.password = datos.password;
        this.active = datos.active;
        this.officeID = datos.officeID;
        this.roleID = datos.roleID;
        this.permission = datos.permission;
        this.subordinate = datos.subordinate;
        this.hirarchySelect = datos.hirarchySelect;
        this.companyInterestPercent = datos.companyInterestPercent;
        return;
      }
    }
  }
