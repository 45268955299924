import { IconButton, Typography } from "@mui/material";
import MuiDialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';


export interface DialogTitleProps{
    id: string;
    children: React.ReactNode;
    onClose: () => void;
  }
  

export const DialogTitle = ((props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle   {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close"  onClick={onClose}
              sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                      }}
  
  >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  