import { ToastSwall } from "../../../_helpers/common";
import { settingConstants } from "../constants/setting.constants";
import { Setting } from "../models/setting";
import { SettingStateModel } from "../models/setting_state_model";

const initialState: SettingStateModel = { 
    loading: true, 
    error: "",
    setting: new Setting(),
    openSetting: {
        open: false,
        select: {},
        type: 0,
        loading: false,
    },
} ;

export function setting(state = initialState, action:any) :SettingStateModel {
    switch (action.type) {

        case settingConstants.SET_OPEN_SETTING_SUCCESS:
            return {
                ...state,
                openSetting:action.openSetting
            };

        case settingConstants.GETALL_REQUEST:     
            return {
                ...state,
                loading: true,
                setting: new Setting(),
            };
        case settingConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                setting: action.setting,
            };
        case settingConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };

            
        case settingConstants.EDIT_SETTING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case settingConstants.EDIT_SETTING_SUCCESS:
            ToastSwall.fire({
                icon: 'success',
                title: 'Ajuste Editado'
              })
            return {
                ...state,
                loading: false,
                setting: action.setting,
            };
        case settingConstants.EDIT_SETTING_FAILURE:
            return {
                ...state,
                error: action.error,
                setting: state.setting,
                loading: false
            };


        default:
            return state
    }
}