import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container , CircularProgress, Box, Grid, Button, Typography, Card} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { toEsDate } from '../../_helpers/format';
import { SpentType } from './models/spent_type';
import { Spent } from './models/spent';
import { spentActions } from './actions/spent.action';
import { DialogEditSpent } from './dialog.edit.spent';
import { DialogEditSpentType } from './dialog.edit.spent_type';

function SpentTypeListPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.spent.loading);
  const alert = useSelector((state: any) => state.alert.message);
  const spentTypes = useSelector((state:any)=> state.spent.spentTypes as SpentType[])

    useEffect(() => {
      if((spentTypes??[]).length == 0){
        dispatch(spentActions.getSpentType());
      }
    }, []);

   

    const handleClose = (value: string) => {
      // dispatch(inventoryActions.setInventoryScreen({
      //   open : false,
      //   select :new Inventory(),
      //   type : 0,
      //   loading:false
      // }));
    };

    const handleClickOpen = (row: SpentType, type: number) => {
      dispatch(spentActions.setOpenModalSpentTypeScreen(
        {
        open : true,
        select :row,
        type : type,
        loading:false
      }));
    };

    const columns: GridColDef[] = [
       {
         field: 'spentTypeId',
         headerName: 'No',
         width: 110,
         align:'center'
       },
       {
         field: 'description',
         headerName: 'Descripcion',
         width: 220,
       },
       {
         field: 'createDate',
         headerName: 'Fecha',
         width: 120,
         valueFormatter: (params) => {
          
            if(params?.value){
              var da = params?.value as string;
              return toEsDate(da);
            } 
            else{
              return "X";
            }
         },
       }
     ];
       
    return (
        <Box my={2}>
            <Container >
              <Card  variant="outlined" style={{padding:"5px 20px 25px 20px"}} >

            <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
                    <Box my={2} >
                        <Button variant="contained"  color="primary" type="submit" onClick={()=>{
                                var spentType = new SpentType();
                                handleClickOpen(spentType, 0);
                                }
                                }>
                              Agregar
                          </Button>
                      </Box>
              </Grid>


              {alert && <Box my={2} >{alert}</Box>}
              {
                loading? 
                <Box my={2}>
                    <CircularProgress title="Cargando..." />
                </Box>
                :
                <Container >
                    
                    <Box >
                      <Typography variant="h5" gutterBottom component="div">
                       Tipos de Gastos
                      </Typography>
                    </Box>

                    <Grid container spacing={3}>
                            <Grid item xs={12} >
                        
                              <DataGrid autoHeight 
                                  rows={spentTypes}
                                  getRowId={(r) => r.spentTypeId}
                                  columns={columns}
                                  showCellRightBorder={false}
                                  showColumnRightBorder={false}
                                  // components={{
                                  //   Toolbar:CustomToolbar
                                  // }}      
                                  pageSize={100}
                                  rowsPerPageOptions={[5]}
                                  nonce="No se encontraron resultados"
                                  density="compact"
                                  checkboxSelection={false}
                                  onCellClick={(params, event) => {
                                      handleClickOpen(params.row as SpentType, 1)
                                    }}
                                  
                                  disableSelectionOnClick
                              />
                            </Grid>
                          </Grid>

       
                </Container>
              }
              <DialogEditSpentType onClose={handleClose} /> 
              </Card>  
            </Container>
        </Box>
    );
}

export { SpentTypeListPage };
