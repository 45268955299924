import axios from 'axios';
import environment from '../../environment';
import { Publish } from './models/publish';

export const publishService = {
    getAll,
    add,
    edit,
    uploadApk
};

 function getAll() {
    return axios.get(environment.apiUri + "/publish" )
  };

  function add(publish: Publish) {
    return axios.post(environment.apiUri + "/publish",publish )
  };
  
  function edit(publish: Publish) {
    return axios.put(environment.apiUri + "/publish",publish )
  };

  
  function uploadApk(publish: Publish) {
    const formData = new FormData();
    formData.append("file", publish.file!);
    let config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    return axios.post(environment.apiUri + "/publish/UploadApk",formData, config )
  };
  
  