import { Adviser } from "../../hierarchy/models/adviser";

export class FilterParams {
    fromDate?: Date;
    toDate?: Date;
    adviserIds?: Adviser[];
    
    constructor(datos?: FilterParams) {
      if (datos != null) {
        this.fromDate = datos.fromDate;
        this.toDate = datos.toDate;
        this.adviserIds = datos.adviserIds;
        return;
      }
      this.adviserIds = [];
    }
  }
