import { Dispatch } from 'redux';
import { DataDigaloProps } from '../../../models/data_digalo_props';
import { alertActions } from '../../../_actions/alert.actions';
import { User } from '../../user/models/user';
import { hierarchyConstants } from '../constants/hierarchy.constants';
import { hierarchyService } from '../hierarchy.service';

import { Adviser } from '../models/adviser';
import { Supervisor } from '../models/supervisor';



export const hierarchyActions = {
    getAll,
    setOpenModalAdviserScreen,
    setOpenModalSupervisorScreen,
    addSupervisor,
    editSupervisor,
    addAdviser,
    editAdviser
};


function getAll() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        hierarchyService.getAll()
            .then(
                hierarchies => dispatch(success(hierarchies.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: hierarchyConstants.GETALL_REQUEST } }
    function success(hierarchies:any) { return { type: hierarchyConstants.GETALL_SUCCESS, hierarchies } }
    function failure(error:any) { return { type: hierarchyConstants.GETALL_FAILURE, error } }
}



function setOpenModalAdviserScreen(openAdviser:DataDigaloProps<Adviser>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: hierarchyConstants.SET_OPEN_ADVISER_SUCCESS, openAdviser });
    };
}
function setOpenModalSupervisorScreen(openSupervisor:DataDigaloProps<Supervisor>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: hierarchyConstants.SET_OPEN_SUPERVISOR_SUCCESS, openSupervisor });
    };
}



function addSupervisor(supervisor:Supervisor) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        hierarchyService.addSupervisor(supervisor)
            .then(
                users => dispatch(success(users.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    
    function request() { return { type: hierarchyConstants.ADD_SUPERVISOR_REQUEST } }
    function success(hierarchies:User) { return { type: hierarchyConstants.ADD_SUPERVISOR_SUCCESS, hierarchies } }
    function failure(error:any) { return { type: hierarchyConstants.ADD_SUPERVISOR_FAILURE, error } }
}
function editSupervisor(supervisor:Supervisor) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        hierarchyService.editSupervisor(supervisor)
            .then(
                users => dispatch(success(users.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    
    function request() { return { type: hierarchyConstants.EDIT_SUPERVISOR_REQUEST } }
    function success(hierarchies:User[]) { return { type: hierarchyConstants.EDIT_SUPERVISOR_SUCCESS, hierarchies } }
    function failure(error:any) { return { type: hierarchyConstants.EDIT_SUPERVISOR_FAILURE, error } }
}


function addAdviser(adviser:Adviser) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        hierarchyService.addAdviser(adviser)
            .then(
                users => dispatch(success(users.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    
    function request() { return { type: hierarchyConstants.ADD_ADVISER_REQUEST } }
    function success(hierarchies:User) { return { type: hierarchyConstants.ADD_ADVISER_SUCCESS, hierarchies } }
    function failure(error:any) { return { type: hierarchyConstants.ADD_ADVISER_FAILURE, error } }
}
function editAdviser(adviser:Adviser) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        hierarchyService.editAdviser(adviser)
            .then(
                users => dispatch(success(users.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    
    function request() { return { type: hierarchyConstants.EDIT_ADVISER_REQUEST } }
    function success(hierarchies:User[]) { return { type: hierarchyConstants.EDIT_ADVISER_SUCCESS, hierarchies } }
    function failure(error:any) { return { type: hierarchyConstants.EDIT_ADVISER_FAILURE, error } }
}