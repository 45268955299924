
import Dialog from '@mui/material/Dialog';
import { Avatar, Box, Button, Checkbox, CircularProgress, DialogContent, FormControl, FormControlLabel, FormHelperText, Grid,
IconButton, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem,  Select, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from './models/user';
import { DataDigaloProps } from '../../models/data_digalo_props';
import { userActions } from './actions/user.action';
import { Role } from './models/role';
import { Permission } from './models/permission';
import { blue } from '@mui/material/colors';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { DialogTitle } from '../../_components/DialogTitle';
import { HierarchySelect } from '../hierarchy/models/hierarchy.select';



export interface DialogAddressProps {
    onClose: (value: HierarchySelect) => void;
  }
  
  function SelectHierarchyDialog(props: DialogAddressProps) {
    const { onClose} = props;
    
    const dispatch = useDispatch();
    
    const  openHierarchy  = useSelector((state: any) => state.users.openHierarchy as DataDigaloProps<HierarchySelect>);
    const  hierarchies  = useSelector((state: any) => state.users.hierarchies as HierarchySelect[]);

    const [inputs, setInputs] = React.useState<HierarchySelect>(openHierarchy?.select);

     useEffect(() => {
         setInputs(openHierarchy?.select)
    }, [openHierarchy]);

     useEffect(() => {
       
         if(hierarchies.length ==0 && (openHierarchy?.select?.rolID??0 !=0) && (openHierarchy?.select?.name == undefined)){
          dispatch(userActions.getHierarchies(openHierarchy?.select?.rolID??0));
        }
    }, [hierarchies]);


    const handleSelect = (select:HierarchySelect) => {
        dispatch(userActions.setOpenModalSelectScreen({
          open : false,
          select : new HierarchySelect(),
          type : 0,
          loading: false
        }));
        select.rolID = openHierarchy.select.rolID;
        onClose(select);
      };
  

    
    const handleClose = () => {

      dispatch(userActions.setOpenModalSelectScreen({
        open : false,
        select : new HierarchySelect(),
        type : 0,
        loading: false
      }));
      onClose( new HierarchySelect());
 
    };
  

    return (
      <Dialog maxWidth="xs" id="inventoryDialog"  fullWidth={true} aria-labelledby="simple-dialog-title" open={openHierarchy?.open??false}>
        <DialogTitle onClose={handleClose}  id="simple-dialog-title" > Seleccione </DialogTitle>
        <DialogContent dividers>
        {
          openHierarchy?.loading ?  <CircularProgress />:
   
                    <List sx={{ pt: 0 }}>
                    {hierarchies.map((item: HierarchySelect) => (
                    <ListItem button onClick={() => handleSelect(item)}>
                        <ListItemAvatar>
                        <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                            <PersonIcon />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.name} />
                    </ListItem>
                    ))}

                </List>
      }
        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { SelectHierarchyDialog };
