import { FinancialStateDetail } from "./financial_state_detail";

export class FinancialState {
    amountNet?: number;
    amountLending?: number;
    amountReceive?: number;
    amountAfterInterest?: number;
    amountAfterInterestReal?: number;
    amountFine?: number;
    spent?: number;
    detail?: FinancialStateDetail[];
    
    constructor(datos?: FinancialState) {
      if (datos != null) {
        this.amountNet = datos.amountNet;
        this.amountLending = datos.amountLending;
        this.amountReceive = datos.amountReceive;
        this.amountAfterInterest = datos.amountAfterInterest;
        this.amountAfterInterestReal = datos.amountAfterInterestReal;
        this.amountFine = datos.amountFine;
        this.spent = datos.spent;
        this.detail = datos.detail;
        return;
      }
      this.detail = [];
    }
  }
