import axios from 'axios';
import environment from '../../environment';
import { Spent } from './models/spent';
import { SpentType } from './models/spent_type';

export const spentService = {
  getByDate,
  getSpentType,
  add,
  edit,
  addType,
  editType
};


 function getByDate(fromDate:string, toDate: string) {
    return axios.get(`${environment.apiUri}/spent/GetByDate/${fromDate}/${toDate}`)
  };
 function getSpentType() {
    return axios.get(`${environment.apiUri}/spent/getTypes`)
  };


 function add(spent: Spent) {
    return axios.post(environment.apiUri + "/spent" , spent)
  };
  
  function edit(spent: Spent) {
     return axios.put(environment.apiUri + "/spent" , spent)
   };
  
   
   
   function addType(spentType: SpentType) {
    return axios.post(environment.apiUri + "/spent/AddType" , spentType)
  };
  
  function editType(spentType: SpentType) {
      return axios.put(environment.apiUri + "/spent/editType" , spentType)
    };


 