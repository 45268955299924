import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';



function HomePage() {
    const auth = useSelector((state:any) => state.auth);
    const user = useSelector((state:any) => state.authentication.user );
    const dispatch = useDispatch();

    useEffect(() => {
        //TODO dispatch(userActions.getAll());
    }, []);



    return (
        <Container >
            
            <h2>Hola {user.userName}!</h2>
            {/* <p>You're logged in with React Hooks!!</p>
            <h3>All registered users:</h3> */}
            {/* {users?.loading && <em>Loading users...</em>}
            {users?.error && <span className="text-danger">ERROR: {users.error}</span>} */}
            {auth?.items &&
                <ul>
                    {/* {users.items.map((user, index) =>
                        <li key={user.id}>
                            {user.firstName + ' ' + user.lastName}
                            {
                                user.deleting ? <em> - Deleting...</em>
                                : user.deleteError ? <span className="text-danger"> - ERROR: {user.deleteError}</span>
                                : <span> - <a onClick={() => handleDeleteUser(user.id)} className="text-primary">Delete</a></span>
                            }
                        </li>
                    )} */}

                    Hola
                </ul>
            }
            {/* <p>
                <Link to="/login">Logout</Link>
            </p> */}
        </Container>
    );
}

export { HomePage };