import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container , CircularProgress, Grid, Box, Button, Card} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { userActions } from './actions/user.action';
import { User } from './models/user';
import { DialogEditUser } from './dialog.edit.user';
import { Role } from './models/role';



function UsersPage() {
    const dispatch = useDispatch();
    const  loading  = useSelector((state: any) => state.users.loading);
    const  users  = useSelector((state: any) => state.users.users as User[]);
    const  alert  = useSelector((state: any) => state.alert.message);
    const [submitted, setSubmitted] = React.useState(false);
    const [inputs, setInputs] = React.useState({
      wareHouseID: ""
    });

    const handleClickOpen = (row: User, type: number) => {
      dispatch(userActions.setOpenModalUserScreen(
        {
        open : true,
        select :row,
        type : type,
        loading:false
      }));
    };



    // reset login status
    useEffect(() => {
      if((users??[]).length ==0){
        dispatch(userActions.getAll());
      }
    }, []);
    
    useEffect(() => {
    
    }, [users]);
    

    
    const handleClose = (value: string) => {
      // dispatch(inventoryActions.setInventoryScreen({
      //   open : false,
      //   select :new Inventory(),
      //   type : 0,
      //   loading:false
      // }));
    };

    const columns: GridColDef[] = [
     // { field: 'inventoryID', headerName: 'ID', width: 130 },
      {
        field: 'userID',
        headerName: 'No Usuario',
        width: 110,
        align:'center'
      },
      {
        field: 'userName',
        headerName: 'Usuario',
        width: 200,
      },
      // {
      //   field:'wareHouseCode',
      //   headerName:'Almacenes',
      //   width: 210,
      // },
      // {
      //   field: 'role',
      //   headerName: 'Rol',
      //   width: 140,
      //   valueFormatter: (params) => {
      //      if(params?.value ==2){
      //        return "Contador"
      //      } 
      //      else if(params?.value ==1){
      //        return "Administrador";
      //      }
      //   },
      // },
      {
        field: 'active',
        headerName: 'Activo',
        width: 140,
        valueFormatter: (params) => {
           if(params?.value){
             return "Si"
           } 
           else{
             return "No";
           }
        },
      },
      {
        field:'edit',
        headerName:'Editar',
        width:130,
        renderCell: (params: GridRenderCellParams) => (
          <Button color="primary" size="small"onClick={()=>{
            // console.log(params.row)
            handleClickOpen(params.row as User, 1)
            }
            }>
          Editar
          </Button>
        )
      },
    ];
  


    function handleChangeInput(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
    }


    return (
        <Box my={2}>
            <Container maxWidth="md" >
            <Card  variant="outlined" style={{padding:"5px 20px 25px 20px"}} >

            <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
                    <Box my={2} >
                        <Button variant="contained"  color="primary" type="submit" onClick={()=>{
                                var user = new User();
                                user.permission= [];
                                handleClickOpen( user, 0);
                                }
                                }>
                              Agregar
                          </Button>
                      </Box>
              </Grid>

              
                {alert && <Box my={2} >{alert}</Box>}
                    {
                        loading?  
                        <Box my={2}>
                           <CircularProgress title="Cargando..." />
                        </Box>
                        :<Grid container spacing={3}>
                            <Grid item xs={12} >
                        
                              <DataGrid autoHeight 
                                  rows={users}
                                  getRowId={(r) => r.userID}
                                  columns={columns}
                                  pageSize={100}
                                  rowsPerPageOptions={[5]}
                                  nonce="No se encontraron resultados"
                                  density="compact"
                                  checkboxSelection={false}
                                  // onCellClick={(params, event) => {
                                  //     console.log(params.row)
                                  //     handleClickOpen(params.row as User, 1)
                                  //   }}
                                  
                                  disableSelectionOnClick
                              />
                            </Grid>
                          </Grid>
                    }
             <DialogEditUser onClose={handleClose} /> 
            </Card>
            </Container>
        </Box>
    );
}

export { UsersPage };
