export const inscriptionConstants = {

    CALCULATE_FEE_REQUEST: 'CALCULATE_FEE_REQUEST',
    CALCULATE_FEE_SUCCESS: 'CALCULATE_FEE_SUCCESS',
    CALCULATE_FEE_FAILURE: 'CALCULATE_FEE_FAILURE',


    CALCULATE_REQUEST_REQUEST: 'CALCULATE_REQUEST_REQUEST',
    CALCULATE_REQUEST_SUCCESS: 'CALCULATE_REQUEST_SUCCESS',
    CALCULATE_REQUEST_FAILURE: 'CALCULATE_REQUEST_FAILURE',

    ADD_LENDING_REQUEST: 'ADD_LENDING_REQUEST',
    ADD_LENDING_SUCCESS: 'ADD_LENDING_SUCCESS',
    ADD_LENDING_FAILURE: 'ADD_LENDING_FAILURE' , 


    GET_DAY_FREE_REQUEST: 'GET_DAY_FREE_REQUEST',
    GET_DAY_FREE_SUCCESS: 'GET_DAY_FREE_SUCCESS',
    GET_DAY_FREE_FAILURE: 'GET_DAY_FREE_FAILURE' , 


    EDIT_LENDING_REQUEST: 'EDIT_LENDING_REQUEST',
    EDIT_LENDING_SUCCESS: 'EDIT_LENDING_SUCCESS',
    EDIT_LENDING_FAILURE: 'EDIT_LENDING_FAILURE' , 



    ADD_DAY_FREE_REQUEST: 'ADD_DAY_FREE_REQUEST',
    ADD_DAY_FREE_SUCCESS: 'ADD_DAY_FREE_SUCCESS',
    ADD_DAY_FREE_FAILURE: 'ADD_DAY_FREE_FAILURE' , 


    EDIT_DAY_FREE_REQUEST: 'EDIT_DAY_FREE_REQUEST',
    EDIT_DAY_FREE_SUCCESS: 'EDIT_DAY_FREE_SUCCESS',
    EDIT_DAY_FREE_FAILURE: 'EDIT_DAY_FREE_FAILURE' , 


    
    GET_ADVISER_REQUEST: 'GET_ADVISER_REQUEST',
    GET_ADVISER_SUCCESS: 'GET_ADVISER_SUCCESS',
    GET_ADVISER_FAILURE: 'GET_ADVISER_FAILURE' , 



    PENDIENT_LENDING_REQUEST: 'PENDIENT_LENDING_REQUEST',
    PENDIENT_LENDING_SUCCESS: 'PENDIENT_LENDING_SUCCESS',
    PENDIENT_LENDING_FAILURE: 'PENDIENT_LENDING_FAILURE' , 


    POSTINGT_LENDING_REQUEST: 'POSTINGT_LENDING_REQUEST',
    POSTING_LENDING_SUCCESS: 'POSTING_LENDING_SUCCESS',
    POSTING_LENDING_FAILURE: 'POSTING_LENDING_FAILURE' , 


    UP_IMAGE_LENDING_REQUEST: 'UP_IMAGE_LENDING_REQUEST',
    UP_IMAGE_LENDING_SUCCESS: 'UP_IMAGE_LENDING_SUCCESS',
    UP_IMAGE_LENDING_FAILURE: 'UP_IMAGE_LENDING_FAILURE' , 

    UP_IMAGE_LENDING_ON_REQUEST: 'UP_IMAGE_LENDING_ON_REQUEST',
    UP_IMAGE_LENDING_ON_SUCCESS: 'UP_IMAGE_LENDING_ON_SUCCESS',
    UP_IMAGE_LENDING_ON_FAILURE: 'UP_IMAGE_LENDING_ON_FAILURE' , 


    
    SET_CUSTOMER_SUCCESS: 'SET_CUSTOMER_SUCCESS' , 
    SET_OPEN_CUSTOMER_SUCCESS: 'SET_OPEN_CUSTOMER_SUCCESS' , 
    SET_OPEN_REQUEST_SUCCESS: 'SET_OPEN_REQUEST_SUCCESS' , 
    SET_OPEN_DAY_FREE_SUCCESS: 'SET_OPEN_DAY_FREE_SUCCESS' , 



};