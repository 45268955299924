import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Container, FormControl, FormHelperText, Grid, IconButton, Input, InputAdornment, InputLabel, 
  MenuItem, Select, TextField, Typography } from '@mui/material';
import { customerActions } from '../../_actions/customer.actions';
import { Customer } from '../../models/customer/customer';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { inscriptionActions } from '../../_actions/inscription.actions';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format } from '../../_helpers/format';
import { Adviser } from '../hierarchy/models/adviser';
import { DialogCustomers } from '../lending/components/dialog.customers';
import { paymentActions } from '../../_actions/payment.action';
import { DialogChoiseLending } from './components/dialog.choise_lending';
import { PaymentRequest } from '../../models/payment/payment_request';


function PaymentPage() {

    const  customers  = useSelector((state: any) => state.customers.items as Customer[]);
    const loading = useSelector((state:any)=> state.payments.loading)
    const paymentRequest = useSelector((state:any)=> state.payments.paymentRequest)
    const advisers = useSelector((state:any)=> state.inscriptions.advisers as Adviser[])
    const user = useSelector((state:any) => state.authentication.user);
    const dispatch = useDispatch();
    // const [editRowsModel, setEditRowsModel] = React.useState({});

    const [inputs, setInputs] = React.useState<PaymentRequest>(paymentRequest);
    const [submitted, setSubmitted] = React.useState(false);
    const [totalPay, setTotalPay] = React.useState(0);
    useEffect(() => {
        if(customers.length == 0){
          dispatch(customerActions.getAll());
        }
      }, []);
    
    
      useEffect(() => {
        if(advisers.length == 0){
          dispatch(inscriptionActions.getAdviser());
        } 
      }, [advisers]);

    useEffect(() => {
        setInputs(new PaymentRequest(paymentRequest));
        setSubmitted(false);
    }, [paymentRequest]);



   
      const handleCustomerClose = (customer: Customer) => {
          console.log(customer);
          setInputs(inputs => ({ ...inputs, "customerID": customer.customerID}));
          setInputs(inputs => ({ ...inputs, "customer": customer.name}));
          dispatch(paymentActions.getPendientPaymentCustomer(customer.customerID??""));
       };
      const handleLendingClose = (payment: PaymentRequest) => {
          console.log(payment);
        
       };


    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleAdd(e:any) {
        e.preventDefault();
        setSubmitted(true);
        if (inputs.term && inputs.amount && paymentRequest.customerID && inputs.durationID && (inputs.fees?? []).length > 0 && inputs.adviserID) {
          inputs.createBy = user.userID;


          console.log(inputs)
            // get return url from location state or default to home page
            // const { from } = location .state || { from: { pathname: "/" } };
            setTotalPay(0)
            inputs.userID = user.UserID;
          dispatch(paymentActions.post(inputs));
        }
      }


      function clean(e:any) {
        e.preventDefault();
        setInputs(new PaymentRequest());
        setSubmitted(false);
      }

   

      const columns: GridColDef[] = [
        { field: 'lineNumber', headerName: '# Cuota', width: 130 },
        {
          field: 'expireDate',
          headerName: 'Fecha',
          width: 160,
          type:'date',
          valueFormatter: (params) => {
            if(params?.value){
              return new Date(params?.value as string).toLocaleDateString().substr(0,10)
            } 
         },
 
        },
        {
          field: 'day',
          headerName: 'Día',
          width: 140,
        },
        {
          field: 'amount',
          headerName: 'Cuota',
          width: 200,
          valueFormatter: (params) => {
            if(params?.value){
              var data: number = parseFloat(params?.value as string);
              return "L. "+ format( data,2)
            } 
         },
        },
        {
          field: 'fine',
          headerName: 'Multa',
          width: 200,
          valueFormatter: (params) => {
            if(params?.value){
              var data: number = parseFloat(params?.value as string);
              return "L. "+ format( data,2)
            } 
         },
        },
        {
          field: 'pay',
          headerName: 'Pago',
          width: 200,
          editable:false,
                    
          valueFormatter: (params) => {
            if(params?.value){
              var data: number = parseFloat(params?.value as string);
              return "L. "+ format( data,2)
            } 
         },

         renderCell: (params) => {
          const handleChange = (prop:any) =>{
            console.log(prop.target.value)

            let index = (inputs.fees??[]).findIndex((x)=> x.lineNumber == params.row.lineNumber);
            if(index> -1){
              (inputs.fees??[])[index].pay   = parseFloat( prop.target.value);
              (inputs.fees??[])[index].diff   = -1 *(((inputs.fees??[])[index].amount??0) + ((inputs.fees??[])[index].fine??0) - parseFloat( isNaN(prop.target.value) ? 0:prop.target.value )) ;
              setInputs(inputs);

              let total = 0;
              for (var i of (inputs.fees??[])) {
                total += isNaN(i?.pay??0)? 0: i?.pay??0;
              }
             total = isNaN(total)? 0: total;
              setTotalPay(total)
            }
          };
        
          return (
            <>

        <FormControl  >
              <TextField
                //label="Size"
              
               // defaultValue="Small"
                id={params.row.lineNumber}
                variant="outlined"
                onChange={handleChange}
                size="small"
              />
                        
        </FormControl>
              {/* <IconButton color="secondary" onClick={onClickDelete}>
               
                hj
              </IconButton>
              <IconButton color="secondary" onClick={onClickEdit}>
               
                asd
              </IconButton> */}
            </>
          );
        },


  
        },
        {
          field: 'diff',
          headerName: 'Diferencia',
          width: 200,
          valueFormatter: (params) => {
            if(params?.value){
              var data: number = parseFloat(params?.value as string);
              return "L. "+ format( data,2)
            } 
         },
        },
      ];
  
    return (
        <Box my={2}>
            <Container >
                <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
                    
                <Box  my={2} mx={7}>
                    <Typography style={{color: "#f50057"}} variant="h6" gutterBottom component="div">
                       Total a Pagar: L. {format(totalPay,2)}
                    </Typography>
                </Box>
{/* 
                    <Box my={2} >
                        <Button disabled={(inputs.fees??[]).length==0} variant="contained" color="primary" onClick={(e)=>{
                            console.log(inputs?.fees??[])
                            }
                        }>Calcular Pago </Button>
                    </Box> */}
                    <Box my={2} >
                        <Button disabled={(inputs.fees??[]).length==0} variant="contained" color="primary" onClick={(e)=>{
                            handleAdd(e);
                            }
                        }>Pagar </Button>
                    </Box>

                </Grid>
                {/* <Typography variant="h6" component="h2">
                    Solicitud Préstamo
                </Typography> */}
            <Grid container spacing={3}>

                    <Grid item xs={4}>
                     
                            <FormControl fullWidth  >
                                <InputLabel shrink > Cliente </InputLabel>

                                <Input
                                    id="selectCustomer"
                                    value={paymentRequest?.customer || ''}
                                    placeholder={"Seleccione un cliente"}
                                    disabled={true}
                                    error={(submitted && !paymentRequest?.customerID)}   
                                    endAdornment={<InputAdornment position="end">
                                        <IconButton
                                            aria-label="Cambiar"
                                            onClick={()=>{
                                                dispatch(inscriptionActions.setOpenCustomerScreen({
                                                    open: true,
                                                    select :{},
                                                    type : 0,
                                                    loading:false
                                                }));
                                                }
                                            }
                                            >
                                        <MoreVertIcon /> 
                                        </IconButton>
                                    </InputAdornment>}
                                />
                                 {
                                (submitted &&  !paymentRequest?.customerID) ? <FormHelperText error >Es requerido</FormHelperText>:""
                                }

                            </FormControl>
                            
                    </Grid>
                    
                    <Grid item xs={3}> 
                        <TextField
                                    id="amount"
                                    label="Monto"
                                    type="text"
                                    placeholder="Monto"
                                    fullWidth
                                    name="amount"
                                    disabled={true}
                                    value={inputs?.amount || ""}
                                    //error={(submitted && !inputs?.interest)}   helperText={(submitted && !inputs?.interest) ? "Es Requerido":""}
                                    onChange={handleChange}
                        
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>


                    <Grid item xs={3}> 
                    <TextField
                                    id="amountPay"
                                    label="Monto Pagado"
                                    type="text"
                                    placeholder="Monto Pagado"
                                    fullWidth
                                    name="amountPay"
                                    disabled={true}
                                    value={inputs?.amountPay || ""}
                                    //error={(submitted && !inputs?.interest)}   helperText={(submitted && !inputs?.interest) ? "Es Requerido":""}
                                    onChange={handleChange}
                        
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>

                    <Grid item xs={2}> 
                        <TextField
                                    id="fine"
                                    label="Multa"
                                    type="text"
                                    placeholder="Multa"
                                    fullWidth
                                    name="fine"
                                    disabled={true}
                                    value={inputs?.amountFine || ""}
                                    //error={(submitted && !inputs?.interest)}   helperText={(submitted && !inputs?.interest) ? "Es Requerido":""}
                                    onChange={handleChange}
                        
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>
                    <Grid item xs={3}> 
                                <TextField
                                    id="term"
                                    label="Plazo en numero"
                                    type="text"
                                    placeholder="Plazo en numero"
                                    fullWidth
                                    name="term"
                                    disabled={true}
                                    value={inputs?.term  || ''}
                                    error={(submitted && !inputs?.term)}   helperText={(submitted && !inputs?.term) ? "Es Requerido":""}
                                    onChange={handleChange}
                              
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>
                    <Grid item xs={3}> 
                                <TextField
                                    id="pendientTerm"
                                    label="Plazo en numero pendientes"
                                    type="text"
                                    placeholder="Plazo en numero pendientes"
                                    fullWidth
                                    name="pendientTerm"
                                    disabled={true}
                                    value={inputs?.pendientTerm  || ''}
                                    error={(submitted && !inputs?.pendientTerm)}   helperText={(submitted && !inputs?.pendientTerm) ? "Es Requerido":""}
                                    onChange={handleChange}
                              
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>


                    <Grid item xs={3}> 
                        <TextField
                                    id="amountExpired"
                                    label="Monto Vencido"
                                    type="text"
                                    placeholder="Monto Vencido"
                                    fullWidth
                                    name="amountExpired"
                                    disabled={true}
                                    value={inputs?.amountExpired || ''}
                                    //error={(submitted && !inputs?.amountExpired)}   helperText={(submitted && !inputs?.amount) ? "Es Requerido":""}
                                    onChange={handleChange}
                        
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>


                    <Grid item xs={3}> 
                        <FormControl >
                        <InputLabel shrink  id="label" >
                            Asesor
                        </InputLabel>
                            <Select
                                value={inputs?.adviserID || ''}
                                onChange={handleChange}
                                displayEmpty
                                labelId="label" 
                                label="Asesor"
                                placeholder="Seleccione un Asesor"
                                name="adviserID"
                                error={(submitted && !inputs?.adviserID)}   
                                // helperText=
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {
                                advisers.map((item: Adviser)=>{
                                    return <MenuItem value={item.adviserID}> {item.adviserID} - {item.name}</MenuItem>
                                })
                                }
                            </Select>
                            {
                                (submitted && !inputs?.adviserID) ? <FormHelperText error >Es requerido</FormHelperText>:""
                            }
                            
                            </FormControl>

                    </Grid>

                    
                </Grid>
                <Box  my={4}>
                    <Typography variant="h6" gutterBottom component="div">
                       Cuotas Pendientes
                    </Typography>
                </Box>
            
                <DataGrid autoHeight 
                    rows={inputs?.fees??[]}
                    loading= {loading}
                    getRowId={(r) => r.lineNumber}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    nonce="No se encontraron resultados"
                    density="compact"
       
                    checkboxSelection={false}
                    // onCellClick={(params, event) => {
                    //     console.log(params.row)
                    //     handleClickOpen(params.row as Customer)
                    //   }}
                    disableSelectionOnClick
                />


            </Container>
            <DialogCustomers onClose={handleCustomerClose} ></DialogCustomers>
            <DialogChoiseLending onClose={handleLendingClose} ></DialogChoiseLending>
        </Box>
    );
}

export { PaymentPage };