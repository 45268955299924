import { ToastSwall } from "../../../_helpers/common";
import { publishConstants } from "../constants/publish.constants";
import { Publish } from "../models/publish";
import { PublishStateModel } from "../models/publish_state_model";

const initialState: PublishStateModel = { 
    loading: true, 
    error: "",
    publishs: Array<Publish>(),
    openPublish: {
        open: false,
        select: {},
        type: 0,
        loading: false,
    },
    } ;

export function publishs(state = initialState, action:any) :PublishStateModel {
    switch (action.type) {

        case publishConstants.SET_OPEN_PUBLISH_SUCCESS:
            return {
                ...state,
                openPublish:action.openPublish
            };

        case publishConstants.GETALL_REQUEST:     
            return {
                ...state,
                loading: true,
                publishs: [],
            };
        case publishConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                publishs: action.publishs,
            };
        case publishConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };

            
        case publishConstants.UPLOAD_APK_REQUEST:     
            return {
                ...state,
                openPublish:{
                    open: true,
                    select : state.openPublish.select,
                    type: state.openPublish.type,
                    loading:true
                },
            };
        case publishConstants.UPLOAD_APK_SUCCESS:
            return {
                ...state,
                openPublish:{
                    open: true,
                    select : action.publish,
                    type: state.openPublish.type,
                    loading:false
                },
            };
        case publishConstants.UPLOAD_APK_FAILURE:
            return {
                ...state,
                error: action.error,
                openPublish:{
                    open: true,
                    select : state.openPublish.select,
                    type: state.openPublish.type,
                    loading:false
                },
            };
        

        case publishConstants.ADD_PUBLISH_SUCCESS:
            ToastSwall.fire({
                icon: 'success',
                title: 'Publicación Agregado'
              })
            return {
                ...state,
                publishs: action.publishs,
                openPublish:{
                    open: false,
                    select : state.openPublish.select,
                    type: 1,
                    loading:false
                },
            };
        case publishConstants.ADD_PUBLISH_FAILURE:
            return {
                ...state,
                openPublish:{
                    open: true,
                    select:state.openPublish.select,
                    type:0,
                    loading: false
                },
                error: action.error,
                publishs: state.publishs,
            };


            
        case publishConstants.EDIT_PUBLISH_REQUEST:
            return {
                ...state,
                openPublish:{
                    open: true,
                    select:state.openPublish.select,
                    type:1,
                    loading: true
                },
            };

        case publishConstants.EDIT_PUBLISH_SUCCESS:
            ToastSwall.fire({
                icon: 'success',
                title: 'Usuario Editado'
              })
            return {
                ...state,
                publishs: action.publishs,
                openPublish:{
                    open: false,
                    select : state.openPublish.select,
                    type: 1,
                    loading:false
                },
            };
        case publishConstants.EDIT_PUBLISH_FAILURE:
            return {
                ...state,
                openPublish:{
                    open: true,
                    select:state.openPublish.select,
                    type:1,
                    loading: false
                },
                error: action.error,
                publishs: state.publishs,
            };

        default:
            return state
    }
}