import { ToastSwall } from "../../../_helpers/common";
import { HierarchySelect } from "../../hierarchy/models/hierarchy.select";
import { userConstants } from "../constants/user.constants";
import { Permission } from "../models/permission";
import { Role } from "../models/role";
import { User } from "../models/user";
import { UserStateModel } from "../models/user_state_model";

const initialState: UserStateModel = { 
    loading: true, 
    error: "",
    users:  Array<User>(), 
    roles: Array<Role>(),
    permissions: Array<Permission>(),
    hierarchies: Array<HierarchySelect>(),
    openUser: {
        open: false,
        select: {},
        type: 0,
        loading: false,
    },
    openHierarchy: {
        open: false,
        select: {},
        type: 0,
        loading: false,
    },
    } ;

export function users(state = initialState, action:any) :UserStateModel {
    switch (action.type) {

        case userConstants.SET_OPEN_USER_SUCCESS:
            return {
                ...state,
                openUser:action.openUser
            };

        case userConstants.SET_OPEN_SELECT_HIERARCHY_SUCCESS:
            return {
                ...state,
                hierarchies: [],
                openHierarchy: action.openHierarchy
            };

        case userConstants.GETALL_REQUEST:     
            return {
                ...state,
                loading: true,
                users: [],
            };
        case userConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.users,
            };
        case userConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        

    case userConstants.GET_ROLES_REQUEST:     
        return {
            ...state,
           // loading: true,
            roles: [],
        };
    case userConstants.GET_ROLES_SUCCESS:
        return {
            ...state,
            //l//oading: false,
            roles:action.roles,
        };
    case userConstants.GET_ROLES_FAILURE:
        return {
            ...state,
            error: action.error,
            //loading: false,
        };


    case userConstants.GET_FROM_HIERARCHY_REQUEST:     
        return {
            ...state,
           // hierarchies: [],
            openHierarchy: {
                open: true,
                select: state.openHierarchy.select,
                loading: true,
                type: 1
            }
        };
    case userConstants.GET_FROM_HIERARCHY_SUCCESS:
        return {
            ...state,
            hierarchies:action.hierarchies,
            openHierarchy: {
                open: true,
                select: state.openHierarchy.select,
                loading: false,
                type: 1
            }
        };
    case userConstants.GET_FROM_HIERARCHY_FAILURE:
        return {
            ...state,
            error: action.error,
            openHierarchy: {
                open: true,
                select: state.openHierarchy.select,
                loading: false,
                type: 1
            }
        };



    case userConstants.GET_PERMISSION_REQUEST:     
    return {
        ...state,
        //oading: true,
        permissions: [],
    };
    case userConstants.GET_PERMISSION_SUCCESS:
        return {
            ...state,
            //loading: false,
            permissions:action.permissions,
        };
    case userConstants.GET_PERMISSION_FAILURE:
        return {
            ...state,
            error: action.error,
            //loading: false,
        };

        case userConstants.ADD_USER_REQUEST:
            return {
                ...state,
                openUser:{
                    open: true,
                    select:state.openUser.select,
                    type:0,
                    loading: true
                },
            };

        case userConstants.ADD_USER_SUCCESS:
            // var index = state.users.findIndex(x=> x.userID === action.user.userID);
            // var newList = state.users.map((item)=>{
            //     return item
            // });
            ToastSwall.fire({
                icon: 'success',
                title: 'Usuario Agregado'
              })
            // newList[index] = action.user;
            return {
                ...state,
                users: action.users,
                openUser:{
                    open: false,
                    select : state.openUser.select,
                    type: 1,
                    loading:false
                },
            };
        case userConstants.ADD_USER_FAILURE:
            return {
                ...state,
                openUser:{
                    open: true,
                    select:action.user,
                    type:0,
                    loading: false
                },
                error: action.error,
                users: state.users,
            };


            
        case userConstants.EDIT_USER_REQUEST:
            return {
                ...state,
                openUser:{
                    open: true,
                    select:state.openUser.select,
                    type:1,
                    loading: true
                },
            };

        case userConstants.EDIT_USER_SUCCESS:
            // var index = state.users.findIndex(x=> x.userID === action.user.userID);
            // var newList = state.users.map((item)=>{
            //     return item
            // });
            ToastSwall.fire({
                icon: 'success',
                title: 'Usuario Editado'
              })
            //newList.push(action.users);
            return {
                ...state,
                users: action.users,
                openUser:{
                    open: false,
                    select : state.openUser.select,
                    type: 1,
                    loading:false
                },
            };
        case userConstants.EDIT_USER_FAILURE:
            return {
                ...state,
                openUser:{
                    open: true,
                    select:action.user,
                    type:1,
                    loading: false
                },
                error: action.error,
                users: state.users,
            };

        default:
            return state
    }
}