import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container , CircularProgress, Grid, Box, Button, Card} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { DialogEditAdviser } from './hierarchy.edit.adviser';
import { Supervisor } from './models/supervisor';
import { hierarchyActions } from './actions/hierarchy.action';
import { DialogEditSupervisor } from './hierarchy.edit.supervisor';
import { Adviser } from './models/adviser';



function HierarchyPage() {
    const dispatch = useDispatch();
    const  loading  = useSelector((state: any) => state.hierarchy.loading);
    const  hierarchies  = useSelector((state: any) => state.hierarchy.hierarchies as Supervisor[]);
    const  alert  = useSelector((state: any) => state.alert.message);

    const handleClickOpenSupervisor = (row: Supervisor, type: number) => {
      dispatch(hierarchyActions.setOpenModalSupervisorScreen(
        {
        open : true,
        select :row,
        type : type,
        loading:false
      }));
    };

    const handleClickOpenAdviser = (row: Adviser, type: number) => {
      dispatch(hierarchyActions.setOpenModalAdviserScreen(
        {
        open : true,
        select :row,
        type : type,
        loading:false
      }));
    };



    // reset login status
    useEffect(() => {
      if((hierarchies??[]).length ==0){
        dispatch(hierarchyActions.getAll());
      }
    }, []);
    
    useEffect(() => {
    
    }, [hierarchies]);
    

    
    const handleClose = (value: string) => {
      // dispatch(inventoryActions.setInventoryScreen({
      //   open : false,
      //   select :new Inventory(),
      //   type : 0,
      //   loading:false
      // }));
    };

   
    return (
        <Box my={2}>
            <Container  >
          <Card  variant="outlined" style={{padding:"5px 20px 25px 20px"}} >

                {alert && <Box my={2} >{alert}</Box>}
                    {
                        loading? 
                        <Box my={2}>
                           <CircularProgress title="Cargando..." />
                        </Box>
                        :
                        <Container >
                           <div style={{textAlign: "center"}}>
                           <Button color="error" variant="text" endIcon={<AddCircleIcon />} 
                           
                           onClick={()=>{
                            var supervisor = new Supervisor();
                            handleClickOpenSupervisor(supervisor,0)
                          }}
                           

                           >Agregar Supervisor</Button>
                           </div>
                            <Grid container spacing={5}>
                                {
                                  (hierarchies??[]).map((item)=>{
                                    return (
                                      <Grid item xs={6} >
                                        <div className="supervisor">
                                          <div className="supervisor-content">
                                            <div className={`title-supervisor ${!item.status? 'inactive-item' : ''}`} >
                                              <p>{item.name}</p>
                                              <span className="subtitle-supervisor">
                                                {item.user} {!item.status? <span className="inactive"> Inactivo</span>:""} 
                                              </span>
                                              <br/>
                                              <Button  size="small" variant="text" endIcon={<EditIcon />}   onClick={()=>{
                                                    handleClickOpenSupervisor(item,1)
                                                    }}
                                          
                                          >Editar</Button>
                                            </div>

                                          </div>
                                  
                                          <div className="line-supervisor">
                                                      <div className="line">
                                                        
                                                      </div>

                                          </div>
                                          <div style={{textAlign: "center"}}>
                                              <div className="arrow-supervisor"></div>
                                          </div>
                                          <Button variant="text" endIcon={<AddCircleIcon />} 
                                            
                                            onClick={()=>{
                                              var adviser = new Adviser();
                                              adviser.supervisorID = item.supervisorID;
                                              handleClickOpenAdviser(adviser,0)
                                            }}
                           
                                          
                                          >Agregar asesor</Button>
                                          <Grid container spacing={2}>
                                            {
                                              (item.subordinate??[]).map((subordinate)=>{
                                                return (
                                                <Grid item xs={6} >
                                                  <div  className={`hierarchy-supervisor ${!subordinate.status? 'inactive-item' : ''}`}  > 
                                                    <p className="title-adviser">{subordinate.name} </p>
                                                    <p className="subtitle-adviser">{subordinate.user}  {!subordinate.status? <span className="inactive"> Inactivo</span>:""} </p> 
                                                    <Button  size="small" variant="text" endIcon={<EditIcon />}
                                                    
                                                    onClick={()=>{

                                                      handleClickOpenAdviser(subordinate,1)
                                                    }}
                                                    
                                                    >Editar</Button>
                                                  </div>
                                                </Grid>  
                                                  )
                                              })
                                            }
                                          </Grid>
                                        </div>
                                      </Grid>
                                    )

                                  }
                                  )
                                }
                              
                              
                            </Grid>

                        </Container>
                    }
             <DialogEditAdviser onClose={handleClose} /> 
             <DialogEditSupervisor onClose={handleClose} /> 
            </Card>
            </Container>
        </Box>
    );
}

export { HierarchyPage };
