import MuiDialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box,DialogContent,  IconButton,Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Customer } from '../../../models/customer/customer';
import { customerActions } from '../../../_actions/customer.actions';
import { useDispatch, useSelector } from 'react-redux';
import { DataDigaloProps } from '../../../models/data_digalo_props';
import { inscriptionActions } from '../../../_actions/inscription.actions';

export interface DialogTitleProps  {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = ((props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle   {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close"  onClick={onClose}
            sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});



export interface SimpleDialogProps {
    onClose: (value: Customer) => void;
  }
  
  function DialogCustomers(props: SimpleDialogProps) {
    const { onClose} = props;
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    
    const  customers  = useSelector((state: any) => state.customers.items as Customer[]);
    const  loading  = useSelector((state: any) => state.customers.loading);
    const openCustomer = useSelector((state:any) => state.inscriptions.openCustomer as DataDigaloProps<Customer> );
    const [submitted, setSubmitted] = React.useState(false);
    const  alert  = useSelector((state: any) => state.alert.message);
    
    const [inputs, setInputs] = React.useState<Customer>(openCustomer?.select);
    useEffect(() => {
      setInputs(openCustomer?.select)
      setSubmitted(false)
    }, [openCustomer]);



    useEffect(() => {
      if(customers.length == 0){
        dispatch(customerActions.getAll());
      }
    }, []);


  
    const columnsAddress: GridColDef[] = [
      {
        field: 'customerID',
        headerName: 'Cliente ID',
        width: 150,
      },      
      {
        field: 'dni',
        headerName: 'Identidad',
        width: 150,
      },
      {
        field: 'name',
        headerName: 'Nombre',
        width: 240
      },
      {
        field: 'phone',
        headerName: 'Teléfono',
        width: 140,
      },
 
    ];


    const handleClose = (customer?:Customer) => {
      onClose(customer ?? new Customer());//TODO 
      setSubmitted(false)
      dispatch(inscriptionActions.setOpenCustomerScreen({
        open: false,
        select :customer ?? new Customer(),
        type : 0,
        loading:false
    }));
    };


  
    return (
      <Dialog   id="customerDialog" fullWidth={true} maxWidth="md"  aria-labelledby="simple-dialog-title" open={openCustomer?.open ??false}>
        <DialogTitle onClose={()=>{handleClose(new Customer())}}  id="simple-dialog-title" > Seleccione un cliente </DialogTitle>
   
        <DialogContent dividers>    

        <Box  my={2}>
              <DataGrid autoHeight 
                  rows={customers}
                  getRowId={(r) => r.customerID}
                  columns={columnsAddress}
                  pageSize={100}
                  loading={loading}
                  density="compact"
                  rowsPerPageOptions={[5]}
                  
                  checkboxSelection={false}
                  onCellClick={(params, event) => {
                      handleClose(params.row);
                    }}
                  
                  disableSelectionOnClick
              />
        </Box>

        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { DialogCustomers };