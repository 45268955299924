export class SpentDetail {
    lineId?: number;
    lineNumber?: number;
    spentId?: number;
    description?: string;
    spentTypeId?: number;
    spentType?:string;
    referent?:string;
    createDate?: Date;
    amount?: number;
    
    constructor(datos?: SpentDetail) {
      if (datos != null) {
        this.lineId = datos.lineId;
        this.lineNumber = datos.lineNumber;
        this.spentId = datos.spentId;
        this.description = datos.description;
        this.spentTypeId = datos.spentTypeId;
        this.spentType = datos.spentType;
        this.referent = datos.referent;
        this.createDate = datos.createDate;
        this.amount = datos.amount;
        return;
      }
    }
  }
