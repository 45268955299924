import { Adviser } from "./adviser";

export class Supervisor {
    supervisorID?: number;
    name?: string;
    userID?: number;
    user?: string;
    status?: boolean;
    subordinate?: Adviser[];

    constructor(datos?: Supervisor) {
      if (datos != null) {
        this.supervisorID = datos.supervisorID;
        this.name = datos.name;
        this.userID = datos.userID;
        this.user = datos.user;
        this.status = datos.status;
        this.subordinate = datos.subordinate;
        return;
      }
    }
  }
