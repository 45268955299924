import { PaymentRequestDetail } from "./payment_request_detail";

export class PaymentRequest {
    adviserID?: number;
    amount?: number;
    amountAfterInterest?: number;
    amountFine?: number;
    amountPay?: number;
    amountExpired?: number;
    pendientTerm?: number;
    createBy?: number;
    createDate?: Date;
    durationID?: number;
    customerID?: string;
    customer?: string;
    fees?: PaymentRequestDetail[];
    interest?: number;
    lendingID?: number;
    postingBy?: number;
    status?: number;
    term?: number;
    userID?: number;
    
    constructor(datos?: PaymentRequest) {
      if (datos != null) {
        this.adviserID = datos.adviserID;
        this.amount = datos.amount;
        this.amountAfterInterest = datos.amountAfterInterest;
        this.amountPay = datos.amountPay;
        this.amountFine = datos.amountFine;
        this.amountExpired = datos.amountExpired;
        this.pendientTerm = datos.pendientTerm;
        this.createBy = datos.createBy;
        this.durationID = datos.durationID;
        this.customerID = datos.customerID;
        this.customer = datos.customer;
        this.fees = datos.fees;
        this.interest = datos.interest;
        this.lendingID = datos.lendingID;
        this.postingBy = datos.postingBy;
        this.status = datos.status;
        this.term = datos.term;
        this.userID = datos.userID;
        return;
      }
    }
  }
