import { Dispatch } from 'redux';
import { DataDigaloProps } from '../../../models/data_digalo_props';
import { alertActions } from '../../../_actions/alert.actions';
import { publishConstants } from '../constants/publish.constants';
import { Publish } from '../models/publish';
import { publishService } from '../publish.service';

export const publishActions = {
    getAll,
    setOpenModalPublishScreen,
    add,
    edit,
    uploadApk
};

function setOpenModalPublishScreen(openPublish:DataDigaloProps<Publish>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: publishConstants.SET_OPEN_PUBLISH_SUCCESS, openPublish });
    };
}

function getAll() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        publishService.getAll()
            .then(
                users => dispatch(success(users.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: publishConstants.GETALL_REQUEST } }
    function success(publishs:any) { return { type: publishConstants.GETALL_SUCCESS, publishs } }
    function failure(error:any) { return { type: publishConstants.GETALL_FAILURE, error } }
}

function add(publish:Publish) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        publishService.add(publish)
            .then(
                users => dispatch(success(users.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    
    function request() { return { type: publishConstants.ADD_PUBLISH_REQUEST } }
    function success(publishs:Publish[]) { return { type: publishConstants.ADD_PUBLISH_SUCCESS, publishs } }
    function failure(error:any) { return { type: publishConstants.ADD_PUBLISH_FAILURE, error } }
}
function uploadApk(publish:Publish) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        publishService.uploadApk(publish)
            .then(
                users => dispatch(success(users.data, publish)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    
    function request() { return { type: publishConstants.UPLOAD_APK_REQUEST } }

    function success(apkName: string , publish:Publish) { 
        publish.apkName = apkName;
        return { type: publishConstants.UPLOAD_APK_SUCCESS, publish }
     }
    function failure(error:any) { return { type: publishConstants.UPLOAD_APK_FAILURE, error } }
}



function edit(publish:Publish) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        publishService.edit(publish)
            .then(
                users => dispatch(success(users.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    
    function request() { return { type: publishConstants.EDIT_PUBLISH_REQUEST } }
    function success(publishs:Publish[]) { return { type: publishConstants.EDIT_PUBLISH_SUCCESS, publishs } }
    function failure(error:any) { return { type: publishConstants.EDIT_PUBLISH_FAILURE, error } }
}