export const spentConstants = {
    GET_SPENTS_BY_DATE_REQUEST: 'GET_SPENTS_BY_DATE_REQUEST',
    GET_SPENTS_BY_DATE_SUCCESS: 'GET_SPENTS_BY_DATE_SUCCESS',
    GET_SPENTS_BY_DATE_FAILURE: 'GET_SPENTS_BY_DATE_FAILURE',


    GET_SPENT_TYPE_REQUEST: 'GET_SPENT_TYPE_REQUEST',
    GET_SPENT_TYPE_SUCCESS: 'GET_SPENT_TYPE_SUCCESS',
    GET_SPENT_TYPE_FAILURE: 'GET_SPENT_TYPE_FAILURE',


    ADD_SPENT_REQUEST: 'ADD_SPENT_REQUEST',
    ADD_SPENT_SUCCESS: 'ADD_SPENT_SUCCESS',
    ADD_SPENT_FAILURE: 'ADD_SPENT_FAILURE',

    EDIT_SPENT_REQUEST: 'EDIT_SPENT_REQUEST',
    EDIT_SPENT_SUCCESS: 'EDIT_SPENT_SUCCESS',
    EDIT_SPENT_FAILURE: 'EDIT_SPENT_FAILURE',


    ADD_SPENT_TYPE_REQUEST: 'ADD_SPENT_TYPE_REQUEST',
    ADD_SPENT_TYPE_SUCCESS: 'ADD_SPENT_TYPE_SUCCESS',
    ADD_SPENT_TYPE_FAILURE: 'ADD_SPENT_TYPE_FAILURE',

    EDIT_SPENT_TYPE_REQUEST: 'EDIT_SPENT_TYPE_REQUEST',
    EDIT_SPENT_TYPE_SUCCESS: 'EDIT_SPENT_TYPE_SUCCESS',
    EDIT_SPENT_TYPE_FAILURE: 'EDIT_SPENT_TYPE_FAILURE',
    
    SET_OPEN_SPENT_SUCCESS: 'SET_OPEN_SPENT_SUCCESS' , 
    SET_OPEN_SPENT_TYPE_SUCCESS: 'SET_OPEN_SPENT_TYPE_SUCCESS' , 
};