export const customerConstants = {

    GETALL_REQUEST: 'CUSTOMERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'CUSTOMERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'CUSTOMERS_GETALL_FAILURE',

    DELETE_REQUEST: 'CUSTOMERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'CUSTOMERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'CUSTOMERS_DELETE_FAILURE'  ,  


    ADD_ADDRESS_REQUEST: 'ADD_ADDRESS_REQUEST',
    ADD_ADDRESS_SUCCESS: 'ADD_ADDRESS_SUCCESS',
    ADD_ADDRESS_FAILURE: 'ADD_ADDRESS_FAILURE' , 


    EDIT_ADDRESS_REQUEST: 'EDIT_ADDRESS_REQUEST',
    EDIT_ADDRESS_SUCCESS: 'EDIT_ADDRESS_SUCCESS',
    EDIT_ADDRESS_FAILURE: 'EDIT_ADDRESS_FAILURE' , 


    ADD_CUSTOMER_REQUEST: 'ADD_CUSTOMER_REQUEST',
    ADD_CUSTOMER_SUCCESS: 'ADD_CUSTOMER_SUCCESS',
    ADD_CUSTOMER_FAILURE: 'ADD_CUSTOMER_FAILURE' , 


    EDIT_CUSTOMER_REQUEST: 'EDIT_CUSTOMER_REQUEST',
    EDIT_CUSTOMER_SUCCESS: 'EDIT_CUSTOMER_SUCCESS',
    EDIT_CUSTOMER_FAILURE: 'EDIT_CUSTOMER_FAILURE' , 


    ADD_REFERENT_REQUEST: 'ADD_REFERENT_REQUEST',
    ADD_REFERENT_SUCCESS: 'ADD_REFERENT_SUCCESS',
    ADD_REFERENT_FAILURE: 'ADD_REFERENT_FAILURE' , 

    EDIT_REFERENT_REQUEST: 'EDIT_REFERENT_REQUEST',
    EDIT_REFERENT_SUCCESS: 'EDIT_REFERENT_SUCCESS',
    EDIT_REFERENT_FAILURE: 'EDIT_REFERENT_FAILURE' , 
    

    ADD_GUARANTEE_REQUEST: 'ADD_GUARANTEE_REQUEST',
    ADD_GUARANTEE_SUCCESS: 'ADD_GUARANTEE_SUCCESS',
    ADD_GUARANTEE_FAILURE: 'ADD_GUARANTEE_FAILURE' , 

    EDIT_GUARANTEE_REQUEST: 'EDIT_GUARANTEE_REQUEST',
    EDIT_GUARANTEE_SUCCESS: 'EDIT_GUARANTEE_SUCCESS',
    EDIT_GUARANTEE_FAILURE: 'EDIT_GUARANTEE_FAILURE' , 
    
    
    UP_IMAGE_REQUEST: 'UP_IMAGE_REQUEST',
    UP_IMAGE_SUCCESS: 'UP_IMAGE_SUCCESS',
    UP_IMAGE_FAILURE: 'UP_IMAGE_FAILURE' , 


    UP_IMAGE_GUARANTEE_REQUEST: 'UP_IMAGE_GUARANTEE_REQUEST',
    UP_IMAGE_GUARANTEE_SUCCESS: 'UP_IMAGE_GUARANTEE_SUCCESS',
    UP_IMAGE_GUARANTEE_FAILURE: 'UP_IMAGE_GUARANTEE_FAILURE' , 


    UP_IMAGE_ADDRESS_REQUEST: 'UP_IMAGE_ADDRESS_REQUEST',
    UP_IMAGE_ADDRESS_SUCCESS: 'UP_IMAGE_ADDRESS_SUCCESS',
    UP_IMAGE_ADDRESS_FAILURE: 'UP_IMAGE_ADDRESS_FAILURE' , 


    UP_IMAGE_CUSTOMER_REQUEST: 'UP_IMAGE_CUSTOMER_REQUEST',
    UP_IMAGE_CUSTOMER_SUCCESS: 'UP_IMAGE_CUSTOMER_SUCCESS',
    UP_IMAGE_CUSTOMER_FAILURE: 'UP_IMAGE_CUSTOMER_FAILURE' , 




    
    
    SET_CUSTOMER_SUCCESS: 'SET_CUSTOMER_SUCCESS' , 
    SET_OPEN_PRINCIPAL_SUCCESS: 'SET_OPEN_PRINCIPAL_SUCCESS' , 
    SET_OPEN_ADDRESS_SUCCESS: 'SET_OPEN_ADDRESS_SUCCESS' , 
    SET_OPEN_REFERENT_SUCCESS: 'SET_OPEN_REFERENT_SUCCESS' , 
    SET_OPEN_GUARANTEE_SUCCESS: 'SET_OPEN_GUARANTEE_SUCCESS' , 



};