import { Dispatch } from 'redux';
import { DataDigaloProps } from '../../../models/data_digalo_props';
import { alertActions } from '../../../_actions/alert.actions';
import { spentConstants } from '../constants/spent.constants';
import { Spent } from '../models/spent';
import { SpentType } from '../models/spent_type';
import { spentService } from '../spent.service';

export const spentActions = {
    getByDate,
    getSpentType,
    setOpenModalSpentScreen,
    setOpenModalSpentTypeScreen,
    add,
    edit,
    addType,
    editType
};


function setOpenModalSpentScreen(openSpent:DataDigaloProps<Spent>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: spentConstants.SET_OPEN_SPENT_SUCCESS, openSpent });
    };
}
function setOpenModalSpentTypeScreen(openSpentType:DataDigaloProps<SpentType>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: spentConstants.SET_OPEN_SPENT_TYPE_SUCCESS, openSpentType });
    };
}
function getByDate(fromDate: string, toDate:string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        spentService.getByDate(fromDate, toDate)
            .then(
                data => dispatch(success(data.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: spentConstants.GET_SPENTS_BY_DATE_REQUEST } }
    function success(spents:Spent[]) { return { type: spentConstants.GET_SPENTS_BY_DATE_SUCCESS, spents } }
    function failure(error:any) { return { type: spentConstants.GET_SPENTS_BY_DATE_FAILURE, error } }
}

function getSpentType() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        spentService.getSpentType()
            .then(
                data => dispatch(success(data.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: spentConstants.GET_SPENT_TYPE_REQUEST } }
    function success(spentTypes:SpentType[]) { return { type: spentConstants.GET_SPENT_TYPE_SUCCESS, spentTypes } }
    function failure(error:any) { return { type: spentConstants.GET_SPENT_TYPE_FAILURE, error } }
}

function add(spent: Spent) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        spentService.add(spent)
            .then(
                data => dispatch(success(data.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message, spent));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: spentConstants.ADD_SPENT_REQUEST } }
    function success(spents:Spent[]) { return { type: spentConstants.ADD_SPENT_SUCCESS, spents } }
    function failure(error:any, spent: Spent) { return { type: spentConstants.ADD_SPENT_FAILURE,  error, spent } }
}


function edit(spent: Spent) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        spentService.edit(spent)
            .then(
                data => dispatch(success(data.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message, spent));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: spentConstants.EDIT_SPENT_REQUEST } }
    function success(spents:Spent[]) { return { type: spentConstants.EDIT_SPENT_SUCCESS, spents } }
    function failure(error:any, spent: Spent) { return { type: spentConstants.EDIT_SPENT_FAILURE, error, spent } }
}




function addType(spentType: SpentType) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        spentService.addType(spentType)
            .then(
                data => dispatch(success(data.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message, spentType));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: spentConstants.ADD_SPENT_TYPE_REQUEST } }
    function success(spentTypes:SpentType[]) { return { type: spentConstants.ADD_SPENT_TYPE_SUCCESS, spentTypes } }
    function failure(error:any, spentType: SpentType) { return { type: spentConstants.ADD_SPENT_TYPE_FAILURE,  error, spentType } }
}


function editType(spentType: SpentType) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        spentService.editType(spentType)
            .then(
                data => dispatch(success(data.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message, spentType));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: spentConstants.EDIT_SPENT_TYPE_REQUEST } }
    function success(spentTypes:SpentType[]) { return { type: spentConstants.EDIT_SPENT_TYPE_SUCCESS, spentTypes } }
    function failure(error:any, spentType: SpentType) { return { type: spentConstants.EDIT_SPENT_TYPE_FAILURE, error, spentType } }
}


