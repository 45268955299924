import { customerConstants } from '../_constants/customer.constants';
import { alertActions } from './alert.actions';
import { customerService } from '../_services/customer.service';
import { Dispatch } from 'redux';
import { CustomerAddress } from '../models/customer/customer_address';
import { Customer } from '../models/customer/customer';
import { DataDigaloProps } from '../models/data_digalo_props';
import { inscriptionConstants } from '../_constants/inscription.constants';
import { Lending } from '../models/lending/lending';
import { inscriptionService } from '../_services/inscription.service';
import { Adviser } from '../pages/hierarchy/models/adviser';
import { DayFree } from '../models/day_free';
import { LendingImage } from '../models/lending/lending_image';

export const inscriptionActions = {
    setOpenCustomerScreen,
    setOpenLendingScreen,
    setOpenDayFreeScreen,
    calculateFee,
    add,
    edit,
    posting,
    getAdviser,
    getPendientLending,
    addImageLending,
    calculateFeeRequest,
    AddEditDayFree,
    addImageLendingOnRequest,
    getAll
};



function setOpenCustomerScreen(openCustomer:DataDigaloProps<Customer>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: inscriptionConstants.SET_OPEN_CUSTOMER_SUCCESS, openCustomer });
    };
}

function setOpenLendingScreen(openRequest:DataDigaloProps<Lending>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: inscriptionConstants.SET_OPEN_REQUEST_SUCCESS, openRequest });
    };
}


function setOpenDayFreeScreen(openDayFree:DataDigaloProps<DayFree>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: inscriptionConstants.SET_OPEN_DAY_FREE_SUCCESS, openDayFree });
    };
}


function getAll() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        inscriptionService.getAll()
            .then(
                
                dayFrees => dispatch(success(dayFrees.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: inscriptionConstants.GET_DAY_FREE_REQUEST } }
    function success(dayFrees:Adviser[]) { return { type: inscriptionConstants.GET_DAY_FREE_SUCCESS, dayFrees } }
    function failure(error:any) { return { type: inscriptionConstants.GET_DAY_FREE_FAILURE, error } }
}
function getAdviser() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        inscriptionService.getAdviser()
            .then(
                
                advisers => dispatch(success(advisers.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: inscriptionConstants.GET_ADVISER_REQUEST } }
    function success(advisers:Adviser[]) { return { type: inscriptionConstants.GET_ADVISER_SUCCESS, advisers } }
    function failure(error:any) { return { type: inscriptionConstants.GET_ADVISER_FAILURE, error } }
}



function addImageLending(lending: Lending, image:File) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.addImage(image)
            .then((customers) =>{
                var lendingImage = new LendingImage();
                lendingImage.imageUri = customers.data;
                lendingImage.lendingImageId = 0;
                var guarantees =  lending.images??[];
                guarantees.push(lendingImage);
                lending.images  = guarantees;
                dispatch(success(lending))
            
            }
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: inscriptionConstants.UP_IMAGE_LENDING_REQUEST } }
    function success(lending: Lending) { return { type: inscriptionConstants.UP_IMAGE_LENDING_SUCCESS, lending } }
    function failure(error:any) { return { type: inscriptionConstants.UP_IMAGE_LENDING_FAILURE, error } }
}


function addImageLendingOnRequest(lending: Lending, image:File) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.addImage(image)
            .then((customers) =>{
                var lendingImage = new LendingImage();
                lendingImage.imageUri = customers.data;
                lendingImage.lendingImageId = 0;
                var guarantees =  lending.images??[];
                guarantees.push(lendingImage);
                lending.images  = guarantees;
                dispatch(success(lending))
            
            }
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: inscriptionConstants.UP_IMAGE_LENDING_ON_REQUEST } }
    function success(lending: Lending) { return { type: inscriptionConstants.UP_IMAGE_LENDING_ON_SUCCESS, lending } }
    function failure(error:any) { return { type: inscriptionConstants.UP_IMAGE_LENDING_ON_FAILURE, error } }
}


function calculateFee(lending:Lending) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        inscriptionService.calculateFee(lending)
            .then(
                
                lendings => dispatch(success(lendings.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: inscriptionConstants.CALCULATE_FEE_REQUEST } }
    function success(lending:Lending) { return { type: inscriptionConstants.CALCULATE_FEE_SUCCESS, lending } }
    function failure(error:any) { return { type: inscriptionConstants.CALCULATE_FEE_FAILURE, error } }
}

function calculateFeeRequest(lending:Lending) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        inscriptionService.calculateFee(lending)
            .then(
                
                lendings => dispatch(success(lendings.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: inscriptionConstants.CALCULATE_REQUEST_REQUEST } }
    function success(lending:Lending) { return { type: inscriptionConstants.CALCULATE_REQUEST_SUCCESS, lending } }
    function failure(error:any) { return { type: inscriptionConstants.CALCULATE_REQUEST_FAILURE, error } }
}


function add(lending:Lending) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        inscriptionService.add(lending)
            .then(
                
                lendings => dispatch(success(lendings.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: inscriptionConstants.ADD_LENDING_REQUEST } }
    function success(lending:Lending) { return { type: inscriptionConstants.ADD_LENDING_SUCCESS, lending } }
    function failure(error:any) { return { type: inscriptionConstants.ADD_LENDING_FAILURE, error } }
}




function edit(lending:Lending) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        inscriptionService.edit(lending)
            .then(
                
                lendings => dispatch(success(lendings.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: inscriptionConstants.EDIT_LENDING_REQUEST } }
    function success(lending:Lending) { return { type: inscriptionConstants.EDIT_LENDING_SUCCESS, lending } }
    function failure(error:any) { return { type: inscriptionConstants.EDIT_LENDING_FAILURE, error } }
}


function AddEditDayFree(DayFree:DayFree) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        inscriptionService.AddEditDayFree(DayFree)
            .then(
                
                lendings => dispatch(success(lendings.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: inscriptionConstants.ADD_DAY_FREE_REQUEST } }
    function success(dayFrees:Lending) { return { type: inscriptionConstants.ADD_DAY_FREE_SUCCESS, dayFrees } }
    function failure(error:any) { return { type: inscriptionConstants.ADD_DAY_FREE_FAILURE, error } }
}



function posting(lending:Lending) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        inscriptionService.posting(lending)
            .then(
                
                lendings => dispatch(success(lendings.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: inscriptionConstants.POSTINGT_LENDING_REQUEST } }
    function success(lendings:Lending[]) { return { type: inscriptionConstants.POSTING_LENDING_SUCCESS, lendings } }
    function failure(error:any) { return { type: inscriptionConstants.POSTING_LENDING_FAILURE, error } }
}


function getPendientLending() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        inscriptionService.getPendientLending()
            .then(
                
                lendings => dispatch(success(lendings.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: inscriptionConstants.PENDIENT_LENDING_REQUEST } }
    function success(lendings:Lending[]) { return { type: inscriptionConstants.PENDIENT_LENDING_SUCCESS, lendings } }
    function failure(error:any) { return { type: inscriptionConstants.PENDIENT_LENDING_FAILURE, error } }
}
