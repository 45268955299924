import axios from 'axios';
import environment from '../environment';
import { PaymentRequest } from '../models/payment/payment_request';

export const paymentService = {
    getPendientPaymentCustomer,
    searchByDecription,
    post,
    getExpedient
};

 function getPendientPaymentCustomer(customerID: string) {
    return axios.get(environment.apiUri + `/payment/pendientByCustomer/${customerID}`)
  };

 function getExpedient(officeID: string) {
    return axios.get(environment.apiUri + `/payment/GetExpedient/${officeID}`)
  };
 function searchByDecription(officeID: string, description: string) {
    return axios.get(environment.apiUri + `/payment/SearchExpendint/${officeID}/${description}`)
  };
 function post(payment: PaymentRequest) {
    return axios.post(environment.apiUri + `/payment/`,payment)
  };
