import { ToastSwall } from "../_helpers/common";
import { Customer } from "../models/customer/customer";
import { customerConstants } from "../_constants/customer.constants";
import { GuaranteeImage } from "../models/customer/guarantee_image";
import { Guarantee } from "../models/customer/guarantee";
import { CustomerStateModel } from "../models/customer/customer_state_model";


const initialState: CustomerStateModel = { 
    loading: true, 
    upImage: false,
    uriImage:"",
    error: "",
    items:  Array<Customer>(), 
    openPrincipal: {
        open: false,
        select: {},
        type: 0,
        loading: false,
    },
    openAddress: {
        open: false,
        select: {},
        type: 0,
        loading: false
    },
    openReferent: {
        open: false,
        select: {},
        type: 0,
        loading: false
    },
    openGuarantee: {
        open: false,
        select:{},
        type:0,
        loading: false,
    }
    } ;

export function customers(state = initialState, action:any) :CustomerStateModel {
    switch (action.type) {

        case customerConstants.SET_OPEN_PRINCIPAL_SUCCESS:
            return {
                ...state,
                openPrincipal:action.openPrincipal
            };
        case customerConstants.SET_OPEN_ADDRESS_SUCCESS:
            return {
                ...state,
                upImage:false,
                uriImage:"",
                openAddress:action.openAddress,
            };


        case customerConstants.SET_OPEN_REFERENT_SUCCESS:
            return {
                ...state,
                openReferent:action.openReferent,
            };

        case customerConstants.SET_OPEN_GUARANTEE_SUCCESS:
            return {
                ...state,
                openGuarantee:action.openGuarantee,
        };


        case customerConstants.GETALL_REQUEST:     
            return {
                ...state,
                loading: true,
                items: [],
            };
        case customerConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.customers,
            };
        case customerConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };


        case customerConstants.ADD_ADDRESS_REQUEST:
            return {
                ...state,
                openAddress:{
                    open: true,
                    select:state.openAddress.select,
                    type:0,
                    loading: true
                },
            };

        case customerConstants.ADD_ADDRESS_SUCCESS:
            var index = state.items.findIndex(x=> x.customerID === action.customer.customerID);
            var newList = state.items.map((item)=>{
                return item
            });
            ToastSwall.fire({
                icon: 'success',
                title: 'Dirección Agregada'
              })
            newList[index] = action.customer;
            return {
                ...state,
                items: newList,
                openPrincipal:{
                    open: true,
                    select : action.customer,
                    type: 1,
                    loading:false
                },
                openAddress:{
                    open: false,
                    select:{},
                    type:0,
                    loading: false
                },
            };
        case customerConstants.ADD_ADDRESS_FAILURE:
            return {
                ...state,
                openAddress:{
                    open: true,
                    select:action.customer,
                    type:0,
                    loading: false
                },
                error: action.error,
                items: state.items,
            };



        case customerConstants.EDIT_ADDRESS_REQUEST:
            return {
                ...state,
                openAddress:{
                    open: true,
                    select:action.customer,
                    type:1,
                    loading: true
                },
            };

        case customerConstants.EDIT_ADDRESS_SUCCESS:
            var index = state.items.findIndex(x=> x.customerID === action.customer.customerID);
            var newList = state.items.map((item)=>{
                return item
            });
            ToastSwall.fire({
                icon: 'success',
                title: 'Dirección Editada'
                })
            newList[index] = action.customer;
            return {
                ...state,
                items: newList,
                openAddress:{
                    open: false,
                    select:{},
                    type:0,
                    loading: false
                },
                upImage: false,
                uriImage:"",
            };
        case customerConstants.EDIT_ADDRESS_FAILURE:
            return {
                ...state,
                openAddress:{
                    open: true,
                    select:action.customer,
                    type:1,
                    loading: false
                },
                error: action.error,
            };



        case customerConstants.ADD_CUSTOMER_REQUEST:
            return {
                ...state,
                openPrincipal:{
                    open: true,
                    select: state.openPrincipal.select,
                    type:0,
                    loading: true
                },
            };

        case customerConstants.ADD_CUSTOMER_SUCCESS:
            var newList = state.items.map((item)=>{
                return item
            });
            ToastSwall.fire({
                icon: 'success',
                title: 'Cliente Agregado'
                })
            newList.push(action.customer);
            return {
                ...state,
                items: newList,
                openPrincipal:{
                    open: true,
                    select:action.customer,
                    type:1,
                    loading: false
                },
            };
        case customerConstants.ADD_CUSTOMER_FAILURE:
            return {
                ...state,
                error: action.error,
                openPrincipal:{
                    open: true,
                    select:state.openPrincipal.select,
                    type:0,
                    loading: false
                },
            };




        case customerConstants.EDIT_CUSTOMER_REQUEST:
            return {
                ...state,
                openPrincipal:{
                    open: true,
                    select: state.openPrincipal.select,
                    type:1,
                    loading: true
                },
            };

        case customerConstants.EDIT_CUSTOMER_SUCCESS:
            var index = state.items.findIndex(x=> x.customerID === action.customer.customerID);
            var newList = state.items.map((item)=>{
                return item
            });
            ToastSwall.fire({
                icon: 'success',
                title: 'Cliente Editado'
                })
            newList[index] = action.customer;
            return {
                ...state,
                items: newList,
                openPrincipal:{
                    open: true,
                    select:action.customer,
                    type:1,
                    loading: false
                },
            };
        case customerConstants.EDIT_CUSTOMER_FAILURE:
            return {
                ...state,
                error: action.error,
                openPrincipal:{
                    open: true,
                    select: state.openPrincipal.select,
                    type:1,
                    loading: false
                },
            };



            case customerConstants.ADD_GUARANTEE_REQUEST:
                return {
                    ...state,
                    openGuarantee: {
                        open: true,
                        select:state.openGuarantee.select,
                        type:state.openGuarantee.type,
                        loading: true,
                    }
                };
    

            case customerConstants.ADD_GUARANTEE_SUCCESS:
                var newList = state.items.map((item)=>{
                    return item
                });
                ToastSwall.fire({
                    icon: 'success',
                    title: 'Garantia Agregada'
                    })
                newList.push(action.customer);
                return {
                    ...state,
                    items: newList,
                    openPrincipal:{
                        open:true,
                        select: action.customer,
                        type: state.openPrincipal.type,
                        loading:false
                    },
                    openGuarantee: {
                        open: false,
                        select:{},
                        type:0,
                        loading: false,
                    }
                };
            case customerConstants.ADD_GUARANTEE_FAILURE:
                return {
                    ...state,
                    error: action.error,
                    openGuarantee: {
                        open: true,
                        select:state.openGuarantee.select,
                        type:state.openGuarantee.type,
                        loading: false,
                    }
                };
    
            case customerConstants.EDIT_GUARANTEE_REQUEST:
                return {
                    ...state,
                    openGuarantee: {
                        open: true,
                        select:state.openGuarantee.select,
                        type:state.openGuarantee.type,
                        loading: true,
                    }
                };
    
            case customerConstants.EDIT_GUARANTEE_SUCCESS:
                var index = state.items.findIndex(x=> x.customerID === action.customer.customerID);
                var newList = state.items.map((item)=>{
                    return item
                });
                ToastSwall.fire({
                    icon: 'success',
                    title: 'Cliente Editado'
                    })
                newList[index] = action.customer;
                return {
                    ...state,
                    items: newList,
                    openPrincipal:{
                        open:true,
                        select: action.customer,
                        type: state.openPrincipal.type,
                        loading:false
                    },
                    openGuarantee: {
                        open: false,
                        select:{},
                        type:0,
                        loading: false,
                    }
                };
            case customerConstants.EDIT_GUARANTEE_FAILURE:
                return {
                    ...state,
                    error: action.error,
                    openGuarantee: {
                        open: true,
                        select:state.openGuarantee.select,
                        type:state.openGuarantee.type,
                        loading: false,
                    }
                };


            case customerConstants.UP_IMAGE_REQUEST:
                return {
                    ...state,
                    upImage: true,
                    uriImage:"",
                    
                };
    
            case customerConstants.UP_IMAGE_SUCCESS:
                ToastSwall.fire({
                    icon: 'success',
                    title: 'Imagen Agregada'
                    })
                return {
                    ...state,
                    upImage: false,
                    uriImage:action.uri,
                };
            case customerConstants.UP_IMAGE_FAILURE:
                return {
                    ...state,
                    error: action.error,
                    upImage: false,
                    uriImage:"",
                };


    case customerConstants.UP_IMAGE_GUARANTEE_REQUEST:
        return {
            ...state,
            openGuarantee: {
                open: true,
                select:state.openGuarantee.select,
                type:state.openGuarantee.type,
                loading: true,
            }
            
        };

    case customerConstants.UP_IMAGE_GUARANTEE_SUCCESS:
        ToastSwall.fire({
            icon: 'success',
            title: 'Imagen Agregada'
            })
        
        return {
            ...state,
            openGuarantee: {
                open: true,
                select:action.guarantee,
                type:state.openGuarantee.type,
                loading: false,
            }
        };

    case customerConstants.UP_IMAGE_GUARANTEE_FAILURE:
        return {
            ...state,
            error: action.error,
            upImage: false,
        };




        case customerConstants.UP_IMAGE_ADDRESS_REQUEST:
            return {
                ...state,
                openAddress: {
                    open: true,
                    select:state.openAddress.select,
                    type:state.openAddress.type,
                    loading: true,
                }
                
            };
    
        case customerConstants.UP_IMAGE_ADDRESS_SUCCESS:
            ToastSwall.fire({
                icon: 'success',
                title: 'Imagen Agregada'
                })
            
            return {
                ...state,
                openAddress: {
                    open: true,
                    select:action.customerAddress,
                    type:state.openAddress.type,
                    loading: false,
                }
            };
            
        case customerConstants.UP_IMAGE_ADDRESS_FAILURE:
            return {
                ...state,
                error: action.error,
                upImage: false,
            };



            case customerConstants.UP_IMAGE_CUSTOMER_REQUEST:
                return {
                    ...state,
                    openPrincipal: {
                        open: true,
                        select:state.openPrincipal.select,
                        type:state.openPrincipal.type,
                        loading: true,
                    }
                    
                };
        
            case customerConstants.UP_IMAGE_CUSTOMER_SUCCESS:
                ToastSwall.fire({
                    icon: 'success',
                    title: 'Imagen Agregada'
                    })
                
                return {
                    ...state,
                    openPrincipal: {
                        open: true,
                        select:action.customer,
                        type:state.openPrincipal.type,
                        loading: false,
                    }
                };
                
            case customerConstants.UP_IMAGE_ADDRESS_FAILURE:
                return {
                    ...state,
                    error: action.error,
                    upImage: false,
                };


    case customerConstants.ADD_REFERENT_REQUEST:
        return {
            ...state,
            openReferent:{
                open: true,
                select: state.openReferent.select,
                type:0,
                loading: true
            },
        };
    
            case customerConstants.ADD_REFERENT_SUCCESS:
                var newList = state.items.map((item)=>{
                    return item
                });
                ToastSwall.fire({
                    icon: 'success',
                    title: 'Referencia Agregado'
                    })
                newList.push(action.customer);
                return {
                    ...state,
                    items: newList,
                    openReferent:{
                        open: false,
                        select:{},
                        type:1,
                        loading: false
                    },
                    openPrincipal:{
                        open: true,
                        select:action.customer,
                        type:1,
                        loading: false
                    },
                };
            case customerConstants.ADD_REFERENT_FAILURE:
                return {
                    ...state,
                    error: action.error,
                    openReferent:{
                        open: true,
                        select:state.openReferent.select,
                        type:0,
                        loading: false
                    },
                };
        
        

                case customerConstants.EDIT_REFERENT_REQUEST:
                    return {
                        ...state,
                        openReferent:{
                            open: true,
                            select: state.openReferent.select,
                            type:1,
                            loading: true
                        },
                    };
        
                case customerConstants.EDIT_REFERENT_SUCCESS:
                    var index = state.items.findIndex(x=> x.customerID == action.customer.customerID);
                    var newList = state.items.map((item)=>{
                        return item
                    });
                    ToastSwall.fire({
                        icon: 'success',
                        title: 'Referencia Editado'
                        })
                    newList[index] = action.customer;
                    return {
                        ...state,
                        items: newList,
                        openReferent:{
                            open: false,
                            select:{},
                            type:1,
                            loading: false
                        },
                        openPrincipal:{
                            open: true,
                            select:action.customer,
                            type:1,
                            loading: false
                        },
                    };
                case customerConstants.EDIT_REFERENT_FAILURE:
                    return {
                        ...state,
                        error: action.error,
                        openReferent:{
                            open: true,
                            select: state.openReferent.select,
                            type:1,
                            loading: false
                        },
                    };


    
     


        
        case customerConstants.DELETE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
                ...state,
                items: state.items.map((user:any) =>
                    user.id === action.id
                        ? { ...user, deleting: true }
                        : user
                )
            };
        case customerConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return {
                ...state,
                items: state.items.filter((user:any) => user.id !== action.id)
            };
        case customerConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
            return {
                ...state,
                items: state.items.map((user:any) => {
                    if (user.id === action.id) {
                        // make copy of user without 'deleting:true' property
                        const { deleting, ...userCopy } = user;
                        // return copy of user with 'deleteError:[error]' property
                        return { ...userCopy, deleteError: action.error };
                    }

                    return user;
                })
            };
        default:
            return state
    }
}