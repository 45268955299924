import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Checkbox, CircularProgress, DialogContent, FormControl, FormControlLabel, FormHelperText, Grid,
IconButton, InputLabel, ListItemText, MenuItem,  Select, Slide, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from './models/user';
import { DataDigaloProps } from '../../models/data_digalo_props';
import { userActions } from './actions/user.action';
import { Role } from './models/role';
import { Permission } from './models/permission';
import { DialogTitle } from '../../_components/DialogTitle';
import { SelectHierarchyDialog } from './dialog.select.hierarchy';
import { HierarchySelect } from '../hierarchy/models/hierarchy.select';
import { TransitionProps } from '@mui/material/transitions';
import AndroidIcon from '@mui/icons-material/Android';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export interface DialogAddressProps {
    onClose: (value: string) => void;
  }
  
  function DialogEditUser(props: DialogAddressProps) {
    const { onClose} = props;
    
    const dispatch = useDispatch();
    
    const  openUser  = useSelector((state: any) => state.users.openUser as DataDigaloProps<User>);
    const  roles  = useSelector((state: any) => state.users.roles as Role[]);
    const  permissions  = useSelector((state: any) => state.users.permissions as Permission[]);
    // const  locations  = useSelector((state: any) => state.inventory.locations as WareHouse[]);
    // const types = useSelector((state: any) => state.inventory.types as InventoryType[]);
    // const  permissions  = useSelector((state: any) => state.inventory.permissions as Permission[]);
    // const  halls  = useSelector((state: any) => state.inventory.halls as Hall[]);
 
    const [inputs, setInputs] = React.useState<User>(openUser?.select);
   
    // const { active, address, city,  contact,latitude, longitude,name,phone} = inputs;
     const [submitted, setSubmitted] = React.useState(false);
    
  


     useEffect(() => {
         setInputs(openUser?.select)
         setSubmitted(false)
    }, [openUser]);


    useEffect(() => {
      // if((locations??[]).length == 0){
      //   dispatch(inventoryActions.getLocations());
      // }
      if(roles.length ==0){
        dispatch(userActions.getRoles());
      }
      if(permissions.length ==0){
        dispatch(userActions.getPermissions());
      }
    }, []);

    // useEffect(() => {
    //   if((permissions??[]).length == 0){
    //     dispatch(inventoryActions.getPermissions());
    //   }

    // if(types.length == 0){
    //         dispatch(inventoryActions.getTypes());
    //     }
    // }, []);
  

    
    const handleClose = () => {
      setInputs(new User())
      dispatch(userActions.setOpenModalUserScreen({
        open : false,
        select : new User(),
        type : 0,
        loading: false
      }));
      setSubmitted(false)
    };
  
    const handleCloseSelect = (value: HierarchySelect) => {
        setInputs(inputs => ({ ...inputs, 'roleID': value.rolID ==-1 ? inputs.roleID:value.rolID}));
        setInputs(inputs => ({ ...inputs, 'hirarchySelect': value}));
        dispatch(userActions.setOpenModalSelectScreen({
          open : false,
          select : value,
          type : 0,
          loading: false
        }));

      setSubmitted(false)
    };
    function handleChangeCheck(e:any)  {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: e.target.checked  }));
    };
    
    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if(inputs.userName  && inputs.roleID){
        if(openUser?.type ===0 && inputs.password){
          dispatch(userActions.add(inputs));
        }
        if(openUser?.type ===1){
          dispatch(userActions.edit(inputs));
        }
      }
    }


  const handleChangeMultiplePermissions = (event: any) => {

      var permissionID = event.target.value as number;
      var index =inputs?.permission?.findIndex(x=>{
        return x.permissionID == permissionID
      });
      var currentSelect = permissions.filter(x=> x.permissionID == permissionID)[0];

      if((index!)>-1){
        inputs.permission! = inputs.permission!.filter((item)=> item.permissionID!=permissionID );
      }
      else{
        inputs.permission!.push(currentSelect);
      }
      setInputs(inputs => ({ ...inputs, 'permission': inputs.permission }));
};

  function handleChange(e:any) {
      const { name, value } = e.target;
      if(name=="roleID"){
        setInputs(inputs => ({ ...inputs, "hirarchySelect": new HierarchySelect({rolID: value}) }));
        dispatch(userActions.setOpenModalSelectScreen({
          open : true,
          select : new HierarchySelect( { rolID: value}),
          type : 0,
          loading: false
        }));
      }
      else{        
        setInputs(inputs => ({ ...inputs, [name]: value }));
      }
  }

    return (
      <Dialog  TransitionComponent={Transition} id="inventoryDialog"  fullWidth={true} maxWidth="sm" aria-labelledby="simple-dialog-title" open={openUser?.open??false}>
        <DialogTitle onClose={handleClose}  id="simple-dialog-title" > {openUser?.type ===0 ? "Agregar": "Editar"} Usuario </DialogTitle>
        <DialogContent dividers>
  

        {
          openUser?.loading ?  <CircularProgress />:
   
        <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
        <Grid container spacing={3}>

        <Grid item xs={6}>
              <TextField
                    id="userID"
                    label="Código de Usuario"
                    type="text"
                    placeholder="Código de Usuario"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    name="employeeID"
                    disabled={true}
                    value={inputs?.userID}
                    error={(submitted && !inputs?.userID)}   helperText={(submitted && !inputs?.userID) ? "Es Requerido":""}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid> 
        <Grid item xs={6}>
              <TextField
                    id="userName"
                    label="Usuario"
                    type="text"
                    placeholder="Usuario"
                    fullWidth
                    size="small"    
                    onChange={handleChange}
                    name="userName"
                    value={inputs?.userName}
                    error={(submitted && !inputs?.userName)}   helperText={(submitted && !inputs?.userName) ? "Es Requerido":""}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid> 
            <Grid item xs={6}>
                  <FormControl fullWidth margin='normal' >
                        <InputLabel  shrink>
                            Rol
                          </InputLabel>
                            <Select
                              value={inputs?.roleID}
                              onChange={handleChange}
                              
                              //onClose={handleChange}
                              displayEmpty
                              label="Rol"
                                size="small"
                              type="number"
                              
                              // multiple
                              // renderValue={(selected) => (selected as string[]).join(', ')}
                              placeholder="Seleccione un rol"
                              name="roleID"
                              error={(submitted && !inputs?.roleID)}   
                              // helperText=
                              inputProps={{ 'aria-label': 'Without label' }}
                            >
                               {
                                roles.map((item: any)=>{
                                  return <MenuItem  value={item.roleID}> {item.name}</MenuItem>
                                })
                              }
                            </Select>
                            {
                              (submitted &&  !inputs?.roleID) ? <FormHelperText error >Es requerido</FormHelperText>:""
                            }
                            
                  </FormControl>
              </Grid>
              <Grid item xs={6}>
              <TextField
                    id="hierarchySelect"
                    label="Asingado"
                    type="text"
                    disabled={true}
                    placeholder="Asingado"
                    fullWidth
                    size="small"
                    name="password"
                    value={inputs?.hirarchySelect?.name}
                    error={(submitted && !inputs?.hirarchySelect?.name && openUser.type ==0)}   helperText={(submitted && !inputs?.hirarchySelect?.name && openUser.type ==0) ? "Es Requerido":""}
                    // onKeyDown={handleChangeBarCode}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
              </Grid>
            <Grid item xs={12}>
              <TextField
                    id="password"
                    label="Contraseña"
                    type="password"
                    placeholder="Contraseña"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    name="password"
                    value={inputs?.password}
                    error={(submitted && !inputs?.password && openUser.type ==0)}   helperText={(submitted && !inputs?.password && openUser.type ==0) ? "Es Requerido":""}
                    // onKeyDown={handleChangeBarCode}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid> 

                        
            {/* <Grid item xs={12}>
              <Box >
                <FormControl >
                    <InputLabel shrink >
                        Almacenes
                    </InputLabel>
                        <Select
                          value={inputs?.wareHouse}
                          onChange={handleChangeMultiple}
                          displayEmpty
                          label="Almacén"
                        size="small"
                          placeholder="Seleccione un Almacén"
                          name="wareHouses"
                          error={(submitted && (inputs?.wareHouse??[]).length ==0 )}   
                          renderValue={(selected) => ((selected ??[]) as WareHouse[]).map((item)=>{
                            return (item?.name??"")?.length > 6?item.name?.substr(0,6) : item.name
                          }).join(', ')}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          {
                            locations.map((item: WareHouse)=>{
                              return <MenuItem dense={true} value={item.wareHouseID}>
                                <Checkbox color="primary" checked={(inputs?.wareHouse??[]).map((ware: WareHouse)=> ware!.wareHouseID).indexOf(item!.wareHouseID) > -1} />
                                  <ListItemText primary={item.name} />
                                </MenuItem>
                            })
                          }
                        </Select>
                        {
                          (submitted && !inputs?.wareHouse) ? <FormHelperText error >Es requerido</FormHelperText>:""
                        }
                      </FormControl>
                  </Box>
            </Grid> */}

                {/* <Grid item xs={12}>
              <Box >
                <FormControl >
                    <InputLabel shrink >
                        Accesos
                    </InputLabel>
                        <Select
                          value={inputs?.permission}
                          onChange={handleChangeMultiplePermission}
                          displayEmpty
                          label="Acceso"
                        size="small"
                          placeholder="Seleccione un Acceso"
                          name="permission"
                          error={(submitted && (inputs?.permission??[]).length ==0 )}   
                          renderValue={(selected) => ((selected ??[]) as Permission[]).map((item)=>{
                            return (item?.name??"")?.length > 6?item.name?.substr(0,6) : item.name
                          }).join(', ')}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          {
                            permissions.map((item: Permission)=>{
                              return <MenuItem dense={true} value={item.permissionID}>
                                <Checkbox color="primary" checked={(inputs?.permission??[]).map((ware: Permission)=> ware!.permissionID).indexOf(item!.permissionID) > -1} />
                                  <ListItemText primary={item.description} />
                                </MenuItem>
                            })
                          }
                        </Select>
                        {
                          (submitted && !inputs?.wareHouse) ? <FormHelperText error >Es requerido</FormHelperText>:""
                        }
                      </FormControl>
                  </Box>
            </Grid>
         */}
              <Grid item xs={12}>
              <Box >
                <FormControl fullWidth>
                    <InputLabel shrink >
                        Permisos
                    </InputLabel>
                        <Select
                          value={inputs?.permission}
                          onChange={handleChangeMultiplePermissions}
                          displayEmpty
                          label="Permisos"
                        size="small"
                          placeholder="Seleccione un Permiso"
                          name="permissions"
                          error={(submitted && (inputs?.permission??[]).length ==0 )}   
                          renderValue={(selected) => ((selected ??[]) as Permission[]).map((item)=>{
                            return (item?.description??"")?.length > 6?item.description?.substr(0,6) : item.description
                          }).join(', ')}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          {
                            permissions.map((item: Permission)=>{
                              return <MenuItem  dense={true} value={item.permissionID}>
                                <Checkbox color="primary" checked={(inputs?.permission??[]).map((ware: Permission)=> ware!.permissionID).indexOf(item!.permissionID) > -1} />
                                  <ListItemText primary={item.description }  secondary={item.type ==1? "Acceso WEB": "Acceso APP"} />
                                  {
                                      item.type == 1 ? <LaptopChromebookIcon style={{color:"#00adff"}} /> : <AndroidIcon style={{color:"#05a903"}} />
                                  }
                                </MenuItem>
                            })
                          }
                        </Select>
                        {
                          (submitted && !inputs?.permission) ? <FormHelperText error >Es requerido</FormHelperText>:""
                        }
                      </FormControl>
                  </Box>
            </Grid>


            {
              openUser.type ==1?
                <Grid item xs={12}>    
                      <FormControl required component="fieldset">
                        <FormControlLabel
                          control={
                            <Checkbox color="primary" name="active" checked={inputs?.active } onChange={handleChangeCheck}  />
                          }
                          label="Activo"
                        />
                        {/* <FormHelperText>You can display an error</FormHelperText> */}
                      </FormControl>
                  </Grid>
                :<Box></Box>
            }
            </Grid>
                  <Box my={3} >
                    <Button type="submit" variant="contained" color="primary">
                    {openUser?.type ===0 ? "Agregar": "Editar"} Usuario 
                    </Button>
                  </Box>
                  </form>        
              }
         <SelectHierarchyDialog onClose={handleCloseSelect}></SelectHierarchyDialog>
        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { DialogEditUser };
