export class GuaranteeImage {
    guaranteeImageID?: number;
    guaranteeID?: number;
    imageUri?: string;

    constructor(datos?: GuaranteeImage) {
      if (datos != null) {
        this.guaranteeImageID = datos.guaranteeImageID;
        this.guaranteeID = datos.guaranteeID;
        this.imageUri = datos.imageUri;
        return;
      }
       
    }
  }
