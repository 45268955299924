import { Dispatch } from 'redux';
import { DataDigaloProps } from '../../../models/data_digalo_props';
import { alertActions } from '../../../_actions/alert.actions';
import { settingConstants } from '../constants/setting.constants';
import { Setting } from '../models/setting';
import { settingService } from '../setting.service';



export const settingActions = {
    getAll,
    setOpenModalSettingScreen,
    edit
};


function getAll() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        settingService.getAll()
            .then(
                data => dispatch(success(data.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: settingConstants.GETALL_REQUEST } }
    function success(setting:any) { return { type: settingConstants.GETALL_SUCCESS, setting } }
    function failure(error:any) { return { type: settingConstants.GETALL_FAILURE, error } }
}



function setOpenModalSettingScreen(openSetting:DataDigaloProps<Setting>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: settingConstants.SET_OPEN_SETTING_SUCCESS, openSetting });
    };
}



function edit(setting:Setting) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        settingService.edit(setting)
            .then(
                data => dispatch(success(data.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    
    function request() { return { type: settingConstants.EDIT_SETTING_REQUEST } }
    function success(setting:Setting) { return { type: settingConstants.EDIT_SETTING_SUCCESS, setting } }
    function failure(error:any) { return { type: settingConstants.EDIT_SETTING_FAILURE, error } }
}