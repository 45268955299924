import { authConstants } from "../_constants/auth.constants";


let user = localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')?? ""): null;
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action:any) {
    switch (action.type) {
        case authConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                loading: true,
                user: action.user
            };
        case authConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                loading: false,
                user: action.user
            };
        case authConstants.LOGIN_FAILURE:
            return {
                loading: false,
            };
        case authConstants.LOGOUT:
            return {
                loading: false,
            };
        default:
            return state
    }
}