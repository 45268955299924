export const hierarchyConstants = {

    GETALL_REQUEST: 'GETALL_REQUEST',
    GETALL_SUCCESS: 'GETALL_SUCCESS',
    GETALL_FAILURE: 'GETALL_FAILURE',



    ADD_SUPERVISOR_REQUEST: 'ADD_SUPERVISOR_REQUEST',
    ADD_SUPERVISOR_SUCCESS: 'ADD_SUPERVISOR_SUCCESS',
    ADD_SUPERVISOR_FAILURE: 'ADD_SUPERVISOR_FAILURE',

    EDIT_SUPERVISOR_REQUEST: 'EDIT_SUPERVISOR_REQUEST',
    EDIT_SUPERVISOR_SUCCESS: 'EDIT_SUPERVISOR_SUCCESS',
    EDIT_SUPERVISOR_FAILURE: 'EDIT_SUPERVISOR_FAILURE',



    ADD_ADVISER_REQUEST: 'ADD_ADVISER_REQUEST',
    ADD_ADVISER_SUCCESS: 'ADD_ADVISER_SUCCESS',
    ADD_ADVISER_FAILURE: 'ADD_ADVISER_FAILURE',

    EDIT_ADVISER_REQUEST: 'EDIT_ADVISER_REQUEST',
    EDIT_ADVISER_SUCCESS: 'EDIT_ADVISER_SUCCESS',
    EDIT_ADVISER_FAILURE: 'EDIT_ADVISER_FAILURE',

    SET_OPEN_ADVISER_SUCCESS: 'SET_OPEN_ADVISER_SUCCESS' , 
    SET_OPEN_SUPERVISOR_SUCCESS: 'SET_OPEN_SUPERVISOR_SUCCESS' , 

};