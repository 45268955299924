import axios from 'axios';
import environment from '../../environment';
import { Adviser } from './models/adviser';
import { Supervisor } from './models/supervisor';


export const hierarchyService = {
    getAll,
    addSupervisor,
    editSupervisor,
    addAdviser,
    editAdviser
};


 function getAll() {
    return axios.get(environment.apiUri + "/user/GetHierachy" )
  };


  function addSupervisor(supervisor: Supervisor) {
    return axios.post(environment.apiUri + "/user/supervisor",supervisor )
  };
  
  function editSupervisor(supervisor: Supervisor) {
    return axios.put(environment.apiUri + "/user/supervisor",supervisor )
  };
  
  function addAdviser(adviser: Adviser) {
    return axios.post(environment.apiUri + "/user/adviser",adviser )
  };
  
  function editAdviser(adviser: Adviser) {
    return axios.put(environment.apiUri + "/user/adviser",adviser )
  };
  
  