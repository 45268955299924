export class AddressImage {
    addressImageID?: number;
    addressID?: number;
    imageUri?: string;

    constructor(datos?: AddressImage) {
      if (datos != null) {
        this.addressImageID = datos.addressImageID;
        this.addressID = datos.addressID;
        this.imageUri = datos.imageUri;
        return;
      }
       
    }
  }
