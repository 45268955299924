import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container , CircularProgress, Box, TextField, Grid, Button, InputAdornment, Typography, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, FormHelperText, Card} from '@mui/material';
import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { format, toEsDate } from '../../_helpers/format';
import { FilterParams } from '../reports/models/filter_params';
import { SpentType } from './models/spent_type';
import { Spent } from './models/spent';
import { spentActions } from './actions/spent.action';
import { DialogEditSpent } from './dialog.edit.spent';

function SpentsListPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.spent.loading);
  const spents = useSelector((state: any) => state.spent.spents as Spent[] );
  const alert = useSelector((state: any) => state.alert.message);
  const spentTypes = useSelector((state:any)=> state.spent.spentTypes as SpentType[])
  const [submitted, setSubmitted] = React.useState(false);
  const [inputs, setInputs] = React.useState<FilterParams>(new FilterParams());
    
    useEffect(() => {
      if((spentTypes??[]).length == 0){
        dispatch(spentActions.getSpentType());
      }
    }, []);

    function handleChange(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if(inputs?.fromDate && inputs.toDate ){
        dispatch(spentActions.getByDate(inputs.fromDate.toString(), inputs.toDate.toString()));
      }
    }

    const handleClose = (value: string) => {
      // dispatch(inventoryActions.setInventoryScreen({
      //   open : false,
      //   select :new Inventory(),
      //   type : 0,
      //   loading:false
      // }));
    };

    const handleClickOpen = (row: Spent, type: number) => {
      dispatch(spentActions.setOpenModalSpentScreen(
        {
        open : true,
        select :row,
        type : type,
        loading:false
      }));
    };


    const columns: GridColDef[] = [
       {
         field: 'spentId',
         headerName: 'No Gasto',
         width: 110,
         align:'center'
       },
       {
         field: 'description',
         headerName: 'Comentario',
         width: 220,
       },
       {
         field: 'createDate',
         headerName: 'Fecha',
         width: 120,
         valueFormatter: (params) => {
          
            if(params?.value){
              var da = params?.value as string;
              return toEsDate(da);
            } 
            else{
              return "X";
            }
         },
       },
      {
        field: 'amount',
        headerName: 'Total',
        width: 170,
        valueFormatter: (params) => {
          if(params?.value!= undefined){
            return "L. "+ format((params?.value as number)??0, 2);
          } 
          else{
            return "-1";
          }
       },
      },
      {
        field: 'user',
        headerName: 'Usuario',
        width: 210,
      },
     ];
       
    return (
        <Box my={2}>

            <Container  >
            <Card  variant="outlined" style={{padding:"5px 20px 25px 20px",}} >

            <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
                    <Box my={2} >
                        <Button variant="contained"  color="primary" type="submit" onClick={()=>{
                                var spent = new Spent();
                                handleClickOpen( spent, 0);
                                }
                                }>
                              Agregar
                          </Button>
                      </Box>
              </Grid>


              {alert && <Box my={2} >{alert}</Box>}
              {
                loading? 
                <Box my={2}>
                    <CircularProgress title="Cargando..." />
                </Box>
                :
                <Container >
                    
                    <Box>
                      <Typography variant="h5" gutterBottom component="div">
                        Gastos por rango de fecha
                      </Typography>
                    </Box>
                    <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <TextField
                                  id="fromDate"
                                  label="Desde"
                                  type="date"
                                  placeholder="Desde"
                                  fullWidth
                                  size="small"
                                  onChange={handleChange}
                                  name="fromDate"
                                  value={inputs?.fromDate}
                                  error={(submitted && !inputs?.fromDate)}   helperText={(submitted && !inputs?.fromDate) ? "Es Requerido":""}
                                  margin="normal"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <DateRangeIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                          </Grid> 
                          <Grid item xs={3}>
                                <TextField
                                      id="toDate"
                                      label="Hasta"
                                      type="date"
                                      placeholder="Hasta"
                                      fullWidth
                                      size="small"    
                                      onChange={handleChange}
                                      name="toDate"
                                      value={inputs?.toDate}
                                      error={(submitted && !inputs?.toDate)}   helperText={(submitted && !inputs?.toDate) ? "Es Requerido":""}
                                      margin="normal"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <DateRangeIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                            </Grid> 
                           

                            <Grid item xs={3}>
                                <Box my={2} >
                                  <Button type="submit" variant="contained" color="primary">
                                      Buscar 
                                  </Button>
                                </Box>
                            </Grid> 
                          
                          </Grid>
                       
                    </form>   

                    <Grid container spacing={3}>
                            <Grid item xs={12} >
                        
                              <DataGrid autoHeight 
                                  rows={spents}
                                  getRowId={(r) => r.spentId}
                                  columns={columns}
                                  // components={{
                                  //   Toolbar:CustomToolbar
                                  // }}      
                                  pageSize={100}
                                  rowsPerPageOptions={[5]}
                                  nonce="No se encontraron resultados"
                                  density="compact"
                                  checkboxSelection={false}
                                  onCellClick={(params, event) => {
                                      console.log(params.row)
                                      handleClickOpen(params.row as Spent, 1)
                                    }}
                                  
                                  disableSelectionOnClick
                              />
                            </Grid>
                          </Grid>


                </Container>
              }
              <DialogEditSpent onClose={handleClose} /> 
        </Card>
            </Container>
        </Box>
    );
}

export { SpentsListPage };
