
import { useDispatch, useSelector } from 'react-redux';
import {
    Route,
    Redirect,
    RouteProps,
} from 'react-router-dom';
import { User } from '../pages/user/models/user';
import { alertActions } from '../_actions/alert.actions';

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
    isSignedIn: boolean;
}

const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, isSignedIn,path,  ...rest } = props;
    const  user: User  = useSelector((state: any) => state.authentication.user);
    var hasPermission = (user?.permission??[]).filter((x=> x.path == path)).length > 0;
    const dispatch = useDispatch();
    if((isSignedIn && hasPermission) || (isSignedIn && path=="/")){
        return ( <Route {...rest} render={(routeProps:any) => ( <Component {...routeProps} /> )  } /> );
    }   
    if(isSignedIn && !hasPermission){
         dispatch(alertActions.error("No tiene acceso"));
        return (<Route {...rest}
            render={(routeProps:any) =>(
                        <Redirect
                            to={{
                                pathname: '/',
                                state: { from: routeProps.location }
                            }} /> 
                    )} />)
    }
    return (
        <Route
            {...rest}
            render={(routeProps:any) =>
                    (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: routeProps.location }
                            }}
                        />
                    )
            }
        />
    );
};

export default PrivateRoute;
