export const filter = (arr, searchKey) => {
    if(searchKey){
    return arr.filter((obj) =>
        Object.keys(obj).some((key) => {
        return (obj[key]
            ?.toString() ??"")
            .toLowerCase()
            .includes(searchKey.toLowerCase());
        })
    );
    }
    return arr;
};