import { alertActions } from './alert.actions';
import { Dispatch } from 'redux';
import { Customer } from '../models/customer/customer';
import { DataDigaloProps } from '../models/data_digalo_props';
import { inscriptionConstants } from '../_constants/inscription.constants';
import { Lending } from '../models/lending/lending';
import { paymentService } from '../_services/payment.service';
import { paymentConstants } from '../_constants/payment.constants';
import { PaymentRequest } from '../models/payment/payment_request';

export const paymentActions = {
    setOpenCustomerScreen,
    searchByDecription,
    getPendientPaymentCustomer,
    setOpenLendingScreen,
    setOpenPaymentScreen,
    post,
    getExpedient
};


 
function setOpenCustomerScreen(openCustomer:DataDigaloProps<Customer>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: inscriptionConstants.SET_OPEN_CUSTOMER_SUCCESS, openCustomer });
    };
}

function setOpenPaymentScreen(openRequest:DataDigaloProps<PaymentRequest>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: paymentConstants.SET_OPEN_REQUEST_SUCCESS, openRequest });
    };
}
function setOpenLendingScreen(openLending:DataDigaloProps<Lending>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: paymentConstants.SET_OPEN_LENDING_SUCCESS, openLending });
    };
}



function getPendientPaymentCustomer(customerID: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        paymentService.getPendientPaymentCustomer(customerID)
            .then(
                
                payments => dispatch(success(payments.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: paymentConstants.GET_PENDIENT_PAYMENT_REQUEST } }
    function success(paymentRequest:PaymentRequest[]) { return { type: paymentConstants.GET_PENDIENT_PAYMENT_SUCCESS, paymentRequest } }
    function failure(error:any) { return { type: paymentConstants.GET_PENDIENT_PAYMENT_FAILURE, error } }
}
function getExpedient(officeID: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        paymentService.getExpedient(officeID)
            .then(
                
                lendings => dispatch(success(lendings.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: paymentConstants.GET_EXPEDIENT_REQUEST } }
    function success(recordLendings:Lending[]) { return { type: paymentConstants.GET_EXPEDIENT_SUCCESS, recordLendings } }
    function failure(error:any) { return { type: paymentConstants.GET_EXPEDIENT_FAILURE, error } }
}
function searchByDecription(officeID: string, description: string ) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        paymentService.searchByDecription(officeID,description)
            .then(
                
                lendings => dispatch(success(lendings.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: paymentConstants.GET_EXPEDIENT_REQUEST } }
    function success(recordLendings:Lending[]) { return { type: paymentConstants.GET_EXPEDIENT_SUCCESS, recordLendings } }
    function failure(error:any) { return { type: paymentConstants.GET_EXPEDIENT_FAILURE, error } }
}


function post(payment:PaymentRequest) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        paymentService.post(payment)
            .then(
                
                payments => dispatch(success(payments.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: paymentConstants.POST_PAYMENT_REQUEST } }
    function success(lending:Lending) { return { type: paymentConstants.POST_PAYMENT_SUCCESS, lending } }
    function failure(error:any) { return { type: paymentConstants.POST_PAYMENT_FAILURE, error } }
}



// function posting(lending:Lending) {
//     return (dispatch: Dispatch) => {
//         dispatch(request());
//         dispatch(alertActions.clear());
//         inscriptionService.posting(lending)
//             .then(
                
//                 lendings => dispatch(success(lendings.data)),
//                 //error => dispatch(failure(error.toString()))
//             ).catch((error)=>{
//                 const message =
//                 (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//                 dispatch(failure(message));
//                 dispatch(alertActions.error(message));
//             });
//     };

//     function request() { return { type: inscriptionConstants.POSTINGT_LENDING_REQUEST } }
//     function success(lendings:Lending[]) { return { type: inscriptionConstants.POSTING_LENDING_SUCCESS, lendings } }
//     function failure(error:any) { return { type: inscriptionConstants.POSTING_LENDING_FAILURE, error } }
// }
