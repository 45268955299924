import MuiDialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogContent, IconButton, Typography } from "@mui/material";
import React, { useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { DataDigaloProps } from '../../../models/data_digalo_props';
import { PaymentRequest } from '../../../models/payment/payment_request';
import { paymentActions } from '../../../_actions/payment.action';

export interface DialogTitleProps  {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = ((props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle   {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close"  onClick={onClose}
            sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}

>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});




export interface SimpleDialogProps {
    onClose: (value: PaymentRequest) => void;
  }
  
  function DialogChoiseLending(props: SimpleDialogProps) {
    const { onClose} = props;
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    
    const  pendients  = useSelector((state: any) => state.payments.pendients as PaymentRequest[]);
    const  loading  = useSelector((state: any) => state.customers.loading);
    const openPendients = useSelector((state:any) => state.payments.openPendients as DataDigaloProps<PaymentRequest> );
    const [submitted, setSubmitted] = React.useState(false);
    const  alert  = useSelector((state: any) => state.alert.message);
    
    // const [inputs, setInputs] = React.useState<Customer>(openPendients?.select);
    // useEffect(() => {
    //   setInputs(openPendients?.select)
    //   setSubmitted(false)
    // }, [openCustomer]);



    useEffect(() => {
      // if(customers.length == 0){
      //   dispatch(customerActions.getAll());
      // }
    }, []);

    const columnsAddress: GridColDef[] = [
      {
        field: 'lendingID',
        headerName: 'No. Prestamo',
        width: 150,
      },      
   
      {
        field: 'customerID',
        headerName: 'Cliente ID',
        width: 150,
      },      
      {
        field: 'amount',
        headerName: 'Monto',
        width: 150,
      },    
 
    ];


    const handleClose = (payment?:PaymentRequest) => {
      onClose(payment ?? new PaymentRequest());//TODO 
    //   setSubmitted(false)
      dispatch(paymentActions.setOpenPaymentScreen({
        open: false,
        select :payment ?? new PaymentRequest(),
        type : 0,
        loading:false
    }));
    };


  
    return (
      <Dialog   id="customerDialog" fullWidth={true} maxWidth="md"  aria-labelledby="simple-dialog-title" open={openPendients?.open ??false}>
        <DialogTitle onClose={()=>{handleClose(new PaymentRequest())}}  id="simple-dialog-title" > Seleccione un Prestamo </DialogTitle>
   
        <DialogContent dividers>    

        <Box  my={2}>
              <DataGrid autoHeight 
                  rows={pendients}
                  getRowId={(r) => r.lendingID}
                  columns={columnsAddress}
                  pageSize={100}
                  loading={loading}
                  density="compact"
                  rowsPerPageOptions={[5]}
                  
                  checkboxSelection={false}
                  onCellClick={(params, event) => {
                      handleClose(params.row);
                    }}
                  
                  disableSelectionOnClick
              />
        </Box>

        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { DialogChoiseLending };