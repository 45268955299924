export class LendingImage {
    lendingImageId?: number;
    lendingID?: number;
    imageUri?: string;

    constructor(datos?: LendingImage) {
      if (datos != null) {
        this.lendingImageId = datos.lendingImageId;
        this.lendingID = datos.lendingID;
        this.imageUri = datos.imageUri;
        return;
      }
    }
  }
