export class PersonalReferent {
    referentID?: number;
    customerID?: string;
    name?: string;
    dni?: string;
    address?: string;
    phone?: number;
    createDate?: number;
    active?: boolean;
    relation?:string;
    
    constructor(datos?: PersonalReferent) {
      if (datos != null) {
        this.customerID = datos.customerID;
        this.name = datos.name;
        this.dni = datos.dni;
        this.address = datos.address;
        this.phone = datos.phone;
        this.createDate = datos.createDate;
        this.active = datos.active;
        this.relation = datos.relation;
        return;
      }
       
    }
  }
