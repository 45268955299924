import axios, { AxiosRequestConfig } from 'axios';
import environment from '../environment';
import { Customer } from '../models/customer/customer';
import { CustomerAddress } from '../models/customer/customer_address';
import { Guarantee } from '../models/customer/guarantee';
import { PersonalReferent } from '../models/customer/personal_referent';

export const customerService = {
    getAll,
    addImage,
    searchByDecription,
    addAddress,
    editAddress,
    addCustomer,
    editCustomer,
    addReferent,
    editReferent,
    addGuarantee,
    editGuarantee
};


 function getAll() {
    return axios.get(environment.apiUri + "/customer")
  };
 function searchByDecription(description: string) {
    return axios.get(environment.apiUri + "/customer/CustomerByName/" +description )
  };
 function addAddress(address:CustomerAddress) {
    return axios.post(environment.apiUri + "/customer/addAddress", address)
  };
  
 function editAddress(address:CustomerAddress) {
    return axios.post(environment.apiUri + "/customer/editAddress", address)
  };

  function addCustomer(customer:Customer) {
    return axios.post(environment.apiUri + "/customer", customer)
  };
  
 function editCustomer(customer:Customer) {
    return axios.put(environment.apiUri + "/customer", customer)
  };
  
  function addReferent(referent:PersonalReferent) {
    return axios.post(environment.apiUri + "/customer/addReferent", referent)
  };
  
 function editReferent(referent:PersonalReferent) {
    return axios.put(environment.apiUri + "/customer/editReferent", referent)
  };
  function addGuarantee(guarantee:Guarantee) {
    return axios.post(environment.apiUri + "/customer/addGuarantee", guarantee)
  };
  
 function editGuarantee(guarantee:Guarantee) {
    return axios.put(environment.apiUri + "/customer/editGuarantee", guarantee)
  };
  
  function addImage(file:File) {
    let FormData = require('form-data');
    let data = new FormData();
    data.append('Image', file);

    let config:AxiosRequestConfig = {
      method: 'post',
      url: environment.apiUri + "/common/image/Upload/",
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    };


    return axios(config);
  };
  

// function getAll() {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };

//     //return fetch(`${apiUri}/users`, requestOptions).then(handleResponse);
// }

