import axios from 'axios';
import environment from '../../environment';
import { Setting } from './models/setting';


export const settingService = {
    getAll,
    edit,
};


 function getAll() {
    return axios.get(environment.apiUri + "/common/GetSetting" )
  };


  function edit(setting: Setting) {
    return axios.put(environment.apiUri + "/common/editSetting",setting )
  };
  
 