export class Setting {
    settingID?: number;
    name?: string;
    rtn?: string;
    phone?: string;
    email?: string;
    address?: string;
    fine?: number;
    interestPercent?: number;
    
    constructor(datos?: Setting) {
      if (datos != null) {
        this.settingID = datos.settingID;
        this.name = datos.name;
        this.rtn = datos.rtn;
        this.phone = datos.phone;
        this.email = datos.email;
        this.address = datos.address;
        this.fine = datos.fine;
        this.interestPercent = datos.interestPercent;
        return;
      }
    }
  }
