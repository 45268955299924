import MuiDialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, DialogContent, Grid, IconButton,  InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState , useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customerActions } from '../../../_actions/customer.actions';
import { PersonalReferent } from '../../../models/customer/personal_referent';
import { bytesToSize} from '../../../_helpers/common';
import { Guarantee } from '../../../models/customer/guarantee';
import { alertActions } from '../../../_actions/alert.actions';
import { DataDigaloProps } from '../../../models/data_digalo_props';
import { ImagePicker } from '../../../_components/ImagePicker';



export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = ((props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle   {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close"  onClick={onClose}
            sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}

>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});




export interface DialogAddressProps {
    onClose: (value: string) => void;
  }
  
  function DialogGuarantee(props: DialogAddressProps) {
    const { onClose} = props;
    const dispatch = useDispatch();
    const  openGuarantee  = useSelector((state: any) => state.customers.openGuarantee as DataDigaloProps<Guarantee>);
    const [inputs, setInputs] = React.useState<Guarantee>(openGuarantee?.select);
   
     const [submitted, setSubmitted] = React.useState(false);

     useEffect(() => {
         setInputs(openGuarantee?.select)
         setSubmitted(false)
    }, [openGuarantee]);





    const handleClose = () => {
      setInputs(new PersonalReferent())
      dispatch(customerActions.setOpenGuaranteeScreen({
        open : false,
        select : new Guarantee(),
        type : 0,
        loading: false,
      }));
      onClose("Hola");
      setSubmitted(false)
    };
  
    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.brand &&  inputs.cost && inputs.description ) {
        inputs.customerID = openGuarantee?.select?.customerID;
        inputs.contractUri="";
          if(openGuarantee?.type ===0){
            dispatch(customerActions.addGuarantee(inputs));
          }
          if(openGuarantee?.type ===1){
            inputs.guaranteeID = openGuarantee?.select?.guaranteeID;
            dispatch(customerActions.editGuarantee(inputs));
          }
      }
    }

    function handleChangeCroquis(e: any){
      let uploadedImg = e;
      const ACCEPTED_UPLOAD_SIZE = 1024; // 1MB
      if ( bytesToSize(uploadedImg.size) > ACCEPTED_UPLOAD_SIZE
      ) {
        dispatch(alertActions.error("La imagen es demasiado grande"));
        return;
      }
      dispatch(customerActions.addImageGurantee( inputs,uploadedImg));
    }

    

    // const handleListItemClick = (value: string) => {
    //   onClose(value);
    // };
    // const handleChangeActive = (e:any,value: boolean) => {
    //   setInputs(inputs => ({ ...inputs, active: value }));
    // };

    function handleChange(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  
    return (
      <Dialog  id="customerAddressDialog"  fullWidth={true} maxWidth="sm"aria-labelledby="simple-dialog-title" open={openGuarantee?.open??false}>
        <DialogTitle onClose={handleClose}  id="simple-dialog-title" > {openGuarantee?.type ===0 ? "Agregar": "Editar"} Garantía </DialogTitle>
        <DialogContent dividers>
        {
          openGuarantee?.loading ?  <CircularProgress />:
   
        <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
        <Grid container spacing={3}>

   
            

            <Grid item xs={7}>
              <TextField
                    id="description"
                    label="Descripción"
                    type="text"
                    placeholder="Descripción"
                    fullWidth
                    name="description"
                    value={inputs?.description}
                    error={(submitted && !inputs?.description)}   helperText={(submitted && !inputs?.description) ? "Es Requerido":""}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>

            <Grid item xs={5}>
              <TextField
                    id="brand"
                    label="Marca"
                    type="text"
                    placeholder="Marca"
                    fullWidth
                    name="brand"
                    value={inputs?.brand}
                    error={(submitted && !inputs?.brand)}   helperText={(submitted && !inputs?.brand) ? "Es Requerido":""}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>
            <Grid item xs={7}>    
                  <TextField
                    id="serie"
                    label="Serie"
                    type="text"
                    fullWidth
                    name="serie"
                    placeholder="Serie"
                    value={inputs?.serie}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>
  
            <Grid item xs={5}>
                <TextField
                    id="cost"
                    label="Costo"
                    type="number"
                    fullWidth
                    name="cost"
                    placeholder="Costo"
                    error={(submitted && !inputs?.cost)}   helperText={(submitted && !inputs?.cost) ? "Es Requerido":""}
                    value={inputs?.cost}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">L</InputAdornment>,
                    }}
                  />
            </Grid>

        

            <Grid item xs={12}>
                  <ImagePicker onImageAdd={handleChangeCroquis} images={(inputs?.images ??[])} loading={openGuarantee?.loading}/>
          
              </Grid>


            <Box my={3} >
              <Button  type="submit" variant="contained" color="primary">
              {openGuarantee?.type ===0 ? "Guardar": "Editar"} Garantía 
              </Button>
            </Box>

            </Grid>
            </form>

             
}
         
       
        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { DialogGuarantee };