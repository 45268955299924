export class CustomerImage {
    customerImageID?: number;
    customerID?: number;
    imageUri?: string;

    constructor(datos?: CustomerImage) {
      if (datos != null) {
        this.customerImageID = datos.customerImageID;
        this.customerID = datos.customerID;
        this.imageUri = datos.imageUri;
        return;
      }
       
    }
  }
