import { Dispatch } from 'redux';
import { DataDigaloProps } from '../../../models/data_digalo_props';
import { User } from '../models/user';
import { alertActions } from '../../../_actions/alert.actions';
import { userConstants } from '../constants/user.constants';
import { userService } from '../user.service';
import { HierarchySelect } from '../../hierarchy/models/hierarchy.select';



export const userActions = {
    getAll,
    getRoles,
    getHierarchies,
    getPermissions,
    setOpenModalUserScreen,
    setOpenModalSelectScreen,
    add,
    edit
};


function setOpenModalUserScreen(openUser:DataDigaloProps<User>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: userConstants.SET_OPEN_USER_SUCCESS, openUser });
    };
}

function setOpenModalSelectScreen(openHierarchy:DataDigaloProps<HierarchySelect>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: userConstants.SET_OPEN_SELECT_HIERARCHY_SUCCESS, openHierarchy });
    };
}

function getAll() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        userService.getAll()
            .then(
                users => dispatch(success(users.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users:any) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error:any) { return { type: userConstants.GETALL_FAILURE, error } }
}


function getRoles() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        userService.getRoles()
            .then(
                roles => dispatch(success(roles.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: userConstants.GET_ROLES_REQUEST } }
    function success(roles:any) { return { type: userConstants.GET_ROLES_SUCCESS, roles } }
    function failure(error:any) { return { type: userConstants.GET_ROLES_FAILURE, error } }
}

function getHierarchies(roleID: number) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        userService.getHierarchies(roleID)
            .then(
                roles => dispatch(success(roles.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: userConstants.GET_FROM_HIERARCHY_REQUEST } }
    function success(hierarchies:any) { return { type: userConstants.GET_FROM_HIERARCHY_SUCCESS, hierarchies } }
    function failure(error:any) { return { type: userConstants.GET_FROM_HIERARCHY_FAILURE, error } }
}

function getPermissions() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        userService.getPermissions()
            .then(
                permissions => dispatch(success(permissions.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: userConstants.GET_PERMISSION_REQUEST } }
    function success(permissions:any) { return { type: userConstants.GET_PERMISSION_SUCCESS, permissions } }
    function failure(error:any) { return { type: userConstants.GET_PERMISSION_FAILURE, error } }
}






function add(user:User) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        userService.add(user)
            .then(
                users => dispatch(success(users.data,)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message, user));
                dispatch(alertActions.error(message));
            });
    };
    
    function request() { return { type: userConstants.ADD_USER_REQUEST } }
    function success(users:User[]) { return { type: userConstants.ADD_USER_SUCCESS, users } }
    function failure(error:any, user: User) { return { type: userConstants.ADD_USER_FAILURE, error, user } }
}
function edit(user:User) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        userService.edit(user)
            .then(
                users => dispatch(success(users.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message, user));
                dispatch(alertActions.error(message));
            });
    };
    
    function request() { return { type: userConstants.EDIT_USER_REQUEST } }
    function success(users:User[]) { return { type: userConstants.EDIT_USER_SUCCESS, users } }
    function failure(error:any, user: User) { return { type: userConstants.EDIT_USER_FAILURE, error ,  user } }
}