import { customerConstants } from '../_constants/customer.constants';
import { alertActions } from './alert.actions';
import { customerService } from '../_services/customer.service';
import { Dispatch } from 'redux';
import { CustomerAddress } from '../models/customer/customer_address';
import { Customer } from '../models/customer/customer';
import { PersonalReferent } from '../models/customer/personal_referent';
import { Guarantee } from '../models/customer/guarantee';
import { DataDigaloProps } from '../models/data_digalo_props';
import { GuaranteeImage } from '../models/customer/guarantee_image';
import { AddressImage } from '../models/customer/address_image';
import { CustomerImage } from '../models/customer/customer_image';




export const customerActions = {
    getAll,
    addImage,
    setPrincipalCustomerScreen,
    setOpenAddressScreen,
    searchByDecription,
    setOpenReferentScreen,
    setOpenGuaranteeScreen,
    addAddress,
    editAddress,
    addCustomer,
    editCustomer,
    addReferent,
    editReferent,
    addGuarantee,
    editGuarantee,
    addImageGurantee,
    addImageAddress,
    addImageCustomer

};


function getAll() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.getAll()
            .then(
                
                customers => dispatch(success(customers.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: customerConstants.GETALL_REQUEST } }
    function success(customers:any) { return { type: customerConstants.GETALL_SUCCESS, customers } }
    function failure(error:any) { return { type: customerConstants.GETALL_FAILURE, error } }
}
function searchByDecription(description:string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.searchByDecription(description)
            .then(
                
                customers => dispatch(success(customers.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: customerConstants.GETALL_REQUEST } }
    function success(customers:any) { return { type: customerConstants.GETALL_SUCCESS, customers } }
    function failure(error:any) { return { type: customerConstants.GETALL_FAILURE, error } }
}

function setPrincipalCustomerScreen(openPrincipal:DataDigaloProps<Customer>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: customerConstants.SET_OPEN_PRINCIPAL_SUCCESS, openPrincipal });
    };
}
function setOpenAddressScreen(openAddress: DataDigaloProps<CustomerAddress>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: customerConstants.SET_OPEN_ADDRESS_SUCCESS, openAddress });
    };
}
function setOpenReferentScreen(openReferent: DataDigaloProps<PersonalReferent>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: customerConstants.SET_OPEN_REFERENT_SUCCESS, openReferent });
    };
}
function setOpenGuaranteeScreen(openGuarantee: DataDigaloProps<Guarantee>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: customerConstants.SET_OPEN_GUARANTEE_SUCCESS, openGuarantee });
    };
}
function addAddress(address:CustomerAddress) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.addAddress(address)
            .then(
                
                customers => dispatch(success(customers.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: customerConstants.ADD_ADDRESS_REQUEST } }
    function success(customer:Customer) { return { type: customerConstants.ADD_ADDRESS_SUCCESS, customer } }
    function failure(error:any) { return { type: customerConstants.ADD_ADDRESS_FAILURE, error } }
}


function editAddress(address:CustomerAddress) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.editAddress(address)
            .then((customers )=>{
                    dispatch(success(customers.data))
                }
                    
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: customerConstants.EDIT_ADDRESS_REQUEST } }
    function success(customer:Customer) { return { type: customerConstants.EDIT_ADDRESS_SUCCESS, customer } }
    function failure(error:any) { return { type: customerConstants.EDIT_ADDRESS_FAILURE, error } }
}

function addCustomer(customer:Customer) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.addCustomer(customer)
            .then(
                
                customers => dispatch(success(customers.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: customerConstants.ADD_CUSTOMER_REQUEST } }
    function success(customer:Customer) { return { type: customerConstants.ADD_CUSTOMER_SUCCESS, customer } }
    function failure(error:any) { return { type: customerConstants.ADD_CUSTOMER_FAILURE, error } }
}

function editCustomer(customer:Customer) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.editCustomer(customer)
            .then(
           
                customers => dispatch(success(customers.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: customerConstants.EDIT_CUSTOMER_REQUEST } }
    function success(customer:Customer) { return { type: customerConstants.EDIT_CUSTOMER_SUCCESS, customer } }
    function failure(error:any) { return { type: customerConstants.EDIT_CUSTOMER_FAILURE, error } }
}



function addReferent(referent:PersonalReferent) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.addReferent(referent)
            .then(
                
                customers => dispatch(success(customers.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: customerConstants.ADD_REFERENT_REQUEST } }
    function success(customer:Customer) { return { type: customerConstants.ADD_REFERENT_SUCCESS, customer } }
    function failure(error:any) { return { type: customerConstants.ADD_REFERENT_FAILURE, error } }
}

function editReferent(referent:PersonalReferent) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.editReferent(referent)
            .then(
           
                customers => dispatch(success(customers.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: customerConstants.EDIT_REFERENT_REQUEST } }
    function success(customer:Customer) { return { type: customerConstants.EDIT_REFERENT_SUCCESS, customer } }
    function failure(error:any) { return { type: customerConstants.EDIT_REFERENT_FAILURE, error } }
}


function addImage(image:File) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.addImage(image)
            .then((customers) =>{
                dispatch(success(customers.data))
            
            }
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: customerConstants.UP_IMAGE_REQUEST } }
    function success(uri:Customer) { return { type: customerConstants.UP_IMAGE_SUCCESS, uri } }
    function failure(error:any) { return { type: customerConstants.UP_IMAGE_FAILURE, error } }
}

function addImageGurantee(guarantee: Guarantee, image:File) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.addImage(image)
            .then((customers) =>{
                var guaranteeImage = new GuaranteeImage();
                guaranteeImage.imageUri = customers.data;
                guaranteeImage.guaranteeID = 0;
                var guarantees = guarantee.images??[];
                guarantees.push(guaranteeImage);
                guarantee.images  = guarantees;
                
                dispatch(success(guarantee))
            
            }
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: customerConstants.UP_IMAGE_GUARANTEE_REQUEST } }
    function success(guarantee: Guarantee) { return { type: customerConstants.UP_IMAGE_GUARANTEE_SUCCESS, guarantee } }
    function failure(error:any) { return { type: customerConstants.UP_IMAGE_GUARANTEE_FAILURE, error } }
}


function addImageAddress(customerAddress: CustomerAddress, image:File) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.addImage(image)
            .then((customers) =>{
                var customerImage = new AddressImage();
                customerImage.imageUri = customers.data;
                customerImage.addressImageID = 0;
                var guarantees =  customerAddress.images??[];
                guarantees.push(customerImage);
                customerAddress.images  = guarantees;
                
                dispatch(success(customerAddress))
            
            }
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: customerConstants.UP_IMAGE_ADDRESS_REQUEST } }
    function success(customerAddress: CustomerAddress) { return { type: customerConstants.UP_IMAGE_ADDRESS_SUCCESS, customerAddress } }
    function failure(error:any) { return { type: customerConstants.UP_IMAGE_ADDRESS_FAILURE, error } }
}


function addImageCustomer(customer: Customer, image:File) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.addImage(image)
            .then((customers) =>{
                var customerImage = new CustomerImage();
                customerImage.imageUri = customers.data;
                customerImage.customerImageID = 0;
                var guarantees =  customer.images??[];
                guarantees.push(customerImage);
                customer.images  = guarantees;
                
                dispatch(success(customer))
            
            }
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: customerConstants.UP_IMAGE_CUSTOMER_REQUEST } }
    function success(customer: Customer) { return { type: customerConstants.UP_IMAGE_CUSTOMER_SUCCESS, customer } }
    function failure(error:any) { return { type: customerConstants.UP_IMAGE_CUSTOMER_FAILURE, error } }
}





function addGuarantee(guarantee:Guarantee) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.addGuarantee(guarantee)
            .then((customers) =>{
                dispatch(success(customers.data))
            
            }
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    }; 

    function request() { return { type: customerConstants.ADD_GUARANTEE_REQUEST } }
    function success(customer:Customer) { return { type: customerConstants.ADD_GUARANTEE_SUCCESS, customer } }
    function failure(error:any) { return { type: customerConstants.ADD_GUARANTEE_FAILURE, error } }
}






function editGuarantee(guarantee:Guarantee) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        customerService.editGuarantee(guarantee)
            .then(
           
                customers => dispatch(success(customers.data)),
                //error => dispatch(failure(error.toString()))
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: customerConstants.EDIT_GUARANTEE_REQUEST } }
    function success(customer:Customer) { return { type: customerConstants.EDIT_GUARANTEE_SUCCESS, customer } }
    function failure(error:any) { return { type: customerConstants.EDIT_GUARANTEE_FAILURE, error } }
}

