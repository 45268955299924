import { SpentDetail } from "./spent_detail";
import { SpentImage } from "./spent_image";

export class Spent {
    spentId?: number;
    description?: string;
    createDate?: Date;
    createBy?: number;
    user?: string;
    amount?: number;
    detail?: SpentDetail[];
    images?: SpentImage[];
    
    constructor(datos?: Spent) {
      if (datos != null) {
        this.spentId = datos.spentId;
        this.description = datos.description;
        this.createDate = datos.createDate;
        this.createBy = datos.createBy;
        this.user = datos.user;
        this.amount = datos.amount;
        this.detail = datos.detail;
        this.images = datos.images;
        return;
      }
      this.detail = [];
      this.images = [];
    }
  }
