export const reportConstants = {

    GET_PAYMENT_BY_DATE_REQUEST: 'GET_PAYMENT_BY_DATE_REQUEST',
    GET_PAYMENT_BY_DATE_SUCCESS: 'GET_PAYMENT_BY_DATE_SUCCESS',
    GET_PAYMENT_BY_DATE_FAILURE: 'GET_PAYMENT_BY_DATE_FAILURE',

    GET_FINANCIAL_STATE_BY_DATE_REQUEST: 'GET_FINANCIAL_STATE_BY_DATE_REQUEST',
    GET_FINANCIAL_STATE_BY_DATE_SUCCESS: 'GET_FINANCIAL_STATE_BY_DATE_SUCCESS',
    GET_FINANCIAL_STATE_BY_DATE_FAILURE: 'GET_FINANCIAL_STATE_BY_DATE_FAILURE',

    GET_PLACING_BY_DATE_REQUEST: 'GET_PLACING_BY_DATE_REQUEST',
    GET_PLACING_BY_DATE_SUCCESS: 'GET_PLACING_BY_DATE_SUCCESS',
    GET_PLACING_BY_DATE_FAILURE: 'GET_PLACING_BY_DATE_FAILURE',
    
    GET_RECOVER_BY_DATE_REQUEST: 'GET_RECOVER_BY_DATE_REQUEST',
    GET_RECOVER_BY_DATE_SUCCESS: 'GET_RECOVER_BY_DATE_SUCCESS',
    GET_RECOVER_BY_DATE_FAILURE: 'GET_RECOVER_BY_DATE_FAILURE',
    
   // SET_OPEN_SETTING_SUCCESS: 'SET_OPEN_SETTING_SUCCESS' , 
};