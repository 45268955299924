import MuiDialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, DialogContent,  Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataDigaloProps } from '../../../models/data_digalo_props';
import { inscriptionActions } from '../../../_actions/inscription.actions';
import { DayFree } from '../../../models/day_free';
import CircularProgress from '@mui/material/CircularProgress';


export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = ((props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle   {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close"  onClick={onClose}
            sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}

>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});



export interface SimpleDialogProps {
    onClose: (value: DayFree) => void;
  }
  
  function DialogDayFree(props: SimpleDialogProps) {
    const { onClose} = props;
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    
    const openDayFree = useSelector((state:any) => state.inscriptions.openDayFree as DataDigaloProps<DayFree> );
    const [submitted, setSubmitted] = React.useState(false);
    const  alert  = useSelector((state: any) => state.alert.message);
    
    const [inputs, setInputs] = React.useState<DayFree>(openDayFree?.select);
    useEffect(() => {
      setInputs(openDayFree?.select)
      setSubmitted(false)
    }, [openDayFree]);





    const handleClose = (dayFree?:DayFree) => {
      onClose(dayFree ?? new DayFree());//TODO 
      setSubmitted(false)
      dispatch(inscriptionActions.setOpenDayFreeScreen({
        open: false,
        select :dayFree ?? new DayFree(),
        type : 0,
        loading:false
    }));
    };
    
    function handleChangeInput(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  
  function handleSubmit(e:any) {
    e.preventDefault();
    setSubmitted(true);
    if (inputs.description && inputs.date) {
      console.log(inputs)
          dispatch(inscriptionActions.AddEditDayFree(inputs));
    }
  }

  
    return (
      <Dialog   id="customerDialog" fullWidth={true} maxWidth="sm" aria-labelledby="simple-dialog-title" open={openDayFree?.open ??false}>
        <DialogTitle onClose={()=>{handleClose(new DayFree())}}  id="simple-dialog-title" > Día Libre  </DialogTitle>
   
        <DialogContent dividers>    
        <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
              <Box  my={2}>
              <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            id="description"
                            label="Descripción"
                            type="text"
                            fullWidth
                            name="description"
                            error={(submitted && !inputs?.description)}   helperText={(submitted && !inputs?.description) ? "Es Requerido":""}
                            onChange={handleChangeInput}
                            placeholder="Descripción"
                            value={inputs?.description}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                              id="date"
                              label="Fecha"
                              type="date"
                              fullWidth
                              name="date"
                              disabled={true}
                              error={(submitted && !inputs?.date)}   helperText={(submitted && !inputs?.date) ? "Es Requerido":""}
                              onChange={handleChangeInput}
                              placeholder="Fecha"
                              value={new Date( inputs?.date?? Date() ).toISOString().substr(0,10)}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />                
                    </Grid>

                    <Grid item xs={12}>
                      {openDayFree?.loading && <CircularProgress size={24} />}           
                    </Grid>
                </Grid>                
                <Box my={3} >

                  <Button type="submit"  disabled={openDayFree?.loading} variant="contained" color="primary">
                  {openDayFree?.select?.dayFreeID ===0 ? "Guardar": "Editar"} Fecha 
                  </Button>

                  {/* <Button type="submit" disabled={openDayFree?.loading} variant="contained"  style={{background:"#f50057", color:"white"}}>
                    Eliminar Día 
                  </Button> */}

                </Box>
              </Box>
            </form>
        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { DialogDayFree };