import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../_actions/auth.actions';
import { Container , Button, TextField,  FormControl, Backdrop, CircularProgress,Box} from '@mui/material';
import logo from './logo.png'

function LoginPage() {
    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });

    const [submitted, setSubmitted] = useState(false);
    const { username, password } = inputs;
    const loggingIn = useSelector((state:any) => state.authentication.loggingIn);
    const dispatch = useDispatch();
    const location:any = useLocation();
    const  loading  = useSelector((state: any) => state.authentication.loading);
    const  alert  = useSelector((state: any) => state.alert.message);
    // reset login status
    useEffect(() => { 
        dispatch(authActions.logout()); 
    }, []);

    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e:any) {
        e.preventDefault();

        setSubmitted(true);
        if (username && password) {
            // get return url from location state or default to home page
            const { from } = location .state || { from: { pathname: "/" } };
            dispatch(authActions.login(username, password, from));
        }
    }
    const handleClose = () => {
       
      };
    return (
        <Container maxWidth="xs" >
            <Box my={4}>
                <img width={200} src={logo} alt="" />
            </Box>
            <form  onSubmit={handleSubmit}  noValidate autoComplete="off">

            <Box my={2}>
                <FormControl fullWidth={true} >
                        <TextField fullWidth={true} error={(submitted && !username)}   helperText={(submitted && !username) ? "Es Requerido":""}  
                        type="text" name="username"  value={username} onChange={handleChange} label="Usuario" />
                </FormControl>
            </Box>
            <Box my={2}>
                <FormControl fullWidth={true} >
                    <TextField fullWidth={true} error={(submitted && !password)}   helperText={(submitted && !password) ? "Es Requerido":""}  
                    type="password" name="password"  value={password} onChange={handleChange} label="Contraseña" />
                 </FormControl>
            </Box>

            <Box my={2}>
                {alert && <Box my={2} >{alert}</Box>}
            </Box>
                <div className="form-group">
                        <Button type="submit" variant="outlined" color="primary">
                        {loggingIn && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Ingresar
                        </Button>
                </div>
            </form>
            <Backdrop  open={loading ?? false} onClick={handleClose}>
                <CircularProgress color="inherit" title="Validando Usuario" />
            </Backdrop>
        </Container>
    );
}

export { LoginPage };