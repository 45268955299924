import Dialog from '@mui/material/Dialog';
import { Box, Button, Checkbox, CircularProgress, DialogContent, FormControl, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataDigaloProps } from '../../models/data_digalo_props';
import { Role } from '../user/models/role';
import { Permission } from '../user/models/permission';
import { Supervisor } from './models/supervisor';
import { DialogTitle } from '../../_components/DialogTitle';
import { hierarchyActions } from './actions/hierarchy.action';

  export interface DialogAddressProps {
    onClose: (value: string) => void;
  }
  
  function DialogEditSupervisor(props: DialogAddressProps) {
    const { onClose} = props;
    const dispatch = useDispatch();
    const  openSupervisor  = useSelector((state: any) => state.hierarchy.openSupervisor as DataDigaloProps<Supervisor>);
    const  roles  = useSelector((state: any) => state.users.roles as Role[]);
    const  permissions  = useSelector((state: any) => state.users.permissions as Permission[]);
    const [inputs, setInputs] = React.useState<Supervisor>(openSupervisor?.select);
    const [submitted, setSubmitted] = React.useState(false);
    
    useEffect(() => {
        setInputs(openSupervisor?.select)
        setSubmitted(false)
    }, [openSupervisor]);



    
    const handleClose = () => {
      setInputs(new Supervisor())
      dispatch(hierarchyActions.setOpenModalSupervisorScreen({
        open : false,
        select : new Supervisor(),
        type : 0,
        loading: false
      }));
      setSubmitted(false)
    };
  

    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if(inputs.name){
        if(openSupervisor?.type ===0 ){
          dispatch(hierarchyActions.addSupervisor(inputs));
        }
        if(openSupervisor?.type ===1){
          dispatch(hierarchyActions.editSupervisor(inputs));
        }
      }
    }
  
  function handleChangeCheck(e:any)  {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: e.target.checked  }));
    };
 
  function handleChange(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
  }

    return (
      <Dialog  id="inventoryDialog"  fullWidth={true} maxWidth="xs" aria-labelledby="simple-dialog-title" open={openSupervisor?.open??false}>
        <DialogTitle onClose={handleClose}  id="simple-dialog-title" > {openSupervisor?.type ===0 ? "Agregar": "Editar"} Supervisor </DialogTitle>
        <DialogContent dividers>
  

        {
          openSupervisor?.loading ?  <CircularProgress />:
   
        <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
        <Grid container spacing={3}>
        {/* <Grid item xs={3}>
              <TextField
                    id="supervisorID"
                    label="Código de Supervisor"
                    type="text"
                    placeholder="Código de Supervisor"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    name="supervisorID"
                    disabled={true}
                    value={inputs?.supervisorID}
                    error={(submitted && !inputs?.supervisorID)}   helperText={(submitted && !inputs?.supervisorID) ? "Es Requerido":""}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>  */}
        <Grid item xs={9}>
              <TextField
                    id="name"
                    label="Nombre"
                    type="text"
                    placeholder="Nombre"
                    fullWidth
                    size="small"    
                    onChange={handleChange}
                    name="name"
                    value={inputs?.name}
                    error={(submitted && !inputs?.name)}   helperText={(submitted && !inputs?.name) ? "Es Requerido":""}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid> 
       
            {
              openSupervisor.type ==1?
                <Grid item xs={12}>    
                      <FormControl required component="fieldset">
                        <FormControlLabel
                          control={
                            <Checkbox color="primary" name="status" checked={inputs?.status } onChange={handleChangeCheck}  />
                          }
                          label="Activo"
                        />
                        {/* <FormHelperText>You can display an error</FormHelperText> */}
                      </FormControl>
                  </Grid>
                :<Box></Box>
            }
            </Grid>
                  <Box my={3} >
                    <Button type="submit" variant="contained" color="primary">
                    {openSupervisor?.type ===0 ? "Agregar": "Editar"} Supervisor 
                    </Button>
                  </Box>
                  </form>        
              }
         
        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { DialogEditSupervisor };
