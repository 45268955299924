import { useDispatch, useSelector } from 'react-redux';
import { Container , CircularProgress, Box, TextField, Grid, Button, InputAdornment, Typography, Card} from '@mui/material';
import React from 'react';
import * as Excel from "exceljs";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { FilterParams } from './models/filter_params';
import { reportActions } from './actions/report.action';
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { format, toEsDate } from '../../_helpers/format';
import { Payment } from '../../models/payment/payment';

function RecoverByDatePage() {
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.report.loading);
  const reportRecoverPay = useSelector((state: any) => state.report.reportRecoverPay );
  const alert = useSelector((state: any) => state.alert.message);
  const [submitted, setSubmitted] = React.useState(false);
  // const advisers = useSelector((state:any)=> state.inscriptions.advisers as Adviser[])
  const [inputs, setInputs] = React.useState<FilterParams>(new FilterParams());

    // useEffect(() => {
    //   setInputs(setting)
    //   setSubmitted(false)
    // }, [setting]);

    // useEffect(() => {
    //   dispatch(settingActions.getAll());
    //   setSubmitted(false)
    // }, []);
    
    // useEffect(() => {
    //   if(advisers.length == 0){
    //     dispatch(inscriptionActions.getAdviser());
    //   }
    // }, [advisers]);

    function handleChange(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if(inputs?.fromDate && inputs.toDate ){
        dispatch(reportActions.getRecoverByDate(inputs));
      }
    }

    // const handleChangeMultipleAdviser = (event: any) => {
    //   var adviserID = event.target.value as number;
    //   var index =inputs?.adviserIds?.findIndex(x=>{
    //     return x.adviserID == adviserID
    //   });
    //   var currentSelect = advisers.filter(x=> x.adviserID == adviserID)[0];

    //   if((index!)>-1){
    //     inputs.adviserIds! = inputs.adviserIds!.filter((item)=> item.adviserID!=adviserID );
    //   }
    //   else{
    //     inputs.adviserIds!.push(currentSelect);
    //   }
    //   setInputs(inputs => ({ ...inputs, 'adviserIds': inputs.adviserIds }));
    // };  


    const columns: GridColDef[] = [
       {
         field: 'createDate',
         headerName: 'Fecha',
         width: 120,
         valueFormatter: (params) => {
            if(params?.value){
              var da = params?.value as string;
              return toEsDate(da);
            } 
            else{
              return "X";
            }
         },
       },
      {
        field: 'pay',
        headerName: 'Total',
        width: 170,
        valueFormatter: (params) => {
          if(params?.value != undefined){
            return "L. "+ format((params?.value as number)??0, 2);
          } 
          else{
            return "-1";
          }
       },
      },
      {
        field: 'noPay',
        headerName: 'No Pagado',
        width: 170,
        valueFormatter: (params) => {
          if(params?.value!= undefined){
            return "L. "+ format((params?.value as number)??0, 2);
          } 
          else{
            return "-1";
          }
       },
      },
      {
        field: 'payAdvanced',
        headerName: 'Adelantado',
        width: 170,
        valueFormatter: (params) => {
          if(params?.value!= undefined){
            return "L. "+ format((params?.value as number)??0, 2);
          } 
          else{
            return "-1";
          }
       },
      },
     ];
     

     function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <Button style={{color:"green"}} onClick={downloadExcel}   startIcon={<ImportExportIcon />} >Descargar Excel</Button>
        </GridToolbarContainer>
      );
    }


    async function downloadExcel(e:any) {
      e.preventDefault();
      const workbook = new Excel.Workbook();
      workbook.creator = 'SISTEMA RAPICREDIT';
      workbook.created = new Date();
      workbook.modified = new Date();
      workbook.lastPrinted = new Date();
      const worksheet = workbook.addWorksheet('Recuperado', {properties:{tabColor:{argb:'FFC0000'}}});
      worksheet.autoFilter = 'A1:N1';
      worksheet.columns = [
        { header: 'Fecha', key: 'createDate', width: 30,},
        { header: 'Total', key: 'pay', width: 30,},
        { header: 'No Pagado', key: 'noPay', width: 30,},
        { header: 'Adelantado', key: 'payAdvanced', width: 30,},
      ];
        
        reportRecoverPay.forEach((item: Payment )=> {
          worksheet.addRow(item);
        });
       const buffer = await workbook.xlsx.writeBuffer();
       var blob = new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = "RecuperadoPorFecha-" + new Date().toISOString().substr(0,10);
        link.download = fileName;
        link.click();
    }

   
    return (
        <Box my={2}>
            <Container maxWidth="xl" >
               <Card  variant="outlined" style={{padding:"5px 20px 25px 20px"}} >

              {alert && <Box my={2} >{alert}</Box>}
              {
                loading? 
                <Box my={2}>
                    <CircularProgress title="Cargando..." />
                </Box>
                :
                <Container maxWidth="xl">
                    
                    <Box my={3}>
                      <Typography variant="h5" gutterBottom component="div">
                        Recuperado por día
                      </Typography>
                    </Box>
                    <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <TextField
                                  id="fromDate"
                                  label="Desde"
                                  type="date"
                                  placeholder="Desde"
                                  fullWidth
                                  size="small"
                                  onChange={handleChange}
                                  name="fromDate"
                                  value={inputs?.fromDate}
                                  error={(submitted && !inputs?.fromDate)}   helperText={(submitted && !inputs?.fromDate) ? "Es Requerido":""}
                                  margin="normal"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <DateRangeIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                          </Grid> 
                          <Grid item xs={3}>
                                <TextField
                                      id="toDate"
                                      label="Hasta"
                                      type="date"
                                      placeholder="Hasta"
                                      fullWidth
                                      size="small"    
                                      onChange={handleChange}
                                      name="toDate"
                                      value={inputs?.toDate}
                                      error={(submitted && !inputs?.toDate)}   helperText={(submitted && !inputs?.toDate) ? "Es Requerido":""}
                                      margin="normal"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <DateRangeIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                            </Grid> 
                            {/* <Grid item xs={3}>
                              <Box my={2}>
                    
              
                                <FormControl fullWidth>
                                  <InputLabel shrink >
                                      Asesores
                                  </InputLabel>
                                      <Select
                                        value={inputs?.adviserIds}
                                        onChange={handleChangeMultipleAdviser}
                                        displayEmpty
                                        label="Asesores"
                                      size="small"
                                        placeholder="Seleccione un Asesor"
                                        name="adviserIds"
                                        error={(submitted && (inputs?.adviserIds??[]).length ==0 )}   
                                        renderValue={(selected) => ((selected ??[]) as Adviser[]).map((item)=>{
                                          return (item?.name??"")?.length > 6?item.name?.substr(0,6) : item.name
                                        }).join(', ')}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                      >
                                        {
                                          advisers.map((item: Adviser)=>{
                                            return <MenuItem dense={true} value={item.adviserID}>
                                              <Checkbox color="primary" checked={(inputs?.adviserIds??[]).map((ware: Adviser)=> ware!.adviserID).indexOf(item!.adviserID) > -1} />
                                                <ListItemText primary={item.name } />
                                              </MenuItem>
                                          })
                                        }
                                      </Select>
                                      {
                                        (submitted && !inputs?.adviserIds) ? <FormHelperText error >Es requerido</FormHelperText>:""
                                      }
                                    </FormControl>
                                </Box>
                            </Grid>  */}

                            <Grid item xs={3}>
                                <Box my={2} >
                                  <Button type="submit" variant="contained" color="primary">
                                      Buscar 
                                  </Button>
                                </Box>
                            </Grid> 
                          
                          </Grid>
                       
                    </form>   

                    <Grid container spacing={3}>
                            <Grid item xs={12} >
                        
                              <DataGrid autoHeight 
                                  rows={reportRecoverPay}
                                  getRowId={(r) => r.id}
                                  columns={columns}
                                  components={{
                                    Toolbar:CustomToolbar
                                  }}      
                                  pageSize={100}
                                  rowsPerPageOptions={[5]}
                                  nonce="No se encontraron resultados"
                                  density="compact"
                                  checkboxSelection={false}
                                  // onCellClick={(params, event) => {
                                  //     console.log(params.row)
                                  //     handleClickOpen(params.row as User, 1)
                                  //   }}
                                  
                                  disableSelectionOnClick
                              />
                            </Grid>
                          </Grid>


                </Container>
              }
            </Card>
            </Container>
        </Box>
    );
}

export { RecoverByDatePage };
