export const userConstants = {

    GETALL_REQUEST: 'GETALL_REQUEST',
    GETALL_SUCCESS: 'GETALL_SUCCESS',
    GETALL_FAILURE: 'GETALL_FAILURE',


    GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',

    GET_FROM_HIERARCHY_REQUEST: 'GET_FROM_HIERARCHY_REQUEST',
    GET_FROM_HIERARCHY_SUCCESS: 'GET_FROM_HIERARCHY_SUCCESS',
    GET_FROM_HIERARCHY_FAILURE: 'GET_FROM_HIERARCHY_FAILURE',

    GET_PERMISSION_REQUEST: 'GET_PERMISSION_REQUEST',
    GET_PERMISSION_SUCCESS: 'GET_PERMISSION_SUCCESS',
    GET_PERMISSION_FAILURE: 'GET_PERMISSION_FAILURE',

    ADD_USER_REQUEST: 'ADD_USER_REQUEST',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILURE: 'ADD_USER_FAILURE',

    EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
    EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
    EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',

    SET_OPEN_USER_SUCCESS: 'SET_OPEN_USER_SUCCESS' , 
    SET_OPEN_SELECT_HIERARCHY_SUCCESS: 'SET_OPEN_SELECT_HIERARCHY_SUCCESS' , 

};