import MuiDialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Container, DialogContent,  FormControl,  FormHelperText,  Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select,  TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { format } from '../../../_helpers/format';
import { DataDigaloProps } from '../../../models/data_digalo_props';
import { inscriptionActions } from '../../../_actions/inscription.actions';
import { Lending } from '../../../models/lending/lending';
import { Adviser } from '../../hierarchy/models/adviser';
import { ImagePicker } from '../../../_components/ImagePicker';
import { bytesToSize } from '../../../_helpers/common';
import { alertActions } from '../../../_actions/alert.actions';



export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = ((props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle   {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close"  onClick={onClose}
            sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}

>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});




export interface SimpleDialogProps {
    onClose: (value: Lending) => void;
  }
  
  function DialogRequest(props: SimpleDialogProps) {
    const { onClose} = props;
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();

    const  loading  = useSelector((state: any) => state.customers.loading);
    const openRequest = useSelector((state:any) => state.inscriptions.openRequest as DataDigaloProps<Lending> );
    const [submitted, setSubmitted] = React.useState(false);
    const  alert  = useSelector((state: any) => state.alert.message);
    const user = useSelector((state:any) => state.authentication.user);
    const advisers = useSelector((state:any)=> state.inscriptions.advisers as Adviser[])
    const [inputs, setInputs] = React.useState<Lending>(openRequest?.select);
    useEffect(() => {
      setInputs(openRequest?.select)
      setSubmitted(false)
    }, [openRequest]);


    useEffect(() => {
      setInputs(new Lending(openRequest.select));
      setSubmitted(false);
  }, [openRequest.select]);

    
    useEffect(() => {
      if(advisers.length == 0){
        dispatch(inscriptionActions.getAdviser());
      }
    }, [advisers]);

  
    const columnsAddress: GridColDef[] = [
      {
        field: 'customerID',
        headerName: 'Cliente ID',
        width: 150,
      },      
      {
        field: 'dni',
        headerName: 'Identidad',
        width: 150,
      },
      {
        field: 'name',
        headerName: 'Nombre',
        width: 240
      },
      {
        field: 'phone',
        headerName: 'Teléfono',
        width: 140,
      },
 
    ];


    const handleClose = (lending?:Lending) => {
      //onClose(lending ?? new Lending());//TODO 
      setSubmitted(false)
      dispatch(inscriptionActions.setOpenLendingScreen({
        open: false,
        select :lending ?? new Lending(),
        type : 0,
        loading:false
    }));
    };


    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.term && inputs.amount && openRequest.select.customerID && inputs.durationID && inputs.adviserID) {
          dispatch(inscriptionActions.calculateFeeRequest(inputs));
      }
    }

    function handleEdit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.term && inputs.amount && openRequest.select.customerID && inputs.durationID && (inputs.fees?? []).length > 0 && inputs.adviserID) {
        inputs.postingBy = user.userID;
          // get return url from location state or default to home page
          // const { from } = location .state || { from: { pathname: "/" } };
          dispatch(inscriptionActions.edit(inputs));
      }
    }

    function handlePosting(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.term && inputs.amount && openRequest.select.customerID && inputs.durationID && (inputs.fees?? []).length > 0 && inputs.adviserID) {
        inputs.postingBy = user.userID;
          // get return url from location state or default to home page
          // const { from } = location .state || { from: { pathname: "/" } };
          dispatch(inscriptionActions.posting(inputs));
      }
    }

    function handleChange(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  const columns: GridColDef[] = [
    { field: 'lineNumber', headerName: '# Cuota', width: 130 },
    {
      field: 'amount',
      headerName: 'Cuota',
      width: 200,
      valueFormatter: (params) => {
        if(params?.value){
          var data: number = parseFloat(params?.value as string);
          return "L. "+ format( data,2)
        } 
     },

    },
    {
      field: 'expireDate',
      headerName: 'Fecha',
      width: 160,
      type:'date',
      valueFormatter: (params) => {
        if(params?.value){
          return new Date(params?.value as string).toLocaleDateString().substr(0,10)
        } 
     },

    },
    {
      field: 'day',
      headerName: 'Día',
      width: 140,
    },
  ];


  const handleImageAdd = (image:any) => {
    console.log(image);
    let uploadedImg = image;
    const ACCEPTED_UPLOAD_SIZE = 1024; // 1MB
    if ( bytesToSize(uploadedImg.size) > ACCEPTED_UPLOAD_SIZE
    ) {
      dispatch(alertActions.error("La imagen es demasiado grande"));
      return;
    }
    dispatch(inscriptionActions.addImageLendingOnRequest( inputs,uploadedImg));
    
  };

  
    return (
      <Dialog   id="customerDialog" fullWidth={true} maxWidth="md" aria-labelledby="simple-dialog-title" open={openRequest?.open ??false}>
        <DialogTitle onClose={()=>{handleClose(new Lending())}}  id="simple-dialog-title" > Solicitud: {openRequest.select.lendingID} </DialogTitle>
   
        <DialogContent dividers>    

        <Box my={2}>
            <Container >
                <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >

                    <Box my={2} mx={2} >
                        <Button variant="contained" color="secondary" onClick={(e)=>{
                            handleSubmit(e);
                            }
                        }>Calcular Cuota </Button>
                    </Box>

                    <Box my={2} mx={2} >
                        <Button disabled={(inputs.fees??[]).length==0} variant="contained" color="primary" onClick={(e)=>{
                            handleEdit(e);
                            }
                        }>Actualizar Solicitud </Button>
                    </Box>

                    <Box my={2} >
                        <Button disabled={(inputs.fees??[]).length==0} variant="contained" style={{background: "#f50057", color:"white"}} onClick={(e)=>{
                            handlePosting(e);
                            }
                        }>Autorizar Solicitud </Button>
                    </Box>

                </Grid>
                {/* <Typography variant="h6" component="h2">
                    Solicitud Préstamo
                </Typography> */}
            <Grid container spacing={3}>

                    <Grid item xs={6}>
                     
                            <FormControl fullWidth  >
                                <InputLabel shrink > Cliente </InputLabel>
                                <Input
                                    id="selectCustomer"
                                    value={openRequest.select?.customer || ''}
                                    placeholder={"Seleccione un cliente"}
                                    disabled={true}
                                    size="small"
                                    error={(submitted && !openRequest.select?.customerID)}   
                                    endAdornment={<InputAdornment position="end">
                                    </InputAdornment>}
                                />
                                 {
                                (submitted &&  !openRequest.select?.customerID) ? <FormHelperText error >Es requerido</FormHelperText>:""
                                }

                            </FormControl>
                            
                    </Grid>
                    <Grid item xs={3}> 
                        <TextField
                                    id="interest"
                                    label="Interes"
                                    type="text"
                                    placeholder="Interes"
                                    fullWidth
                                    size="small"
                                    name="interest"
                                    value={inputs?.interest}
                                    //error={(submitted && !inputs?.interest)}   helperText={(submitted && !inputs?.interest) ? "Es Requerido":""}
                                    onChange={handleChange}
                        
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>
                    <Grid item xs={3}> 
                        <FormControl fullWidth>
                        <InputLabel shrink  id="durationID" >
                        Plazo (en tiempo)
                        </InputLabel>
                            <Select
                                value={inputs?.durationID || 1}
                                onChange={handleChange}
                                displayEmpty
                                labelId="durationID" 
                                label="durationID"
                                size="small"
                                placeholder="Plazo (en tiempo)"
                                name="durationID"
                                error={(submitted && !inputs?.durationID)}   
                                // helperText=
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={1}>Días</MenuItem>
                                {/* <MenuItem value={2}>Meses</MenuItem>
                                <MenuItem value={3}>Años</MenuItem> */}
                        
                            </Select>
                            {
                                (submitted && !inputs?.adviserID) ? <FormHelperText error >Es requerido</FormHelperText>:""
                            }
                            
                            </FormControl>

                    </Grid>

                    <Grid item xs={4}> 
                                <TextField
                                    id="term"
                                    label="Plazo en numero"
                                    type="text"
                                    size="small"
                                    placeholder="Plazo en numero"
                                    fullWidth
                                    name="term"
                                    value={inputs?.term  || ''}
                                    error={(submitted && !inputs?.term)}   helperText={(submitted && !inputs?.term) ? "Es Requerido":""}
                                    onChange={handleChange}
                              
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>


                    <Grid item xs={4}> 
                        <TextField
                                    id="amount"
                                    label="Monto"
                                    type="text"
                                    placeholder="Monto"
                                    fullWidth
                                    size="small"
                                    name="amount"
                                    value={inputs?.amount || ''}
                                    error={(submitted && !inputs?.amount)}   helperText={(submitted && !inputs?.amount) ? "Es Requerido":""}
                                    onChange={handleChange}
                        
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>


                    <Grid item xs={4}> 
                        <FormControl >
                        <InputLabel shrink  id="adviserID" >
                            Asesor
                        </InputLabel>
                            <Select
                                value={inputs?.adviserID || ''}
                                onChange={handleChange}
                                displayEmpty
                                labelId="label" 
                                label="adviserID"
                                size="small"
                                placeholder="Seleccione un Asesor"
                                name="adviserID"
                                error={(submitted && !inputs?.adviserID)}   
                                // helperText=
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {
                                advisers.map((item: Adviser)=>{
                                    return <MenuItem value={item.adviserID}> {item.adviserID} - {item.name}</MenuItem>
                                })
                                }
                            </Select>
                            {
                                (submitted && !inputs?.adviserID) ? <FormHelperText error >Es requerido</FormHelperText>:""
                            }
                            
                            </FormControl>

                    </Grid>

                    
                </Grid>
                <Grid item xs={12} mt={2}>
                    <ImagePicker onImageAdd={handleImageAdd} images={openRequest.select.images??[]} loading={openRequest.loading}/>
                </Grid>

                <Box  my={4}>
                    <Typography variant="h6" gutterBottom component="div">
                       Cuotas
                    </Typography>
                </Box>
                <DataGrid autoHeight 
                    rows={inputs?.fees??[]}
                    loading= {openRequest.loading}
                    getRowId={(r) => r.lineNumber}
                    columns={columns}
                    pageSize={7}
                    rowsPerPageOptions={[5]}
                    nonce="No se encontraron resultados"
                    density="compact"
                    
                    checkboxSelection={false}
                    // onCellClick={(params, event) => {
                    //     console.log(params.row)
                    //     handleClickOpen(params.row as Customer)
                    //   }}
                      
                    disableSelectionOnClick
                />


            </Container>
        </Box>

        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { DialogRequest };