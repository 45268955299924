import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CircularProgress, Container } from '@mui/material';
import { DialogDayFree } from './lending/components/dialog.day.free';
import { inscriptionActions } from '../_actions/inscription.actions';
import { DayFree } from '../models/day_free';
var Calendar = require('rc-year-calendar');


    Calendar.locales['es'] = {
        days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        daysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
        daysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        weekShort: 'S',
        weekStart: 1
    };

    const handleCustomerClose = (dayFree: DayFree) => {
        console.log(dayFree);
    };

    function DaysFreePage() {
        const dayFrees = useSelector((state:any) => state.inscriptions.dayFrees);
        const user = useSelector((state:any) => state.authentication.user );
        const dispatch = useDispatch();
        const  loading  = useSelector((state: any) => state.inscriptions.loading);
        useEffect(() => {
            if(dayFrees.length == 0){
                dispatch(inscriptionActions.getAll());
            }
    }, [dayFrees]);


    return (
        <Box my={2}>
            <Container  maxWidth="xl">
        <Card  variant="outlined" style={{padding:"5px 20px 25px 20px"}} >

            {loading ?  <CircularProgress size={24} /> :
            <Calendar year={new Date().getFullYear()}  style="background"  language="es" dataSource={
                
                (dayFrees??[]).map((item:DayFree)=>{
                var fecha = item.date?.toString() ?? new Date();
                var newItem =  {
                    id :item.dayFreeID,
                    name: item.description,
                    startDate: new Date(fecha),
                    endDate: new Date(fecha),
                    color:  "#37dbc3"
                  };
                return newItem;
            })
        
        } onDayClick={(fecha: any, event: any) => {
                        var dayFree = new DayFree({ 
                            dayFreeID: 0,
                            date: fecha.date,
                            description: "",
                            userID: user.userID
                        });
                        if((fecha.events??[]).length >0)
                        {
                            var current = fecha.events[0];
                            console.log(current.name)
                            dayFree.description = current.name;
                            dayFree.dayFreeID = current.id;

                        }
                        dispatch(inscriptionActions.setOpenDayFreeScreen({
                            open: true,
                            select :dayFree,
                            type : 0,
                            loading:false
                        }));
                      }}/>
        }
            <DialogDayFree onClose={handleCustomerClose} ></DialogDayFree>
        </Card>
        </Container>
        </Box>
    );
}

export { DaysFreePage };