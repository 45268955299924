import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, Container,  Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { inscriptionActions } from '../../_actions/inscription.actions';
import { Lending } from '../../models/lending/lending';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format } from '../../_helpers/format';
import { NavLink } from 'react-router-dom';
import { DialogRequest } from './components/dialog.request';
import { filter } from '../../_helpers/filter';
import SearchIcon from '@mui/icons-material/Search';
import { DebounceInput } from 'react-debounce-input';

function ListRequestLendingPage() {
    const pendientsLendings = useSelector((state:any)=> state.inscriptions.pendientsLendings)
    const loadingAdviser = useSelector((state:any)=> state.inscriptions.loadingAdviser)
    const loading = useSelector((state:any)=> state.inscriptions.loading)
    const user = useSelector((state:any) => state.authentication.user);
    const dispatch = useDispatch();
    const [pendientsLendingsFilters, setPendientsLendingsFilters] = useState<Lending[]>(pendientsLendings);

    useEffect(() => {
        //if(pendientsLendings.length == 0){
        dispatch(inscriptionActions.getPendientLending());
        //}
      }, []);


    useEffect(() => { 
       setPendientsLendingsFilters(pendientsLendings)
    },[pendientsLendings]);

      
      const handleCustomerClose = (lending: Lending) => {
        console.log(lending);
       
     };
     const handleClickOpen = (row: Lending, type: number) => {
        dispatch(inscriptionActions.setOpenLendingScreen({
            open: true,
            select :row ?? new Lending(),
            type : type,
            loading:false
        }));
      };
    
      const columns: GridColDef[] = [
        { field: 'lendingID', headerName: '# Solicitud', width: 150 },
        {
            field: 'customerID',
            headerName:'Cliente ID',
            width:140

        },
        {
            field: 'customer',
            headerName:'Cliente',
            width:300
        },
        {
            field: 'duration',
            headerName:'Plazo',
            width:120
        },
        {
            field: 'createDate',
            headerName: 'Fecha',
            width: 160,
            type:'date',
            valueFormatter: (params) => {
              if(params?.value){
                return new Date(params?.value as string).toLocaleDateString().substr(0,10)
              } 
           },
        },
        {
          field: 'amount',
          headerName: 'Monto',
          width: 200,
          valueFormatter: (params) => {
            if(params?.value){
              var data: number = parseFloat(params?.value as string);
              return "L. "+ format( data,2)
            } 
         },
        },    
      ];
  
    return (
        <Box my={2}>
            <Container maxWidth="xl" >
                 <Card  variant="outlined" style={{padding:"5px 20px 25px 20px"}} >

                <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
                    
            
                    <Box my={2} >
                        <Button  variant="contained"  color="primary" component={NavLink}  to={"/solicitud"} >Nueva Solicitud </Button>
                    </Box>

                </Grid>
                {/* <Typography variant="h6" component="h2">
                    Solicitud Préstamo
                </Typography> */}
          
                <Box>
                    <Typography variant="h6" gutterBottom component="div">
                        Solicitudes Pendientes
                    </Typography>
                </Box>
                  <Box my={1}>
                    <DebounceInput  style={{width:"100%"}} id="standard-basic" label="Buscador" 
                    variant="filled" size="small" debounceTimeout={400} element={TextField as any} 
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={event => {
                          let textSearch = (event.target?.value??"");
                          if(textSearch.length > 1){
                            let newPendientsLendings = filter(pendientsLendings, textSearch)
                            setPendientsLendingsFilters(newPendientsLendings);
                          }
                          else{
                            setPendientsLendingsFilters(pendientsLendings)
                          }
                      }} 

                    />
                </Box>
                <DataGrid autoHeight 
                    rows={pendientsLendingsFilters??[]}
                    loading= {loading || loadingAdviser}
                    getRowId={(r) => r.lendingID}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    nonce="No se encontraron resultados"
                    density="compact"
                    
                    checkboxSelection={false}
                    onCellClick={(params, event) => {
                        console.log(params.row)
                        handleClickOpen(params.row as Lending, 1)
                      }}
                      
                    disableSelectionOnClick
                />

            </Card>
            </Container>
            <DialogRequest onClose={handleCustomerClose} ></DialogRequest>
        </Box>
    );
}

export { ListRequestLendingPage };