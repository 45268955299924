import MuiDialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Card, CircularProgress, DialogContent,  Grid, IconButton, InputAdornment, Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Customer } from '../../../models/customer/customer';
import { DialogAddress } from './dialog.address';
import { CustomerAddress } from '../../../models/customer/customer_address';
import { customerActions } from '../../../_actions/customer.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Guarantee } from '../../../models/customer/guarantee';
import { DialogReferent } from './dialog.referent';
import { PersonalReferent } from '../../../models/customer/personal_referent';
import { format } from '../../../_helpers/format';
// import { TextMaskCustom, TextMaskPhone } from '../../../_helpers/common';
import { DialogGuarantee } from './dialog.guarantee';
import environment from '../../../environment';
import { DataDigaloProps } from '../../../models/data_digalo_props';
import { ImagePicker } from '../../../_components/ImagePicker';
import { bytesToSize } from '../../../_helpers/common';
import { alertActions } from '../../../_actions/alert.actions';



interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = ((props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle   {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close"  onClick={onClose}
            sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}

>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}


export interface SimpleDialogProps {
    onClose: (value: string) => void;
  }
  
  function SimpleDialog(props: SimpleDialogProps) {
    const { onClose} = props;
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    

    const openPrincipal = useSelector((state:any) => state.customers.openPrincipal as DataDigaloProps<Customer> );
    const [submitted, setSubmitted] = React.useState(false);
    const  alert  = useSelector((state: any) => state.alert.message);
    
    const [inputs, setInputs] = React.useState<Customer>(openPrincipal?.select);
    useEffect(() => {
      setInputs(openPrincipal?.select)
      setSubmitted(false)
    }, [openPrincipal]);

    useEffect(() => {
    }, [openPrincipal]);


    const handleImageAdd = (image:any) => {
      console.log(image);
      let uploadedImg = image;
      const ACCEPTED_UPLOAD_SIZE = 1024; // 1MB
      if ( bytesToSize(uploadedImg.size) > ACCEPTED_UPLOAD_SIZE
      ) {
        dispatch(alertActions.error("La imagen es demasiado grande"));
        return;
      }
      dispatch(customerActions.addImageCustomer( inputs,uploadedImg));
      
    };
  



    const columnsReferent: GridColDef[] = [
      {
        field: 'name',
        headerName: 'Nombre',
        width: 200
      },
      {
        field: 'address',
        headerName: 'Dirección',
        width: 250,
      },
      // {
      //   field: 'dni',
      //   headerName: 'Identidad',
      //   width: 130,
      // },
      {
        field: 'phone',
        headerName: 'Teléfono',
        width: 140,
      },

      {
        field: 'relation',
        headerName: 'Relación',
        width: 130,
      },
    ];

    const handleClickOpen = (row: CustomerAddress, type: number) => {
      dispatch(customerActions.setOpenAddressScreen({
        open : true,
        select : row,
        type : type,
        loading: false
      }));
    };

    const handleClickGuarantee = (row: Guarantee, type: number) => {
      dispatch(customerActions.setOpenGuaranteeScreen({
        open : true,
        select : row,
        type : type,
        loading: false,
      }));
    };

    
    const handleClickOpenReferent = (row: PersonalReferent, type: number) => {
      dispatch(customerActions.setOpenReferentScreen({
        open : true,
        select : row,
        type: type,
        loading: false
      }));
    };
    
    function handleChangeInput(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  // const handleDateChange = (date: Date | null) => {
  //   setInputs(inputs => ({ ...inputs, birthday: (date ?? new Date()) }));
  // };
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
    const columnsAddress: GridColDef[] = [
      
      {
        field: 'name',
        headerName: 'Nombre',
        width: 130
      },
      {
        field: 'address',
        headerName: 'Dirección',
        width: 265,
      },

      {
        field: 'city',
        headerName: 'Ciudad',
        width: 140,
      },
      {
        field: 'contact',
        headerName: 'Contacto',
        width: 140,
      },
      {
        field: 'phone',
        headerName: 'Teléfono',
        width: 120,
      },
    ];


    const handleClose = () => {
      onClose("Hola");
      setSubmitted(false)
    };

    
    const handleCloseAddress = (value: string) => {
     // dispatch(customerActions.setPrincipalCustomerScreen(false));
    };
    const handleCloseReferent = (value: string) => {
     // dispatch(customerActions.setPrincipalCustomerScreen(false));
    };
    const handleCloseGuarantee = (value: string) => {
     // dispatch(customerActions.setPrincipalCustomerScreen(false));
    };
  

    // const handleListItemClick = (value: string) => {
    //   onClose(value);
    // };
    // const handleChangeActive = (e:any,value: boolean) => {
    //   //TODO
    // };

    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.name && inputs.dni && inputs.birthday && inputs.income && inputs.canPay && inputs.phone) {
        console.log(inputs)
          // get return url from location state or default to home page
          // const { from } = location .state || { from: { pathname: "/" } };
          if(openPrincipal?.type ===0){
            dispatch(customerActions.addCustomer(inputs));
          }
          if(openPrincipal?.type ===1){
            inputs.customerID = openPrincipal?.select?.customerID;
            dispatch(customerActions.editCustomer(inputs));
          }
      }
    }
  
    return (
      <Dialog   id="customerDialog" fullWidth={true} maxWidth="md" aria-labelledby="simple-dialog-title" open={openPrincipal?.open ??false}>
        <DialogTitle onClose={handleClose}  id="simple-dialog-title" >  {openPrincipal?.type ===0 ? "Agregar": ""} {openPrincipal?.select?.customerID}  {openPrincipal?.select?.name}   </DialogTitle>
        <Paper square>
          <Tabs value={value} indicatorColor="primary" onChange={handleChange} aria-label="disabled tabs example" >
            <Tab label="Datos Generales" />
            <Tab label="Direcciones"  />
            <Tab label="Garantias"  />
            <Tab label="Referencias"  />
          </Tabs>
        </Paper>

        <DialogContent dividers>
       
        <TabPanel value={value} index={0}>
        {alert && <Box my={2} >{alert}</Box>}
          { openPrincipal?.loading ?  <CircularProgress />:
        <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
          <Grid container spacing={3}>
              
              <Grid item xs={4}>
                <TextField
                      id="customerID"
                      label="Cliente"
                      type="text"
                      fullWidth
                      size="small"
                      value={inputs?.customerID}
                      margin="normal"
                      name="customerID"
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
              </Grid>
              <Grid item xs={4}>    
                    <TextField
                      id="dni"
                      label="Identidad"
                      type="text"
                      fullWidth
                      size="small"
                      name="dni"
                      error={(submitted && !inputs?.dni)}   helperText={(submitted && !inputs?.dni) ? "Es Requerido":""}
                      onChange={handleChangeInput}
                      placeholder="Identidad"
                      // InputProps={{
                      //   inputComponent: TextMaskCustom as any,
                      // }}
                      value={inputs?.dni}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
              </Grid>
              <Grid item xs={4}>
                <img width={120} src="./logo.png" alt="" />
              </Grid>


              <Grid item xs={6}>
                  <TextField
                      id="name"
                      label="Nombre"
                      type="text"
                      fullWidth
                      size="small"
                      name="name"
                      error={(submitted && !inputs?.name)}   helperText={(submitted && !inputs?.name) ? "Es Requerido":""}
                      onChange={handleChangeInput}
                      placeholder="Nombre"
                      value={inputs?.name}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
              </Grid>

              <Grid item xs={3}>
              <TextField
                      id="phone"
                      label="Teléfono"
                      type="text"
                      size="small"
                      fullWidth
                      name="phone"
                      // InputProps={{
                      //   inputComponent: TextMaskPhone as any,
                      // }}
                      error={(submitted && !inputs?.phone)}   helperText={(submitted && !inputs?.phone) ? "Es Requerido":""}
                      onChange={handleChangeInput}
                      placeholder="Teléfono"
                      value={inputs?.phone}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
              </Grid>
              
              <Grid item xs={3}>
              <TextField
                      id="birthday"
                      label="Fecha de Nacimiento"
                      type="date"
                      size="small"
                      fullWidth
                      name="birthday"
                      error={(submitted && !inputs?.birthday)}   helperText={(submitted && !inputs?.birthday) ? "Es Requerido":""}
                      onChange={handleChangeInput}
                      placeholder="Fecha de Nacimiento"
                      value={new Date( inputs?.birthday?? Date() ).toISOString().substr(0,10)}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                
              </Grid>


              <Grid item xs={3}>
                <TextField
                      id="income"
                      label="Ingresos"
                      type="number"
                      size="small"
                      fullWidth
                      error={(submitted && !inputs?.income)}   helperText={(submitted && !inputs?.income) ? "Es Requerido":""}
                      name="income"
                      onChange={handleChangeInput}
                      placeholder="Ingresos"
                      value={inputs?.income}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">L</InputAdornment>,
                      }}
                      
                    />
              </Grid>
              <Grid item xs={3}>
                <TextField
                      id="canPay"
                      label="Capacidad de Pago"
                      type="number"
                      size="small"
                      fullWidth
                      name="canPay"
                      error={(submitted && !inputs?.canPay)}   helperText={(submitted && !inputs?.canPay) ? "Es Requerido":""}
                      onChange={handleChangeInput}
                      placeholder="Capacidad de Pago"
                      value={inputs?.canPay}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">L</InputAdornment>,
                      }}
                    />
              </Grid>

              <Grid item xs={3}>
                <TextField
                    id="email"
                    label="Correo"
                    type="text"
                    size="small"
                    fullWidth
                    name="email"
                    onChange={handleChangeInput}
                    placeholder="Correo"
                    value={inputs?.email}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>

              <Grid item xs={3}>
                <TextField
                    id="createDate"
                    label="Fecha de Ingreso"
                    type="date"
                    size="small"
                    fullWidth
                    disabled
                    name="createDate"
                    onChange={handleChangeInput}
                    placeholder="Fecha de Ingreso"
                    value={new Date( inputs?.createDate?? Date() ).toISOString().substr(0,10)}
                    margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>


            <Grid item xs={12}>
              <ImagePicker onImageAdd={handleImageAdd} images={openPrincipal.select.images??[]} loading={openPrincipal.loading}/>
            </Grid>

            
          

          </Grid>

      
           
          <Box my={3} >
            <Button type="submit" variant="contained" color="primary">
            {openPrincipal?.type ===0 ? "Guardar": "Editar"} Cliente 
            </Button>
          </Box>
          </form>
        }
             

            

        </TabPanel>

        

        <TabPanel value={value} index={1}>

        <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
            <Button variant="contained" color="primary" disabled={openPrincipal?.type ===0}   onClick={()=>{
              var addressNew = new CustomerAddress();
              addressNew.customerID  =openPrincipal?.select?.customerID 
              handleClickOpen( addressNew, 0);
            }
            }>
              Agregar
            </Button>
        </Grid>
        <Box  my={2}>
              <DataGrid autoHeight 
                  rows={openPrincipal?.select?.address??[]}
                  getRowId={(r) => r.addressID}
                  columns={columnsAddress}
                  pageSize={100}
                  density="compact"
                  rowsPerPageOptions={[5]}
                  
                  checkboxSelection={false}
                  onCellClick={(params, event) => {
                      console.log(params.row)
                      handleClickOpen(params.row as CustomerAddress, 1)
                    }}
                  
                  disableSelectionOnClick
              />
                  </Box>
        </TabPanel>
                  


        <TabPanel value={value} index={2}>
            <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
                <Button variant="contained" color="primary" disabled={openPrincipal?.type ===0}   onClick={()=>{
                  var guaranteNew = new Guarantee();
                  guaranteNew.customerID  =openPrincipal?.select?.customerID 
                  handleClickGuarantee( guaranteNew, 0);
                }
                }>
                  Agregar
                </Button>
            </Grid>


               <Grid container spacing={3}>
                  {(openPrincipal?.select?.guarantee??[]).map((item: Guarantee)=>{
                      return  <Grid key={item.guaranteeID?.toString()}  item xs={4}>
                      <Card id={item.guaranteeID?.toString()} style={{maxWidth: 325,}} >
                        <CardActionArea  onClick={event => {handleClickGuarantee(item,1) }} >
                          <CardMedia
                            style={{height: 180}}
                            image={(item?.images ??[]).length > 0 ? (`${environment.apiUri}/common/image/` + (item?.images??[])[0].imageUri ??"" ): "https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132484366.jpg"}
                            title="Contemplative Reptile"
                          />
                            <CardContent>
                            <Typography gutterBottom variant="h6" component="h4">
                            {item?.description}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">


                            <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                      <Typography  color="textSecondary">
                                        <strong>Marca</strong>
                                      </Typography>
                                      <Typography variant="body2" component="p">
                                      {item?.brand}
                                      </Typography>
                                  </Grid>

                                  <Grid item xs={6}>
                                      <Typography  color="textSecondary">
                                      <strong>Serie</strong>
                                      </Typography>
                                      <Typography variant="body2" component="p">
                                      {item?.serie}
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                      <Typography  color="textSecondary">
                                      <strong>Valor</strong>
                                      </Typography>
                                      <Typography variant="body2" component="p">
                                        L. {format((item?.cost ??0), 2)}
                                      </Typography>
                                  </Grid>
                            </Grid>
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                  </Grid>

                  })}
                 
               
              </Grid>
                  
        </TabPanel>

        <TabPanel value={value} index={3}>
            <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
                <Button variant="contained" color="primary" disabled={openPrincipal?.type ===0}   onClick={()=>{
                  var referentNew = new PersonalReferent();
                  referentNew.customerID  =openPrincipal?.select?.customerID 
                  handleClickOpenReferent( referentNew, 0);
                }
                }>
                  Agregar
                </Button>
            </Grid>
                  <Box  my={2}>
                              <DataGrid autoHeight 
                                  rows={openPrincipal?.select?.personalReferent??[]}
                                  getRowId={(r) => r.referentID}
                                  columns={columnsReferent}
                                  pageSize={100}
                                  density="compact"
                                  rowsPerPageOptions={[5]}
                                  
                                  checkboxSelection={false}
                                  onCellClick={(params, event) => {
                                      handleClickOpenReferent(params.row as PersonalReferent, 1)
                                    }}
                                  
                                  disableSelectionOnClick
                              />
                  </Box>
        </TabPanel>

        
        <DialogAddress onClose={handleCloseAddress} />                        
        <DialogReferent onClose={handleCloseReferent} />     
        <DialogGuarantee  onClose={handleCloseGuarantee} />
        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { SimpleDialog };