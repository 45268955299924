import MuiDialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Checkbox, CircularProgress, DialogContent, FormControl, FormControlLabel, FormHelperText, Grid,
IconButton, InputLabel,   MenuItem,   Select, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataDigaloProps } from '../../models/data_digalo_props';
import { Adviser } from './models/adviser';
import { hierarchyActions } from './actions/hierarchy.action';
import { Supervisor } from './models/supervisor';


export interface DialogTitleProps{
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = ((props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle   {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close"  onClick={onClose}
            sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}

>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});



export interface DialogAddressProps {
    onClose: (value: string) => void;
  }
  
  function DialogEditAdviser(props: DialogAddressProps) {
    const { onClose} = props;
    const dispatch = useDispatch();
    const  openAdviser  = useSelector((state: any) => state.hierarchy.openAdviser as DataDigaloProps<Adviser>);
    const [inputs, setInputs] = React.useState<Adviser>(openAdviser?.select);   
     const [submitted, setSubmitted] = React.useState(false);
     const  supervisor  = useSelector((state: any) => state.hierarchy.hierarchies as Supervisor[]);
     useEffect(() => {
         setInputs(openAdviser?.select)
         setSubmitted(false)
    }, [openAdviser]);
    
    const handleClose = () => {
      setInputs(new Adviser())
      dispatch(hierarchyActions.setOpenModalAdviserScreen({
        open : false,
        select : new Adviser(),
        type : 0,
        loading: false
      }));
      setSubmitted(false)
    };
  
    function handleChangeCheck(e:any)  {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: e.target.checked  }));
    };
    
    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if(inputs.name){
        if(openAdviser?.type ===0 ){
          dispatch(hierarchyActions.addAdviser(inputs));
        }
        if(openAdviser?.type ===1){
          dispatch(hierarchyActions.editAdviser(inputs));
        }
      }
    }

 
  function handleChange(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
  
  }

    return (
      <Dialog  id="inventoryDialog"  fullWidth={true} maxWidth="sm" aria-labelledby="simple-dialog-title" open={openAdviser?.open??false}>
        <DialogTitle onClose={handleClose}  id="simple-dialog-title" > {openAdviser?.type ===0 ? "Agregar": "Editar"} Asesor </DialogTitle>
        <DialogContent dividers>
  

        {
          openAdviser?.loading ?  <CircularProgress />:
   
        <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
        <Grid container spacing={3}>

        {/* <Grid item xs={6}>
              <TextField
                    id="adviserID"
                    label="Código de Asesor"
                    type="text"
                    placeholder="Código de Asesor"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    name="adviserID"
                    disabled={true}
                    value={inputs?.adviserID}
                    error={(submitted && !inputs?.adviserID)}   helperText={(submitted && !inputs?.adviserID) ? "Es Requerido":""}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>  */}
        <Grid item xs={6}>
              <TextField
                    id="name"
                    label="Nombre"
                    type="text"
                    placeholder="Nombre"
                    fullWidth
                    size="small"    
                    onChange={handleChange}
                    name="name"
                    value={inputs?.name}
                    error={(submitted && !inputs?.name)}   helperText={(submitted && !inputs?.name) ? "Es Requerido":""}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>


            <Grid item xs={6}>
                  <FormControl fullWidth margin='normal' >
                        <InputLabel  shrink>
                        Supervisor
                          </InputLabel>
                            <Select
                              value={inputs?.supervisorID}
                              onChange={handleChange}
                              displayEmpty
                              label="Supervisor"
                                size="small"
                              type="number"
                              
                              // multiple
                              // renderValue={(selected) => (selected as string[]).join(', ')}
                              placeholder="Seleccione un Supervisor"
                              name="supervisorID"
                              error={(submitted && !inputs?.supervisorID)}   
                              // helperText=
                              inputProps={{ 'aria-label': 'Without label' }}
                            >
                               {
                                (supervisor??[]).map((item: any)=>{//roles
                                  return <MenuItem  value={item.supervisorID}> {item.name}</MenuItem>
                                })
                              }
                            </Select>
                            {
                              (submitted &&  !inputs?.supervisorID) ? <FormHelperText error >Es requerido</FormHelperText>:""
                            }
                            
                  </FormControl>
              </Grid>


   
            {
              openAdviser.type ==1?
                <Grid item xs={12}>    
                      <FormControl required component="fieldset">
                        <FormControlLabel
                          control={
                            <Checkbox color="primary" name="status" checked={inputs?.status } onChange={handleChangeCheck}  />
                          }
                          label="Activo"
                        />
                        {/* <FormHelperText>You can display an error</FormHelperText> */}
                      </FormControl>
                  </Grid>
                :<Box></Box>
            }
            </Grid>
                  <Box my={3} >
                    <Button type="submit" variant="contained" color="primary">
                    {openAdviser?.type ===0 ? "Agregar": "Editar"} Asesor 
                    </Button>
                  </Box>
                  </form>        
              }
         
        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { DialogEditAdviser };
