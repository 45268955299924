import { Dispatch } from 'redux';
import { alertActions } from '../../../_actions/alert.actions';
import { reportConstants } from '../constants/report.constants';
import { FilterParams } from '../models/filter_params';
import { FinancialState } from '../models/financial_state';
import { reportService } from '../report.service';




export const reportActions = {
    getPaymentByDate,
    getFinancialStateByDate,
    getPlacingByDate,
    getRecoverByDate
};


function getPaymentByDate(filter: FilterParams) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        reportService.getPaymentByDate(filter)
            .then(
                data => dispatch(success(data.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: reportConstants.GET_PAYMENT_BY_DATE_REQUEST } }
    function success(reportPayment:any) { return { type: reportConstants.GET_PAYMENT_BY_DATE_SUCCESS, reportPayment } }
    function failure(error:any) { return { type: reportConstants.GET_PAYMENT_BY_DATE_FAILURE, error } }
}

function getFinancialStateByDate(fromDate: string, toDate: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        reportService.getFinancialStateByDate(fromDate,toDate )
            .then(
                data => dispatch(success(data.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: reportConstants.GET_FINANCIAL_STATE_BY_DATE_REQUEST } }
    function success(financialState:FinancialState) { return { type: reportConstants.GET_FINANCIAL_STATE_BY_DATE_SUCCESS, financialState } }
    function failure(error:any) { return { type: reportConstants.GET_FINANCIAL_STATE_BY_DATE_FAILURE, error } }
}



function getPlacingByDate(filter: FilterParams) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        reportService.getPlacingByDate(filter)
            .then(
                data => dispatch(success(data.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: reportConstants.GET_PLACING_BY_DATE_REQUEST } }
    function success(reportPlacing:any) { return { type: reportConstants.GET_PLACING_BY_DATE_SUCCESS, reportPlacing } }
    function failure(error:any) { return { type: reportConstants.GET_PLACING_BY_DATE_FAILURE, error } }
}


function getRecoverByDate(filter: FilterParams) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        dispatch(alertActions.clear());
        reportService.getRecoverByDate(filter)
            .then(
                data => dispatch(success(data.data)),
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request() { return { type: reportConstants.GET_RECOVER_BY_DATE_REQUEST } }
    function success(reportRecoverPay:any) { return { type: reportConstants.GET_RECOVER_BY_DATE_SUCCESS, reportRecoverPay } }
    function failure(error:any) { return { type: reportConstants.GET_RECOVER_BY_DATE_FAILURE, error } }
}



// function setOpenModalSettingScreen(openSetting:DataDigaloProps<Setting>) {
//     return (dispatch: Dispatch) => {
//         dispatch(  { type: settingConstants.SET_OPEN_SETTING_SUCCESS, openSetting });
//     };
// }



// function edit(setting:Setting) {
//     return (dispatch: Dispatch) => {
//         dispatch(request());
//         dispatch(alertActions.clear());
//         settingService.edit(setting)
//             .then(
//                 data => dispatch(success(data.data)),
//             ).catch((error)=>{
//                 const message =
//                 (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//                 dispatch(failure(message));
//                 dispatch(alertActions.error(message));
//             });
//     };
    
//     function request() { return { type: settingConstants.EDIT_SETTING_REQUEST } }
//     function success(setting:Setting) { return { type: settingConstants.EDIT_SETTING_SUCCESS, setting } }
//     function failure(error:any) { return { type: settingConstants.EDIT_SETTING_FAILURE, error } }
// }