export class Adviser {
    adviserID?: number;
    name?: string;
    status?: boolean;
    createDate?: Date;
    user?: string;
    supervisorID?: number;
    
    constructor(datos?: Adviser) {
      if (datos != null) {
        this.adviserID = datos.adviserID;
        this.name = datos.name;
        this.status = datos.status;
        this.createDate = datos.createDate;
        this.user = datos.user;
        this.supervisorID = datos.supervisorID;
        return;
      }
    }
  }
