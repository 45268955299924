export const publishConstants = {
    GETALL_REQUEST: 'GETALL_REQUEST',
    GETALL_SUCCESS: 'GETALL_SUCCESS',
    GETALL_FAILURE: 'GETALL_FAILURE',

    ADD_PUBLISH_REQUEST: 'ADD_PUBLISH_REQUEST',
    ADD_PUBLISH_SUCCESS: 'ADD_PUBLISH_SUCCESS',
    ADD_PUBLISH_FAILURE: 'ADD_PUBLISH_FAILURE',

    EDIT_PUBLISH_REQUEST: 'EDIT_PUBLISH_REQUEST',
    EDIT_PUBLISH_SUCCESS: 'EDIT_PUBLISH_SUCCESS',
    EDIT_PUBLISH_FAILURE: 'EDIT_PUBLISH_FAILURE',

    UPLOAD_APK_REQUEST: 'UPLOAD_APK_REQUEST',
    UPLOAD_APK_SUCCESS: 'UPLOAD_APK_SUCCESS',
    UPLOAD_APK_FAILURE: 'UPLOAD_APK_FAILURE',

    SET_OPEN_PUBLISH_SUCCESS: 'SET_OPEN_PUBLISH_SUCCESS' , 
};