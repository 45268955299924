import axios from 'axios';
import environment from '../../environment';
import { User } from './models/user';

export const userService = {
    getAll,
    getRoles,
    getHierarchies,
    getPermissions,
    add,
    edit
};


 function getAll() {
    return axios.get(environment.apiUri + "/user" )
  };

 function getRoles() {
    return axios.get(environment.apiUri + "/user/roles" )
  };

 function getHierarchies(roleID: number) {
    return axios.get(environment.apiUri + "/user/HirarchySelect/"+ roleID )
  };

 function getPermissions() {
    return axios.get(environment.apiUri + "/user/Permissions" )
  };


  function add(user: User) {
    return axios.post(environment.apiUri + "/user",user )
  };
  
  function edit(user: User) {
    return axios.put(environment.apiUri + "/user",user )
  };
  
  