import { myHistory } from '../_helpers/history';
import { authConstants } from '../_constants/auth.constants';
import { alertActions } from './alert.actions';
import { userService } from '../_services/user.service';
import { Dispatch } from 'redux';
  
export const authActions = {
    login,
    logout,
  //  getAll,
};

function login(username: string, password: string, from: any) {
    return (dispatch: Dispatch) => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then((user)=> { 
                    if (user.data.token) {
  
                        localStorage.setItem("user", JSON.stringify(user.data));
                    }
                    dispatch(success(user.data));
                    myHistory.push(from);
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };

    function request(user:any) { return { type: authConstants.LOGIN_REQUEST, user } }
    function success(user:any) { return { type: authConstants.LOGIN_SUCCESS, user } }
    function failure(error:any) { return { type: authConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: authConstants.LOGOUT };
}



// function getAll() {
//     return (dispatch: Dispatch) => {
//         dispatch(request());

//         userService.getAll()
//             .then(
//                 users => dispatch(success(users)),
//                 error => dispatch(failure(error.toString()))
//             );
//     };

//     function request() { return { type: userConstants.GETALL_REQUEST } }
//     function success(users:any) { return { type: userConstants.GETALL_SUCCESS, users } }
//     function failure(error:any) { return { type: userConstants.GETALL_FAILURE, error } }
// }
