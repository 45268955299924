import React, { useEffect , useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, Chip, Container, Grid,  InputAdornment,  TextField,  Typography } from '@mui/material';
import { Lending } from '../../models/lending/lending';
import { DataGrid,  GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { NavLink } from 'react-router-dom';
import { format } from '../../_helpers/format';
import { paymentActions } from '../../_actions/payment.action';
import WarningIcon from '@mui/icons-material/Warning';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import { DialogRecordDetail } from './components/dialog.record.detail';
import SearchIcon from '@mui/icons-material/Search';
import { DebounceInput } from 'react-debounce-input';
import { filter } from '../../_helpers/filter';


function RecordPage() {
    const recordLendings = useSelector((state:any)=> state.payments.recordLendings )
    const loadingAdviser = useSelector((state:any)=> state.inscriptions.loadingAdviser)
    const loading = useSelector((state:any)=> state.payments.loading)
    const user = useSelector((state:any) => state.authentication.user);
    const dispatch = useDispatch();
    const [recordLendingsFilters, setRecordLendingsFilters] = useState<Lending[]>(recordLendings);

    useEffect(() => {
        if(recordLendings.length == 0){
            dispatch(paymentActions.getExpedient("001"));//TODO 
        }
      }, []);

   useEffect(() => { 
       setRecordLendingsFilters(recordLendings)
    },[recordLendings]);
      
      const handleCustomerClose = (lending: Lending) => {
        console.log(lending);
       
     };
     const handleClickOpen = (row: Lending, type: number) => {
        dispatch(paymentActions.setOpenLendingScreen({
            open: true,
            select :row ?? new Lending(),
            type : type,
            loading:false
        }));
      };

 
    
      const columns: GridColDef[] = [
        

        { field: 'lendingID', headerName: '# Solicitud', width: 110 },
        {
            field: 'customerID',
            headerName:'Cliente ID',
            width:105

        },
        {
            field: 'customer',
            headerName:'Cliente',
            width:210
        },
        // {
        //     field: 'amountFine',
        //     headerName:'Castigo',
        //     width:120
        // },
        {
            field: 'amount',
            headerName: 'Monto',
            width: 110,
            valueFormatter: (params) => {
              if(params?.value){
                var data: number = parseFloat(params?.value as string);
                return "L. "+ format( data,2)
              } 
           },
          }, 
        {
            field: 'amountAfterInterest',
            headerName:'Monto Con Intereses',
            width:170,
            valueFormatter: (params) => {
                if(params?.value){
                  var data: number = parseFloat(params?.value as string);
                  return "L. "+ format( data,2)
                } 
             },
        },
        {
            field: 'amountPay',
            headerName:'Monto Pagado',
            width:160,
            valueFormatter: (params) => {
                if(params?.value){
                  var data: number = parseFloat(params?.value as string);
                  return "L. "+ format( data,2)
                } 
             },
        },
        // {
        //     field: 'term',
        //     headerName:'Plazo',
        //     width:110
        // },
        {
            field: 'percentPay',
            headerName:'Porcentaje',
            width:120,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                {
                  ((params?.value??0) >90 ) ?
                    <Chip
                          icon={<DoneIcon />}
                          label={parseFloat((params?.value??0).toString()).toFixed(0)  + "%"}
                          color="primary"
                          size="small"
                          style={{border: "1px solid #00c853", color: "#00c853"}}
                          variant="outlined"
                        />
                  :
                  ((params?.value??0) >40 )?
                    <Chip
                        icon={<WarningIcon />}
                        label={parseFloat((params?.value??0).toString()).toFixed(0)  + "%"}
                        color="primary"
                        size="small"
                        style={{border: "1px solid #ffb300", color: "#ffb300"}}
                        variant="outlined"
                      />
                  :
                  ((params?.value??0) >0 )?
                  <Box>
                    
                   
                    <Chip
                        icon={<ErrorIcon />}
                        label={ parseFloat((params?.value??0).toString()).toFixed(0)  + "%" }
                        color="primary"
                        size="small"
                        style={{border: "1px solid #ff1744", color: "#ff1744"}}
                        variant="outlined"
                      />
                  </Box>
                  : <Box></Box>
              
                }
              </Box>
            )
        },
        {
            field: 'createDate',
            headerName: 'Fecha',
            width: 160,
            type:'date',
            valueFormatter: (params) => {
              if(params?.value){
                return new Date(params?.value as string).toLocaleDateString().substr(0,10)
              } 
           },
        },
        

        // {
        //     field: 'actions',
        //     headerName: 'Acciones',
        //     sortable: false,
        //     width: 100,
        //     headerAlign: 'center',
        //     filterable: false,
        //     align: 'center',
        //     disableColumnMenu: true,
        //     disableReorder: true,

        //     renderCell: (params: GridRenderCellParams) => (
        //         <Box>
        //           {
        //             ((params?.row?.quantity??0) ==(params?.value??0) ) ?
        //               <Chip
        //                     icon={<DoneIcon />}
        //                     label={"Listo"}
        //                     color="primary"
        //                     size="small"
        //                     style={{border: "1px solid #00c853", color: "#00c853"}}
        //                     variant="outlined"
        //                   />
        //             :
        //             ((params?.value??0) ==0)?
        //               <Chip
        //                   icon={<WarningIcon />}
        //                   label={" Sin recuento"}
        //                   color="primary"
        //                   size="small"
        //                   style={{border: "1px solid #ffb300", color: "#ffb300"}}
        //                   variant="outlined"
        //                 />
        //             :
        //             ((params?.value??0) > 0)?
        //             <Box>
                      
                     
        //               <Chip
        //                   icon={<ErrorIcon />}
        //                   label={" No cuadra: " +  (params?.value??0) }
        //                   color="primary"
        //                   size="small"
        //                   style={{border: "1px solid #ff1744", color: "#ff1744"}}
        //                   variant="outlined"
        //                 />
        //             </Box>
        //             : <Box></Box>
        //           }
        //         </Box>
        //       ),
        //   },
      
      ];
  
    return (
        <Box my={2}>
            <Container maxWidth="xl">
              <Card  variant="outlined" style={{padding:"5px 20px 25px 20px"}} >

                <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
                    
            
                    <Box my={2} >
                        <Button  variant="contained"  color="primary" component={NavLink}  to={"/solicitud"} >Nueva Solicitud </Button>
                    </Box>

                </Grid>
                {/* <Typography variant="h6" component="h2">
                    Solicitud Préstamo
                </Typography> */}
          
                <Box >
                    <Typography variant="h6" gutterBottom component="div">
                        Prestamos Pendientes
                    </Typography>
                </Box>
                <Box my={1}>
                    <DebounceInput  style={{width:"100%"}} id="standard-basic" label="Buscador" 
                    variant="filled" size="small" debounceTimeout={400} element={TextField as any} 
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={event => {
                          let textSearch = (event.target?.value??"");
                          if(textSearch.length > 1){ 
                            dispatch(paymentActions.searchByDecription("001",textSearch));
                          }
                      }} 

                    />
                </Box>
                <DataGrid autoHeight 
                    rows={recordLendingsFilters??[]}
                    loading= {loading || loadingAdviser}
                    getRowId={(r) => r.lendingID}
                    columns={columns}
                    pageSize={10}
                    
                    rowsPerPageOptions={[5]}
                    nonce="No se encontraron resultados"
                    density="compact"
                    
                    checkboxSelection={false}
                    onCellClick={(params, event) => {
                        console.log(params.row)
                        handleClickOpen(params.row as Lending, 1)
                      }}
                      
                    disableSelectionOnClick
                />

            </Card>
            </Container>
            <DialogRecordDetail onClose={handleCustomerClose} ></DialogRecordDetail>
        </Box>
    );
}

export { RecordPage };