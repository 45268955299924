import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, Container, FormControl, FormHelperText, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { customerActions } from '../../_actions/customer.actions';
import { Customer } from '../../models/customer/customer';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DialogCustomers } from './components/dialog.customers';
import { inscriptionActions } from '../../_actions/inscription.actions';
import { Lending } from '../../models/lending/lending';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format } from '../../_helpers/format';
import { Adviser } from '../hierarchy/models/adviser';
import { User } from '../user/models/user';
import { ImagePicker } from '../../_components/ImagePicker';
import { bytesToSize } from '../../_helpers/common';
import { alertActions } from '../../_actions/alert.actions';

function LendingInscriptionPage() {

    const  customers  = useSelector((state: any) => state.customers.items as Customer[]);
    const lending = useSelector((state:any)=> state.inscriptions.lending)
    const loading = useSelector((state:any)=> state.inscriptions.loading)
    const advisers = useSelector((state:any)=> state.inscriptions.advisers as Adviser[])
    const dispatch = useDispatch();
    const [inputs, setInputs] = React.useState<Lending>(lending);
    const  user  = useSelector((state: any) => state.authentication.user as User);
    const [submitted, setSubmitted] = React.useState(false);
    useEffect(() => {
        inputs.interest = user.companyInterestPercent ;
        if(customers.length == 0){
          dispatch(customerActions.getAll());
        }
      }, []);
    
    
      useEffect(() => {
        if(advisers.length == 0){
          dispatch(inscriptionActions.getAdviser());
        }
      }, [advisers]);

    useEffect(() => {
        setInputs(new Lending(lending));
        setSubmitted(false);
    }, [lending]);

   
      const handleCustomerClose = (customer: Customer) => {
          console.log(customer);
          setInputs(inputs => ({ ...inputs, "customerID": customer.customerID}));
          setInputs(inputs => ({ ...inputs, "customer": customer.name}));
       };


    function handleChange(e:any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e:any) {
        e.preventDefault();
        setSubmitted(true);
        if (inputs.term && inputs.amount && lending.customerID && inputs.durationID && inputs.adviserID) {

          let fee  = (( parseFloat(inputs.amount.toString())  ) + ( parseFloat( inputs.amount.toString()) * (inputs?.interest??0) ) + 50 ) / inputs.term;

          inputs.fees = [];
            // get return url from location state or default to home page
            // const { from } = location .state || { from: { pathname: "/" } };
            dispatch(inscriptionActions.calculateFee(inputs));
        }
      }
    function handleAdd(e:any) {
        e.preventDefault();
        setSubmitted(true);
        if (inputs.term && inputs.amount && lending.customerID && inputs.durationID && (inputs.fees?? []).length > 0 && inputs.adviserID) {
          inputs.createBy = user.userID;
            // get return url from location state or default to home page
            // const { from } = location .state || { from: { pathname: "/" } };
            dispatch(inscriptionActions.add(inputs));
        }
      }


      const handleImageAdd = (image:any) => {
        console.log(image);
        let uploadedImg = image;
        const ACCEPTED_UPLOAD_SIZE = 1024; // 1MB
        if ( bytesToSize(uploadedImg.size) > ACCEPTED_UPLOAD_SIZE
        ) {
          dispatch(alertActions.error("La imagen es demasiado grande"));
          return;
        }
        dispatch(inscriptionActions.addImageLending( lending,uploadedImg));
        
      };

      function clean(e:any) {
        e.preventDefault();
        setInputs(new Lending());
        setSubmitted(false);
      }

      const columns: GridColDef[] = [
        { field: 'lineNumber', headerName: '# Cuota', width: 130 },
        {
          field: 'amount',
          headerName: 'Cuota',
          width: 200,
          valueFormatter: (params) => {
            if(params?.value){
              var data: number = parseFloat(params?.value as string);
              return "L. "+ format( data,2)
            } 
         },
  
        },
        {
          field: 'expireDate',
          headerName: 'Fecha',
          width: 160,
          type:'date',
          valueFormatter: (params) => {
            if(params?.value){
              return new Date(params?.value as string).toLocaleDateString().substr(0,10)
            } 
         },
 
        },
        {
          field: 'day',
          headerName: 'Día',
          width: 140,
        },
      ];
  
    return (
        <Box my={2}>
            <Container maxWidth="xl">
                <Card  variant="outlined" style={{padding:"5px 20px 25px 20px"}} >

                <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
                    
                    <Box my={2} mx={2} >
                        <Button variant="contained" color="secondary" onClick={(e)=>{
                            clean(e);
                            }
                        }>Nuevo</Button>
                    </Box>
                    <Box my={2} mx={2} >
                        <Button variant="contained" color="secondary" onClick={(e)=>{
                            handleSubmit(e);
                            }
                        }>Calcular Cuota </Button>
                    </Box>

                    <Box my={2} >
                        <Button disabled={(inputs.fees??[]).length==0} variant="contained" color="primary" onClick={(e)=>{
                            handleAdd(e);
                            }
                        }>Guardar Solicitud </Button>
                    </Box>

                </Grid>
                {/* <Typography variant="h6" component="h2">
                    Solicitud Préstamo
                </Typography> */}
            <Grid container spacing={3}>

                    <Grid item xs={6}>
                     
                            <FormControl fullWidth  >
                                <InputLabel shrink > Cliente </InputLabel>

                                <Input
                                    id="selectCustomer"
                                    value={lending?.customer || ''}
                                    placeholder={"Seleccione un cliente"}
                                    disabled={true}
                                    error={(submitted && !lending?.customerID)}   
                                    endAdornment={<InputAdornment position="end">
                                        <IconButton
                                            aria-label="Cambiar"
                                            onClick={()=>{
                                                dispatch(inscriptionActions.setOpenCustomerScreen({
                                                    open: true,
                                                    select :{},
                                                    type : 0,
                                                    loading:false
                                                }));
                                                }
                                            }
                                            >
                                        <MoreVertIcon /> 
                                        </IconButton>
                                    </InputAdornment>}
                                />
                                 {
                                (submitted &&  !lending?.customerID) ? <FormHelperText error >Es requerido</FormHelperText>:""
                                }

                            </FormControl>
                            
                    </Grid>
                    <Grid item xs={3}> 
                        <TextField
                                    id="interest"
                                    label="Interes"
                                    type="text"
                                    placeholder="Interes"
                                    fullWidth
                                    size="small"
                                    name="interest"
                                    disabled={true}
                                    value={inputs?.interest??0}
                                    //error={(submitted && !inputs?.interest)}   helperText={(submitted && !inputs?.interest) ? "Es Requerido":""}
                                    onChange={handleChange}
                        
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>


                    <Grid item xs={3}> 
                        <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth> 
                            <InputLabel shrink > Plazo (en tiempo) </InputLabel>
                            <Select 
                            fullWidth
                            labelId="durationID"
                            size="small"
                            name="durationID"
                            placeholder={"Plazo (en tiempo)"}
                            error={(submitted && !inputs?.durationID)} 
                            value={inputs?.durationID || 1}
                            onChange={handleChange}
                            >
                            <MenuItem value={1}>Días</MenuItem>
                            {/* <MenuItem value={2}>Meses</MenuItem>
                            <MenuItem value={3}>Años</MenuItem> */}
                            </Select>
                            {
                            (submitted &&  !inputs?.durationID) ? <FormHelperText error >Es requerido</FormHelperText>:""
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}> 
                                <TextField
                                    id="term"
                                    label="Plazo en numero"
                                    type="text"
                                    size="small"
                                    placeholder="Plazo en numero"
                                    fullWidth
                                    name="term"
                                    value={inputs?.term  || ''}
                                    error={(submitted && !inputs?.term)}   helperText={(submitted && !inputs?.term) ? "Es Requerido":""}
                                    onChange={handleChange}
                              
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>


                    <Grid item xs={4}> 
                        <TextField
                                    id="amount"
                                    label="Monto"
                                    type="text"
                                    placeholder="Monto"
                                    fullWidth
                                    size="small"
                                    name="amount"
                                    value={inputs?.amount || ''}
                                    error={(submitted && !inputs?.amount)}   helperText={(submitted && !inputs?.amount) ? "Es Requerido":""}
                                    onChange={handleChange}
                        
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                    </Grid>


                    <Grid item xs={4}> 
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                        <InputLabel shrink  id="label" >
                            Asesor
                        </InputLabel>
                            <Select
                                value={inputs?.adviserID || ''}
                                onChange={handleChange}
                                displayEmpty
                                labelId="label" 
                                size="small"
                                label="Asesor"
                                placeholder="Seleccione un Asesor"
                                name="adviserID"
                                error={(submitted && !inputs?.adviserID)}   
                                // helperText=
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {
                                advisers.map((item: Adviser)=>{
                                    return <MenuItem value={item.adviserID}> {item.adviserID} - {item.name}</MenuItem>
                                })
                                }
                            </Select>
                            {
                                (submitted && !inputs?.adviserID) ? <FormHelperText error >Es requerido</FormHelperText>:""
                            }
                            
                            </FormControl>

                    </Grid>

                    
                </Grid>
                <Grid item xs={12}  mt={2}>
                    <ImagePicker onImageAdd={handleImageAdd} images={lending.images??[]} loading={loading}/>
                </Grid>
                <Box  my={3}>
                    <Typography variant="h6" gutterBottom component="div">
                       Cuotas
                    </Typography>
                </Box>

                <DataGrid autoHeight 
                    rows={inputs?.fees??[]}
                    loading= {loading}
                    getRowId={(r) => r.lineNumber}
                    columns={columns}
                    
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    nonce="No se encontraron resultados"
                    density="compact"
                    
                    checkboxSelection={false}
                    // onCellClick={(params, event) => {
                    //     console.log(params.row)
                    //     handleClickOpen(params.row as Customer)
                    //   }}
                      
                    disableSelectionOnClick
                />

                </Card>
            </Container>
            <DialogCustomers onClose={handleCustomerClose} ></DialogCustomers>
        </Box>
    );
}

export { LendingInscriptionPage };