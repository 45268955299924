import { AddressImage } from "./address_image";

export class CustomerAddress {
    addressID?: number;
    customerID?: string;
    name?: string;
    address?: string;
    city?: string;
    contact?: string;
    phone?: string;
    latitude?: number;
    longitude?: boolean;
    sketch?:string;
    createDate?:Date;
    active? : boolean;
    images?: AddressImage[];
    
    constructor(datos?: CustomerAddress) {
      if (datos != null) {
        this.addressID = datos.addressID;
        this.customerID = datos.customerID;
        this.address = datos.address;
        this.city = datos.city;
        this.contact = datos.contact;
        this.phone = datos.phone;
        this.latitude = datos.latitude;
        this.longitude = datos.longitude;
        this.sketch = datos.sketch;
        this.createDate = datos.createDate;
        this.active = datos.active;
        this.images = datos.images;
        return;
      }
       
    }
  }
