import MuiDialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, DialogContent, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { CustomerAddress } from '../../../models/customer/customer_address';
import { useDispatch, useSelector } from 'react-redux';
import { customerActions } from '../../../_actions/customer.actions';
import { bytesToSize} from '../../../_helpers/common'; // TextMaskPhone 
import { alertActions } from '../../../_actions/alert.actions';
import environment from '../../../environment';
import { AddAPhoto } from '@mui/icons-material';
import { DataDigaloProps } from '../../../models/data_digalo_props';
import { ImagePicker } from '../../../_components/ImagePicker';


export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = ((props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle   {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close"  onClick={onClose}
            sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}

>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});





export interface DialogAddressProps {
    onClose: (value: string) => void;
  }
  
  function DialogAddress(props: DialogAddressProps) {
    const { onClose} = props;


    
    
    
    const dispatch = useDispatch();
    
    const  openAddress  = useSelector((state: any) => state.customers.openAddress as DataDigaloProps<CustomerAddress>);

    const [inputs, setInputs] = React.useState<CustomerAddress>(openAddress?.select);
   
    // const { active, address, city,  contact,latitude, longitude,name,phone} = inputs;
     const [submitted, setSubmitted] = React.useState(false);
     useEffect(() => {
         setInputs(openAddress?.select)
         setSubmitted(false)
    }, [openAddress]);


    
    const handleClose = () => {
      setInputs(new CustomerAddress())
      dispatch(customerActions.setOpenAddressScreen({
        open : false,
        select : new CustomerAddress(),
        type : 0,
        loading: false
      }));
      setInputs(new CustomerAddress())
      onClose("Hola");
      setSubmitted(false)
    };


    const handleImageAdd = (image:any) => {
      console.log(image);
      let uploadedImg = image;
      const ACCEPTED_UPLOAD_SIZE = 1024; // 1MB
      if ( bytesToSize(uploadedImg.size) > ACCEPTED_UPLOAD_SIZE
      ) {
        dispatch(alertActions.error("La imagen es demasiado grande"));
        return;
      }
      dispatch(customerActions.addImageAddress( inputs,uploadedImg));
      
    };
  
  

    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.name && inputs.city && inputs.address && inputs.phone ) {
        inputs.sketch = "";
        inputs.customerID = openAddress?.select?.customerID;
          // get return url from location state or default to home page
          // const { from } = location .state || { from: { pathname: "/" } };
          if(openAddress?.type ===0){
            dispatch(customerActions.addAddress(inputs));
          }
          if(openAddress?.type ===1){
            inputs.addressID = openAddress?.select?.addressID;
            dispatch(customerActions.editAddress(inputs));
          }
      }
    }

    function handleChange(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  
    return (
      <Dialog  id="customerAddressDialog"  fullWidth={true} maxWidth="sm"  aria-labelledby="simple-dialog-title" open={openAddress?.open??false}>
        <DialogTitle onClose={handleClose}  id="simple-dialog-title" > {openAddress?.type ===0 ? "Agregar": "Editar"} Dirección </DialogTitle>
        <DialogContent dividers>
        {
          openAddress?.loading ?  <CircularProgress />:
   
        <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
        <Grid container spacing={3}>

            <Grid item xs={6}>
              <TextField
                    id="name"
                    label="Nombre"
                    type="text"
                    placeholder="Nombre"
                    fullWidth
                    name="name"
                    value={inputs?.name}
                    error={(submitted && !inputs?.name)}   helperText={(submitted && !inputs?.name) ? "Es Requerido":""}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>
            <Grid item xs={6}>    
                  <TextField
                    id="city"
                    label="Ciudad"
                    type="text"
                    fullWidth
                    name="city"
                    placeholder="Ciudad"
                    value={inputs?.city}
                    error={(submitted && !inputs?.city)}   helperText={(submitted && !inputs?.city) ? "Es Requerido":""}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>
    
            <Grid item xs={12}>
                <TextField
                    id="address"
                    label="Dirección"
                    type="text"
                    fullWidth
                    name="address"
                    placeholder="Dirección"
                    error={(submitted && !inputs?.address)}   helperText={(submitted && !inputs?.address) ? "Es Requerido":""}
                    value={inputs?.address}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>

            <Grid item xs={6}>
            <TextField
                    id="phone"
                    label="Teléfono"
                    type="text"
                    fullWidth
                    name="phone"
                    placeholder="Teléfono"
                    error={(submitted && !inputs?.phone)}   helperText={(submitted && !inputs?.phone) ? "Es Requerido":""}
                    value={inputs?.phone}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // InputProps={{
                    //   inputComponent: TextMaskPhone as any,
                    // }}
                  />
            </Grid>
            
            <Grid item xs={6}>
            <TextField
                    id="contact"
                    label="Contacto"
                    type="text"
                    fullWidth
                    name="contact"
                    placeholder="Contacto"
                    value={inputs?.contact}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>


            <Grid item xs={6}>
            <TextField
                    id="latitude"
                    label="Latitude"
                    type="text"
                    fullWidth
                    name="latitude"
                    placeholder="Latitude"
                    value={inputs?.latitude}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>

            <Grid item xs={6}>
            <TextField
                    id="longitude"
                    label="Longitude"
                    type="text"
                    fullWidth
                    name="longitude"
                    placeholder="Longitude"
                    value={inputs?.longitude}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>

    

            <Grid item xs={12}>
              <ImagePicker onImageAdd={handleImageAdd} images={openAddress.select.images??[]} loading={openAddress.loading}/>
            </Grid>
          </Grid>
            <Box my={3} >
              <Button type="submit" variant="contained" color="primary">
              {openAddress?.type ===0 ? "Guardar": "Editar"} Dirección 
              </Button>
            </Box>
            </form> 
          }
        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { DialogAddress };