export class HierarchySelect {
    id?: number;
    name?: string;
    rolID?: number;
    
    constructor(datos?: HierarchySelect) {
      if (datos != null) {
        this.id = datos.id;
        this.name = datos.name;
        this.rolID = datos.rolID;
        return;
      }
    }
  }
