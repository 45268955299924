import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { HomePage } from '../pages/HomePage';
import { LoginPage } from '../pages/LoginPage';
import { alertActions } from '../_actions/alert.actions';
import { authActions } from '../_actions/auth.actions';
import Header from '../_components/Header';
import { myHistory } from '../_helpers/history';
import './App.css';
import PrivateRoute from './privateRoute';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CustomerPage } from '../pages/customer/CustomerPage';
import { Box } from '@mui/material';
import { LendingInscriptionPage } from '../pages/lending/LeadingInscriptionPage';
import { ListRequestLendingPage } from '../pages/lending/ListRequestLendingPage';
import { DaysFreePage } from '../pages/DaysFreePage';
import { RecordPage } from '../pages/record/RecordPage';
import { PaymentPage } from '../pages/payment/PaymentPage';
import { UsersPage } from '../pages/user/UserListPage';
import { HierarchyPage } from '../pages/hierarchy/HierarchyListPage';
import { SettingPage } from '../pages/general_settings/SettingPage';
import { PaymentByDatePage } from '../pages/reports/PaymentByDatePage';
import { PlacingByDatePage } from '../pages/reports/PlacingByDatePage';
import { RecoverByDatePage } from '../pages/reports/RecoverByDatePage';
import { PublishPage } from '../pages/publish/PublishListPage';
import { SpentsListPage } from '../pages/spents/SpentListPage';
import { SpentTypeListPage } from '../pages/spents/SpentTypeListPage';
import { FinancialStateHistoricPage } from '../pages/reports/FinancialStateHistoricPage';

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#0277bd',
      dark: '#ececec',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#fff',
      dark: '#ececec',
      contrastText: '#000',
    },
    success:{
      light: '#8bc34a',
      main: '#ff5722',
      dark: '#673ab7',
      contrastText: '#000',
    }
  },
});

function App() {
  //const { user: currentUser } = useSelector((state: any) => state.auth);
  const  user  = useSelector((state: any) => state.authentication.user);
  const dispatch = useDispatch();

  useEffect(() => {
    myHistory.listen((location, action) => {
          // clear alert on location change
          dispatch(alertActions.clear());
      });
  }, []);

  useEffect(() => {
    // if (currentUser) {
    //   setShowModeratorBoard(currentUser.hierarchyID == 1);//.roles.includes("ROLE_MODERATOR")
    //   setShowAdminBoard(currentUser.hierarchyID ==0);//roles.includes("ROLE_ADMIN")
    // }
  }, [user]);


  axios.interceptors.response.use(
    response => response,
    error => {
      const {status} = error?.response??0;
      if (status ===401) {
        dispatch(authActions.logout());
        //error= "Su sesión ha finalizado, por favor ingrese denuevo"
      }
      return Promise.reject(error);
    }
    );

 axios.defaults.headers.common['Authorization'] = `Bearer ${(user?.token??"")}`


    
  return (
    <ThemeProvider theme={theme}>
 

    <Router history={myHistory}>
    <Box>
    {user?.userName? (<Header></Header>)  : (<Box></Box>)}
        <Switch> 
          {/* <PrivateRoute exact path="/clientes-nuevos" isSignedIn={currentUser } component={ListNewCustomer} /> */}
          <PrivateRoute exact path="/" isSignedIn={user } component={HomePage} />
          <PrivateRoute exact path="/solicitud" isSignedIn={user } component={LendingInscriptionPage} />
          <PrivateRoute exact path="/clientes" isSignedIn={user } component={CustomerPage} />
          <PrivateRoute exact path="/solicitudes" isSignedIn={user } component={ListRequestLendingPage} />
          <PrivateRoute exact path="/expediente" isSignedIn={user } component={RecordPage} />
          <PrivateRoute exact path="/feriados" isSignedIn={user } component={DaysFreePage} />
          <PrivateRoute exact path="/pagar" isSignedIn={user } component={PaymentPage} />
          <PrivateRoute exact path="/usuarios" isSignedIn={user } component={UsersPage} />
          <PrivateRoute exact path="/jerarquia" isSignedIn={user } component={HierarchyPage} />
          <PrivateRoute exact path="/ajustes" isSignedIn={user } component={SettingPage} />
          <PrivateRoute exact path="/pagos-por-fecha" isSignedIn={user } component={PaymentByDatePage} />
          <PrivateRoute exact path="/colocacion-por-fecha" isSignedIn={user } component={PlacingByDatePage} />
          <PrivateRoute exact path="/recuperado-por-fecha" isSignedIn={user } component={RecoverByDatePage} />
          <PrivateRoute exact path="/publicacion" isSignedIn={user } component={PublishPage} />
          <PrivateRoute exact path="/gastos" isSignedIn={user } component={SpentsListPage} />
          <PrivateRoute exact path="/tipo-gastos" isSignedIn={user } component={SpentTypeListPage} />
          <PrivateRoute exact path="/estado-cuenta-historico" isSignedIn={user } component={FinancialStateHistoricPage} />
          <Route exact path="/login" component={LoginPage} />
          <Redirect from="*" to="/" />
        </Switch>

    </Box>
  </Router>
  </ThemeProvider>
  );
}

export default App;
