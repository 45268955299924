import axios from 'axios';
import environment from '../environment';
import { DayFree } from '../models/day_free';
import { Lending } from '../models/lending/lending';


export const inscriptionService = {
    calculateFee,
    add,
    edit,
    posting,
    getAdviser,
    getPendientLending,
    AddEditDayFree,
    getAll
};


 function getAll() {
    return axios.get(environment.apiUri + "/common/dayFrees" )
  };

 function calculateFee(lending: Lending) {
    return axios.post(environment.apiUri + "/lending/CalculateFee",lending )
  };

 function add(lending: Lending) {
    return axios.post(environment.apiUri + "/lending",lending )
  };
  
  
  function edit(lending: Lending) {
    return axios.put(environment.apiUri + "/lending",lending )
  };
  

  function posting(lending: Lending) {
    return axios.post(environment.apiUri + "/lending/Posting",lending )
  };

 function getAdviser() {
    return axios.get(environment.apiUri + "/common/GetAdviser" )
  };


 function getPendientLending() {
    return axios.get<Lending[]>(environment.apiUri + "/lending/PendientLending" )
  };


  function AddEditDayFree(dayFree: DayFree) {
    return axios.post(environment.apiUri + "/common/AddEditDayFree",dayFree )
  };
  
  