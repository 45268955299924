import { PaymentRequest } from "../models/payment/payment_request";
import { ToastSwall } from "../_helpers/common";
import { Adviser } from "../pages/hierarchy/models/adviser";
import { PaymentStateModel } from "../models/lending/payment_state_model";
import { paymentConstants } from "../_constants/payment.constants";


const initialState: PaymentStateModel = { 
    loading: false, 
    loadingAdviser: false,
    error: "",
    advisers: Array<Adviser>(),
    paymentRequest: new PaymentRequest(),
    pendients: Array<PaymentRequest>(),
    recordLendings: Array<PaymentRequest>(),
    openLending:{
        open: false,
        select: {},
        type: 0,
        loading: false
    },
    openPendients: {
        open: false,
        select: {},
        type: 0,
        loading: false,
    },
    } ;

export function payments(state = initialState, action:any) :PaymentStateModel {
    switch (action.type) {

        // case inscriptionConstants.SET_OPEN_CUSTOMER_SUCCESS:
        // let customer = new Customer();
        // let newLending = new Lending(state.lending);
        // if(action.openCustomer.select?.customerID){
        //     customer = action.openCustomer.select;
        //     newLending.customer = customer.name;
        //     newLending.customerID = customer.customerID;
        // }
        // return {
        //     ...state,
        //     openCustomer:action.openCustomer,
        //     lending: newLending,
        // };
      
        
        case paymentConstants.SET_OPEN_REQUEST_SUCCESS:
        return {
            ...state,
            paymentRequest: action.openRequest.select,
            openPendients: action.paymentRequest
        
        };
        case paymentConstants.SET_OPEN_LENDING_SUCCESS:
        return {
            ...state,
            openLending: action.openLending
        
        };



           case paymentConstants.GET_PENDIENT_PAYMENT_REQUEST: 
            return {
                ...state,
                loading: true,
            };

        case paymentConstants.GET_PENDIENT_PAYMENT_SUCCESS:
            return {
                ...state,
                pendients : action.paymentRequest,
                openPendients: {
                    open: true,
                    loading: false,
                    select: {},
                    type: 0
                },
                loading: false
            };
        case paymentConstants.GET_PENDIENT_PAYMENT_FAILURE:
            
            return {
                ...state,
                error: action.error,
                loading: false
            };

        // case inscriptionConstants.SET_OPEN_DAY_FREE_SUCCESS:

        // return {
        //     ...state,
        //     openDayFree:action.openDayFree
        // };
       


      



        

        case paymentConstants.POST_PAYMENT_REQUEST:
            
            return {
                ...state,
                loading: true
            };
    
        case paymentConstants.POST_PAYMENT_SUCCESS: 
            let index = state.recordLendings.findIndex((item)=> item.lendingID == action.lending.lendingID);
            // if(index != -1){
                var newList = state.recordLendings.map((item)=>{
                    return item
                });
                newList[index] = action.lending;
            // }
            ToastSwall.fire({
                icon: 'success',
                title: 'Cuota Pagada'
                })
            return {
                ...state,
                //paymentRequest : new PaymentRequest({} ),
                openLending: {
                    open: true,
                    loading: false,
                    select :action.lending,
                    type :0
                },
                recordLendings: newList,
                loading: false
            };
        case paymentConstants.POST_PAYMENT_FAILURE:
            
            return {
                ...state,
                error: action.error,
                loading: false
            };
        





    
        case paymentConstants.GET_EXPEDIENT_REQUEST:
        
            return {
                ...state,
                loading: true
            };
    
        case paymentConstants.GET_EXPEDIENT_SUCCESS:
            return { 
                ...state,

                recordLendings: action.recordLendings,
                loading: false
            };
        case paymentConstants.GET_EXPEDIENT_FAILURE:
            
            return {
                ...state,
                error: action.error,
                loading: false
            };

        default:
            return state
    }
}