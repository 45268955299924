import Dialog from '@mui/material/Dialog';
import { Box, Button, Checkbox, CircularProgress, DialogContent, FormControl, FormControlLabel, FormHelperText, Grid, Slide, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataDigaloProps } from '../../models/data_digalo_props';
import { DialogTitle } from '../../_components/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import { Publish } from './models/publish';
import { publishActions } from './actions/publish.action';
import { User } from '../user/models/user';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export interface DialogAddressProps {
    onClose: (value: string) => void;
  }
  
  function DialogEditPublish(props: DialogAddressProps) {
    const { onClose} = props;

    const dispatch = useDispatch();
    const  openPublish  = useSelector((state: any) => state.publishs.openPublish as DataDigaloProps<Publish>);
    const  publishs  = useSelector((state: any) => state.publishs.publishs as Publish[]);
    const user = useSelector((state:any) => state.authentication.user as User);
    const [inputs, setInputs] = React.useState<Publish>(openPublish?.select);
    const [submitted, setSubmitted] = React.useState(false);
    
     useEffect(() => {
         setInputs(openPublish?.select)
         setSubmitted(false)
    }, [openPublish]);



    const handleClose = () => {
      setInputs(new Publish())
      dispatch(publishActions.setOpenModalPublishScreen({
        open : false,
        select : new Publish(),
        type : 0,
        loading: false
      }));
      setSubmitted(false)
    };
  
  
    function handleChangeCheck(e:any)  {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: e.target.checked  }));
    };
    
    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if(inputs.description  && inputs.versionID && inputs.apkName){
        if(openPublish?.type ===0){
          inputs.userID =user.userID;
          dispatch(publishActions.add(inputs));
        }
        if(openPublish?.type ===1){
          dispatch(publishActions.edit(inputs));
        }
      }
    }



  function handleChange(e:any) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  function handleCapture(e:any) {
    if((e.target??[]).length ==0) return ;
    const file  = e.target.files[0];
    inputs.file = file;
    dispatch(publishActions.uploadApk(inputs));
  }

    return (
      <Dialog  TransitionComponent={Transition} id="inventoryDialog"  fullWidth={true} maxWidth="sm" aria-labelledby="simple-dialog-title" open={openPublish?.open??false}>
        <DialogTitle onClose={handleClose}  id="simple-dialog-title" > {openPublish?.type ===0 ? "Agregar": "Editar"} Publicación </DialogTitle>
        <DialogContent dividers>
        {
          openPublish?.loading ?  <CircularProgress />:
        <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
        <Grid container spacing={3}>

        <Grid item xs={4}>
              <TextField
                    id="versionID"
                    label="Código de Versión"
                    type="text"
                    placeholder="Código de Versión"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    name="versionID"
                    disabled={ !(openPublish?.type ==0) }
                    value={inputs?.versionID}
                    error={(submitted && !inputs?.versionID)}   helperText={(submitted && !inputs?.versionID) ? "Es Requerido":""}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid> 
        <Grid item xs={8}>
              <TextField
                    id="description"
                    label="Descripción"
                    type="text"
                    placeholder="Descripción"
                    fullWidth
                    size="small"    
                    onChange={handleChange}
                    name="description"
                    value={inputs?.description}
                    error={(submitted && !inputs?.description)}   helperText={(submitted && !inputs?.description) ? "Es Requerido":""}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid> 


            <Grid item xs={12}>
                {
                inputs?.apkName?
                <Box>
                  <img width={40} height={40} src="https://upload.wikimedia.org/wikipedia/commons/4/41/APK_format_icon_%282014-2019%29.png"/>
                  <br/>
                    <Button variant="text" component="label">
                          Cambiar APK
                          <input onChange={handleCapture} type="file" accept=".apk" hidden />
                    </Button>
                </Box>
                  :
                  <Button variant="text" component="label">
                        Agregar Apk
                        <input onChange={handleCapture} type="file" accept=".apk" hidden />
                  </Button>
              }
            </Grid> 
           

            {
              openPublish.type ==1?
                <Grid item xs={12}>    
                      <FormControl required component="fieldset">
                        <FormControlLabel
                          control={
                            <Checkbox color="primary" name="active" checked={inputs?.active } onChange={handleChangeCheck}  />
                          }
                          label="Activo"
                        />
                      </FormControl>
                  </Grid>
                :<Box></Box>
            }
            </Grid>
                  <Box my={3} >
                    <Button type="submit" variant="contained" color="primary">
                    {openPublish?.type ===0 ? "Agregar": "Editar"} Publicación 
                    </Button>
                  </Box>
                  </form>        
              }
        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { DialogEditPublish };
