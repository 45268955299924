export class DayFree {
    dayFreeID?: number;
    date?: Date;
    userID?: number;
    description?: string;
    
    constructor(datos?: DayFree) {
      if (datos != null) {
        this.dayFreeID = datos.dayFreeID;
        this.date = datos.date;
        this.userID = datos.userID;
        this.description = datos.description;
        return;
      }
    }
  }
