import { Customer } from "../models/customer/customer";
import { InscriptionStateModel } from "../models/lending/inscription_state_model";
import { inscriptionConstants } from "../_constants/inscription.constants";
import { Lending } from "../models/lending/lending";
import { SuccessModal, ToastSwall } from "../_helpers/common";
import { Adviser } from "../pages/hierarchy/models/adviser";
import { DayFree } from "../models/day_free";


const initialState: InscriptionStateModel = { 
    loading: false, 
    loadingAdviser: false,
    error: "",
    items:  Array<Customer>(), 
    advisers: Array<Adviser>(),
    lending: new Lending(),
    
    dayFrees: Array<DayFree>(),
    pendientsLendings: Array<Lending>(),
    openCustomer: {
        open: false,
        select: {},
        type: 0,
        loading: false,
    },
    openRequest:{
        open: false,
        select:{},
        type:0,
        loading:false
    },
    openDayFree: {
        open:false,
        select:{},
        type: 0,
        loading: false
    }
    } ;

export function inscriptions(state = initialState, action:any) :InscriptionStateModel {
    switch (action.type) {

        case inscriptionConstants.SET_OPEN_CUSTOMER_SUCCESS:
        let customer = new Customer();
        let newLending = new Lending(state.lending);
        if(action.openCustomer.select?.customerID){
            customer = action.openCustomer.select;
            newLending.customer = customer.name;
            newLending.customerID = customer.customerID;
        }
        return {
            ...state,
            openCustomer:action.openCustomer,
            lending: newLending,
        };
      
        
        case inscriptionConstants.SET_OPEN_REQUEST_SUCCESS:

        return {
            ...state,
            openRequest:action.openRequest
        };


        case inscriptionConstants.SET_OPEN_DAY_FREE_SUCCESS:

        return {
            ...state,
            openDayFree:action.openDayFree
        };
       


        case inscriptionConstants.CALCULATE_FEE_REQUEST:
            
            return {
                ...state,
                loading: true,
            };

        case inscriptionConstants.CALCULATE_FEE_SUCCESS:
            
            return {
                ...state,
                lending: action.lending,
                loading: false
            };
        case inscriptionConstants.CALCULATE_FEE_FAILURE:
            
            return {
                ...state,
                error: action.error,
                loading: false
            };

            case inscriptionConstants.UP_IMAGE_LENDING_REQUEST:
                return {
                    ...state,
                    loading: true
                    
                };
            case inscriptionConstants.UP_IMAGE_LENDING_SUCCESS:
                ToastSwall.fire({
                    icon: 'success',
                    title: 'Imagen Agregada'
                    })
                return {
                    ...state,
                    loading: false,
                   lending: action.lending
                };
                
            case inscriptionConstants.UP_IMAGE_LENDING_FAILURE:
                return {
                    ...state,
                    error: action.error,
                    loading:false
                };



            case inscriptionConstants.UP_IMAGE_LENDING_ON_REQUEST:
                return {
                    ...state,
                    openRequest: {
                        open: true,
                        select: state.openRequest.select,
                        loading:true,
                        type: state.openRequest.type
                    }
                    
                };
            case inscriptionConstants.UP_IMAGE_LENDING_ON_SUCCESS:
                ToastSwall.fire({
                    icon: 'success',
                    title: 'Imagen Agregada'
                    })
                return {
                    ...state,
                    openRequest: {
                        open: true,
                        select: action.lending,
                        loading:false,
                        type: state.openRequest.type
                    }
                    
                };
                
            case inscriptionConstants.UP_IMAGE_LENDING_ON_FAILURE:
                return {
                    ...state,
                    error: action.error,
                    openRequest: {
                        open: true,
                        select: state.openRequest.select,
                        loading:false,
                        type: state.openRequest.type
                    }
                    
                };

        

        
        case inscriptionConstants.CALCULATE_REQUEST_REQUEST:
            
            return {
                ...state,
                openRequest: {
                    open : state.openRequest.open,
                    loading : true,
                    select : state.openRequest.select,
                    type : 1
                },
            };

        case inscriptionConstants.CALCULATE_REQUEST_SUCCESS:
            
            return {
                ...state,
                openRequest: {
                    open : state.openRequest.open,
                    loading : false,
                    select : action.lending,
                    type : 1
                },
            };
        case inscriptionConstants.CALCULATE_REQUEST_FAILURE:
            
            return {
                ...state,
                error: action.error,
                openRequest: {
                    open : state.openRequest.open,
                    loading : false,
                    select : state.openRequest.select,
                    type : 1
                },
                loading: false
            };




       
        
    
        case inscriptionConstants.EDIT_LENDING_REQUEST:
            
            return {
                ...state,
                openRequest: {
                    open : true,
                    loading : true,
                    select : state.openRequest.select,
                    type : 1
                },
            };

        case inscriptionConstants.EDIT_LENDING_SUCCESS:
            
            var currentLendins = state.pendientsLendings.map((item)=> item);
            var index = state.pendientsLendings.findIndex((item)=> {
                return item.lendingID == action.lending.lendingID
            });
            if(index!=-1){
                currentLendins[index] = action.lending;
            }
            ToastSwall.fire({
                icon: 'success',
                title: 'Solicitud Actualizada'
              })
            return {
                ...state,
                pendientsLendings : currentLendins,
                openRequest: {
                    open : true,
                    loading : false,
                    select : action.lending,
                    type : 1
                },
            };
        case inscriptionConstants.EDIT_LENDING_FAILURE:
            
            return {
                ...state,
                error: action.error,
                openRequest: {
                    open : true,
                    loading : false,
                    select : state.openRequest.select,
                    type : 1
                },
                loading: false
            };



        
    case inscriptionConstants.GET_DAY_FREE_REQUEST:
        return {
            ...state,
            loading: true
        };

    case inscriptionConstants.GET_DAY_FREE_SUCCESS:
        return {
            ...state,
            dayFrees: action.dayFrees,
            loading: false

        };
    case inscriptionConstants.GET_DAY_FREE_FAILURE:
        return {
            ...state,
            error: action.error,
            loading: false

        };




        case inscriptionConstants.ADD_DAY_FREE_REQUEST:
            
            return {
                ...state,
                openDayFree: {
                    open : true,
                    loading : true,
                    select : state.openDayFree.select,
                    type : 1
                },
            };

        case inscriptionConstants.ADD_DAY_FREE_SUCCESS:
            
            ToastSwall.fire({
                icon: 'success',
                title: 'Día Agregado'
                })
            return {
                ...state,
                dayFrees: action.dayFrees,
                openDayFree: {
                    open : false,
                    loading : false,
                    select : {},
                    type : 1
                },
            };
        case inscriptionConstants.ADD_DAY_FREE_FAILURE:
            
            return {
                ...state,
                error: action.error,
                openDayFree: {
                    open : true,
                    loading : false,
                    select : state.openDayFree.select,
                    type : 1
                },
                loading: false
            };


        

        case inscriptionConstants.POSTINGT_LENDING_REQUEST:
            
            return {
                ...state,
                openRequest: {
                    open : true,
                    loading : true,
                    select : state.openRequest.select,
                    type : 1
                },
            };
    
        case inscriptionConstants.POSTING_LENDING_SUCCESS:
            
            ToastSwall.fire({
                icon: 'success',
                title: 'Solicitud Autorizada'
                })
            return {
                ...state,
                pendientsLendings : action.lendings,
                openRequest: {
                    open : false,
                    loading : false,
                    select : {},
                    type : 1
                },
            };
        case inscriptionConstants.POSTING_LENDING_FAILURE:
            
            return {
                ...state,
                error: action.error,
                openRequest: {
                    open : true,
                    loading : false,
                    select : state.openRequest.select,
                    type : 1
                },
                loading: false
            };
        
        



        case inscriptionConstants.GET_ADVISER_REQUEST:
            
            return {
                ...state,
                loadingAdviser: true,
            };

        case inscriptionConstants.GET_ADVISER_SUCCESS:
            
            return {
                ...state,
                advisers: action.advisers,
                loadingAdviser: false
            };
        case inscriptionConstants.GET_ADVISER_FAILURE:
            
            return {
                ...state,
                error: action.error,
                loadingAdviser: false
            };



            
        case inscriptionConstants.ADD_LENDING_REQUEST:
            
            return {
                ...state,
                loading: true,
            };

        case inscriptionConstants.ADD_LENDING_SUCCESS:
            
            SuccessModal(`Solicitud creada con número: ${action.lending.lendingID}`)

            return {
                ...state,
                lending: new Lending(),
                loading: false
            };
        case inscriptionConstants.ADD_LENDING_FAILURE:
            
            return {
                ...state,
                error: action.error,
                loading: false
            };




        case inscriptionConstants.PENDIENT_LENDING_REQUEST:
            
            return {
                ...state,
                pendientsLendings: [],
                loading: true,
            };

        case inscriptionConstants.PENDIENT_LENDING_SUCCESS:
            
            return {
                ...state,
                pendientsLendings: action.lendings,
                loading: false
            };
        case inscriptionConstants.PENDIENT_LENDING_FAILURE:
            
            return {
                ...state,
                error: action.error,
                loading: false
            };

        default:
            return state
    }
}