import { ToastSwall } from "../../../_helpers/common";
import { hierarchyConstants } from "../constants/hierarchy.constants";
import { HierarchyStateModel } from "../models/hierarchy_state_model";

const initialState: HierarchyStateModel = { 
    loading: true, 
    error: "",
    hierarchies: [],
    // users:  Array<User>(), 
    // roles: Array<Role>(),
    // permissions: Array<Permission>(),
    openAdviser: {
        open: false,
        select: {},
        type: 0,
        loading: false,
    },
    openSupervisor: {
        open: false,
        select: {},
        type: 0,
        loading: false,
    },

    } ;

export function hierarchy(state = initialState, action:any) :HierarchyStateModel {
    switch (action.type) {

        case hierarchyConstants.SET_OPEN_ADVISER_SUCCESS:
            return {
                ...state,
                openAdviser:action.openAdviser
            };

        case hierarchyConstants.SET_OPEN_SUPERVISOR_SUCCESS:
            return {
                ...state,
                openSupervisor:action.openSupervisor
            };

        case hierarchyConstants.GETALL_REQUEST:     
            return {
                ...state,
                loading: true,
                hierarchies: [],
            };
        case hierarchyConstants.GETALL_SUCCESS:
            return {
                ...state,
                loading: false,
                hierarchies: action.hierarchies,
            };
        case hierarchyConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        

  

        case hierarchyConstants.ADD_SUPERVISOR_REQUEST:
            return {
                ...state,
                openSupervisor:{
                    open: true,
                    select:state.openSupervisor.select,
                    type:0,
                    loading: true
                },
            };

        case hierarchyConstants.ADD_SUPERVISOR_SUCCESS:
            ToastSwall.fire({
                icon: 'success',
                title: 'Supervisor Agregado'
              })
            return {
                ...state,
                hierarchies: action.hierarchies,
                openSupervisor:{
                    open: false,
                    select : state.openSupervisor.select,
                    type: 1,
                    loading:false
                },
            };
        case hierarchyConstants.ADD_SUPERVISOR_FAILURE:
            return {
                ...state,
                openSupervisor:{
                    open: true,
                    select:state.openSupervisor.select,
                    type:0,
                    loading: false
                },
                error: action.error,
                hierarchies: state.hierarchies,
            };


            
        case hierarchyConstants.EDIT_SUPERVISOR_REQUEST:
            return {
                ...state,
                openSupervisor:{
                    open: true,
                    select:state.openSupervisor.select,
                    type:1,
                    loading: true
                },
            };

        case hierarchyConstants.EDIT_SUPERVISOR_SUCCESS:
            ToastSwall.fire({
                icon: 'success',
                title: 'Supervisor Editado'
              })
            //newList.push(action.users);
            return {
                ...state,
                hierarchies: action.hierarchies,
                openSupervisor:{
                    open: false,
                    select : state.openSupervisor.select,
                    type: 1,
                    loading:false
                },
            };
        case hierarchyConstants.EDIT_SUPERVISOR_FAILURE:
            return {
                ...state,
                openSupervisor:{
                    open: true,
                    select:state.openSupervisor.select,
                    type:1,
                    loading: false
                },
                error: action.error,
                hierarchies: state.hierarchies,
            };



            case hierarchyConstants.ADD_ADVISER_REQUEST:
                return {
                    ...state,
                    openAdviser:{
                        open: true,
                        select:state.openAdviser.select,
                        type:0,
                        loading: true
                    },
                };
    
            case hierarchyConstants.ADD_ADVISER_SUCCESS:
                ToastSwall.fire({
                    icon: 'success',
                    title: 'Asesor Agregado'
                  })
                return {
                    ...state,
                    hierarchies: action.hierarchies,
                    openAdviser:{
                        open: false,
                        select : state.openAdviser.select,
                        type: 1,
                        loading:false
                    },
                };
            case hierarchyConstants.ADD_ADVISER_FAILURE:
                return {
                    ...state,
                    openAdviser:{
                        open: true,
                        select:state.openAdviser.select,
                        type:0,
                        loading: false
                    },
                    error: action.error,
                    hierarchies: state.hierarchies,
                };
    
    
                
            case hierarchyConstants.EDIT_ADVISER_REQUEST:
                return {
                    ...state,
                    openAdviser:{
                        open: true,
                        select:state.openAdviser.select,
                        type:1,
                        loading: true
                    },
                };
    
            case hierarchyConstants.EDIT_ADVISER_SUCCESS:
                ToastSwall.fire({
                    icon: 'success',
                    title: 'Asesor Editado'
                  })
                return {
                    ...state,
                    hierarchies: action.hierarchies,
                    openAdviser:{
                        open: false,
                        select : state.openAdviser.select,
                        type: 1,
                        loading:false
                    },
                };
            case hierarchyConstants.EDIT_ADVISER_FAILURE:
                return {
                    ...state,
                    openAdviser:{
                        open: true,
                        select:state.openAdviser.select,
                        type:1,
                        loading: false
                    },
                    error: action.error,
                    hierarchies: state.hierarchies,
                };
    



        default:
            return state
    }
}