import { Box, CircularProgress,  Grid,  ImageList, ImageListItem} from '@mui/material';
import { useDispatch } from 'react-redux';
import ImageViewer from "react-simple-image-viewer";
import environment from '../environment';
import { bytesToSize } from '../_helpers/common';
import { alertActions } from '../_actions/alert.actions';
import { AddAPhoto } from '@mui/icons-material';
import React from 'react';


  function ImagePicker(props: any) {
    const { onImageAdd, loading, images} = props;
    const dispatch = useDispatch();
    const [currentImage, setCurrentImage] = React.useState(0);
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);



    const openImageViewer = React.useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);

      const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };

      function handleChangeImage(e: any){
        let uploadedImg = e.target.files[0];    
        const ACCEPTED_UPLOAD_SIZE = 1024; // 1MB
        if ( bytesToSize(uploadedImg.size) > ACCEPTED_UPLOAD_SIZE
        ) 
        {
          dispatch(alertActions.error("La imagen es demasiado grande"));
          return;
        }
        onImageAdd(uploadedImg);
      }


  
    return (
        <Grid item xs={12}>
        {
               loading ?  <CircularProgress />:
                        (images ?? []) .length > 0 ? 
                        <Box>
                            {
                                <div >
                                <ImageList  cols={4} rowHeight={100}>
                                {(images ??[]).map((item: any, index: number) => (
                                    <ImageListItem key={item.imageUri}>
                                    <img onClick={() => openImageViewer(index)} src={`${environment.apiUri}/common/image/${item.imageUri}`}  alt={item.id?.toString()} />
                                    
                                    </ImageListItem>
                                ))}
                                </ImageList>
                                </div>
                            }

                            {isViewerOpen && (
                                    <ImageViewer
                                    src={ (images ??[]).map((item:any)=> {
                                        return `${environment.apiUri}/common/image/${item.imageUri}`;
                                    }) }
                                    currentIndex={ currentImage }
                                    disableScroll={ false }
                                    closeOnClickOutside={ true }
                                    onClose={ closeImageViewer }
                                    />
                                )}
                            <Box my={2}>
                            <label className="custom-file-upload">
                            <input
                                type="file"
                                name="image"
                                placeholder="Seleccione un archivo"
                                
                                
                                onChange={handleChangeImage}
                                accept="image/jpeg"
                                />
                                <AddAPhoto fontSize="large" />
                            </label>
                            </Box>
                        </Box>
                        :
                        

                            <Box my={2}>
                            <label className="custom-file-upload">
                            <input type="file" name="image" placeholder="Seleccione un archivo" onChange={handleChangeImage} accept="image/jpeg"/>
                                <AddAPhoto fontSize="large" />
                            </label>
                            </Box>
                        }
                </Grid>
    );
  }
  
  
  export { ImagePicker };