import MaskedInput from "react-text-mask";
import Swal from 'sweetalert2'

interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
  }
// export function TextMaskCustom(props: TextMaskCustomProps) {
//     const { inputRef, ...other } = props;
  
//     return (
//       <MaskedInput
//         {...other}
//         // ref={(ref: any) => {
//         //   inputRef(ref ? ref.inputElement : null);
//         // }}
//         mask={[/[0-9]/, /\d/, /\d/, /\d/,  '-',/[0-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
//         placeholderChar={'\u2000'}
//         showMask
//       />
//     );
//   }
  
//   export  function TextMaskPhone(props: TextMaskCustomProps) {
//     const { inputRef, ...other } = props;
  
//     return (
//       <MaskedInput
//         {...other}
//         // ref={(ref: any) => {
//         //   inputRef(ref ? ref.inputElement : null);
//         // }}
//         mask={[/[0-9]/, /\d/, /\d/,/\d/, '-',/[0-9]/, /\d/, /\d/,/\d/]}
//         placeholderChar={'\u2000'}
//         showMask
//       />
//     );
//   }


  export const ToastSwall = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    customClass:{
        container:'zIndex'
    },
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  export function  SuccessModal(message: string ){
    Swal.fire({
      icon: 'success',
      title: message,
      showConfirmButton: true,
      confirmButtonText:"Listo"
    })
  }

    
  export function bytesToSize(bytes: any) {
    // var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 0;
    // var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / 1024);
  }