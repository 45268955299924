import { GuaranteeImage } from "./guarantee_image";

export class Guarantee {
    guaranteeID?: number;
    customerID?: string;
    description?: string;
    brand?: string;
    serie?: number;
    contractUri?: string;
    cost?: number;
    images?: GuaranteeImage[]

    constructor(datos?: Guarantee) {
      if (datos != null) {
        this.guaranteeID = datos.guaranteeID;
        this.customerID = datos.customerID;
        this.description = datos.description;
        this.brand = datos.brand;
        this.serie = datos.serie;
        this.contractUri = datos.contractUri;
        this.cost = datos.cost;
        this.images = datos.images;
        return;
      }
       
    }
  }
