import { useDispatch, useSelector } from 'react-redux';
import { Container , CircularProgress, Box, TextField, Grid, Button, InputAdornment, Typography, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, FormHelperText, Card, IconButton} from '@mui/material';
import React from 'react';
import * as Excel from "exceljs";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { reportActions } from './actions/report.action';
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { format, toEsDate } from '../../_helpers/format';
import { FinancialState } from './models/financial_state';
import { FinancialStateDetail } from './models/financial_state_detail';

function FinancialStateHistoricPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.report.loading);
  const financialState: FinancialState = useSelector((state: any) => state.report.financialState );
  const alert = useSelector((state: any) => state.alert.message);
  const [submitted, setSubmitted] = React.useState(false);
    const [inputs, setInputs] = React.useState({
        fromDate:"",
        toDate:""
    });

    // useEffect(() => {
    //   setInputs(setting)
    //   setSubmitted(false)
    // }, [setting]);

    // useEffect(() => {
    //   dispatch(settingActions.getAll());
    //   setSubmitted(false)
    // }, []);
    
    function handleChange(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
        dispatch(reportActions.getFinancialStateByDate(inputs.fromDate, inputs.toDate));
    }


    const columns: GridColDef[] = [
       {
         field: 'documentNo',
         headerName: 'No Documento',
         width: 130,
         align:'center'
       },
      {
        field: 'documentType',
        headerName: 'Tipo',
        width: 90,
      },
       {
         field: 'createdDate',
         headerName: 'Fecha',
         width: 100,
         valueFormatter: (params) => {
          
            if(params?.value){
              var da = params?.value as string;
              return toEsDate(da);
            } 
            else{
              return "X";
            }
         },
       },
       {
         field: 'expireDate',
         headerName: 'Fecha Vencimiento',
         width: 110,
         valueFormatter: (params) => {
            let item =  (financialState?.detail??[]).find((x)=> x.documentNo == params.id);

            if(params?.value){

              var da = params?.value as string;
              if((item?.documentType??"") == "Prestamo"){
                return toEsDate(da);
              }
              return "NO APLICA";
            } 
            else{
              return "X";
            }
         },
       },
 
       {
         field: 'expireDays',
         headerName: 'Días Vencido',
         width: 120,
        renderCell: (params) => {
          let result = ((params.row?.documentType??"") == "Prestamo") ? params.row.expireDays : 0;
          result = result == 0 ? "": result;
          return (
            <>
              <span style={{color: params.row.status== "VENCIDO"? "red" : "black"  }}>{result} {params.row.status} </span>

            </>
          );
        },
       },
       {
        field: 'referent',
        headerName: 'Referencia',
        width: 210,
      },
       {
        field: 'amount',
        headerName: 'Monto',
        width: 120,
        valueFormatter: (params) => {
          if(params?.value!= undefined){
            return "L. "+ format((params?.value as number)??0, 2);
          } 
          else{
            return "-1";
          }
       },
   
      },
      // {
      //   field: 'amountAfterInterestReal',
      //   headerName: 'TL+INT Neto',
      //   width: 170,
      //   valueFormatter: (params) => {
      //     if(params?.value!= undefined){
      //       return "L. "+ format((params?.value as number)??0, 2);
      //     } 
      //     else{
      //       return "-1";
      //     }
      //  },
      // },
      {
        field: 'amountAfterInterest',
        headerName: 'TL+INT Generado',
        width: 140,
        valueFormatter: (params) => {
          if(params?.value!= undefined){
            return "L. "+ format((params?.value as number)??0, 2);
          } 
          else{
            return "-1";
          }
       },
      },
      {
        field: 'amountReceive',
        headerName: 'Cobrado',
        width: 140,
        valueFormatter: (params) => {
          if(params?.value!= undefined){
            return "L. "+ format((params?.value as number)??0, 2);
          } 
          else{
            return "-1";
          }
       },
      },
      {
        field: 'amountFine',
        headerName: 'Multas',
        width: 170,
        valueFormatter: (params) => {
          if(params?.value!= undefined){
            return "L. "+ format((params?.value as number)??0, 2);
          } 
          else{
            return "-1";
          }
       },
      },
   
      
      
     ];
     

     function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <Button style={{color:"green"}} onClick={downloadExcel}   startIcon={<ImportExportIcon />} >Descargar Excel</Button>
        </GridToolbarContainer>
      );
    }


    async function downloadExcel(e:any) {
      e.preventDefault();
      const workbook = new Excel.Workbook();
      workbook.creator = 'SISTEMA RAPICREDIT';
      workbook.created = new Date();
      workbook.modified = new Date();
      workbook.lastPrinted = new Date();
      const worksheet = workbook.addWorksheet('Estado de Cuenta', {properties:{tabColor:{argb:'FFC0000'}}});
      worksheet.autoFilter = 'A1:H1';
      worksheet.columns = [
        { header: 'No Documento', key: 'documentNo', width: 30, },
        { header: 'Fecha', key: 'createdDate', width: 30 },
        { header: 'Tipo', key: 'documentType', width: 30,},// outlineLevel: 1 
        { header: 'Referencia', key: 'referent', width: 30,},
        { header: 'Monto', key: 'amount', width: 30,},
        { header: 'TL+INT', key: 'amountAfterInterest', width: 30,},
        { header: 'Cobrado', key: 'amountReceive', width: 30,},
        { header: 'Multas', key: 'amountFine', width: 30,},
      ];
        
        (financialState?.detail??[]).forEach((item: FinancialStateDetail )=> {
          worksheet.addRow(item);
        });
       const buffer = await workbook.xlsx.writeBuffer();
       var blob = new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = "EstadoCuentaPorFecha-" + new Date().toISOString().substr(0,10);
        link.download = fileName;
        link.click();
    }

   
    return (
        <Box my={2}>
            <Container maxWidth="xl" >
              <Card  variant="outlined" style={{padding:"5px 20px 25px 20px"}} >

              {alert && <Box my={2} >{alert}</Box>}
              {
                loading? 
                <Box my={2}>
                    <CircularProgress title="Cargando..." />
                </Box>
                :
                <Container maxWidth="xl">
                    
                    <Box my={3} >
                      <Typography variant="h5" gutterBottom component="div">
                        Estado Cuenta
                      </Typography>
                    </Box>
                    <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <TextField
                                  id="fromDate"
                                  label="Desde"
                                  type="date"
                                  placeholder="Desde"
                                  fullWidth
                                  size="small"
                                  onChange={handleChange}
                                  name="fromDate"
                                  value={inputs?.fromDate}
                                  error={(submitted && !inputs?.fromDate)}   helperText={(submitted && !inputs?.fromDate) ? "Es Requerido":""}
                                  margin="normal"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <DateRangeIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                          </Grid> 
                          <Grid item xs={3}>
                                <TextField
                                      id="toDate"
                                      label="Hasta"
                                      type="date"
                                      placeholder="Hasta"
                                      fullWidth
                                      size="small"    
                                      onChange={handleChange}
                                      name="toDate"
                                      value={inputs?.toDate}
                                      error={(submitted && !inputs?.toDate)}   helperText={(submitted && !inputs?.toDate) ? "Es Requerido":""}
                                      margin="normal"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <DateRangeIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                            </Grid> 
                 

                            <Grid item xs={3}>
                                <Box my={2} >
                                  <Button type="submit" variant="contained" color="primary">
                                      Buscar 
                                  </Button>
                                </Box>
                            </Grid> 
                          
                      </Grid>
                    </form>  

                      { (financialState?.detail??[]).length > 0 ?
                          <React.Fragment>
                              <Grid container spacing={3} my={2}>

                                      <Grid item xs={4}>
                                          <div style={{padding:"20px", border:"1px solid #c1c1c1"}}>
                                            <h4>Utilidad Neta</h4>
                                            <p>L {format(financialState?.amountNet??0, 2)}</p>
                                          </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                          <div style={{padding:"20px", border:"1px solid #c1c1c1"}}>
                                            <h4>Gastos</h4>
                                            <p>L {format(financialState?.spent??0, 2)}</p>
                                          </div>
                                      </Grid>
                                      {/* <Grid item xs={4}>
                                        <div style={{padding:"20px", border:"1px solid #c1c1c1"}}>
                                            <h4>TL+INT Neto</h4>
                                            <p>L {format(financialState?.amountAfterInterestReal??0, 2)}</p>
                                          </div>
                                      </Grid> */}
                                      <Grid item xs={4}>
                                        <div style={{padding:"20px", border:"1px solid #c1c1c1"}}>
                                            <h4>TL+INT Generado</h4>
                                            <p>L {format(financialState?.amountAfterInterest??0, 2)}</p>
                                          </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div style={{padding:"20px", border:"1px solid #c1c1c1"}}>
                                            <h4>Colocado</h4>
                                            <p>L {format(financialState?.amountLending??0, 2)}</p>
                                          </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div style={{padding:"20px", border:"1px solid #c1c1c1"}}>
                                            <h4>Ingreso por Multas</h4>
                                            <p>L {format(financialState?.amountFine??0, 2)}</p>
                                          </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div style={{padding:"20px", border:"1px solid #c1c1c1"}}>
                                            <h4>Cobrado</h4>
                                            <p>L {format(financialState?.amountReceive??0, 2)}</p>
                                          </div>
                                      </Grid>
                                      
                                    </Grid>


                                <Grid container spacing={3} >
                                        <Grid item xs={12} >
                                    
                                          <DataGrid autoHeight 
                                              rows={financialState?.detail??[]}
                                              getRowId={(r) => r.documentNo}
                                              columns={columns}
                                              components={{
                                                Toolbar:CustomToolbar
                                              }}      

                                              pageSize={10}
                                              rowsPerPageOptions={[5]}
                                              nonce="No se encontraron resultados"
                                              density="compact"
                                              checkboxSelection={false}
                                              // onCellClick={(params, event) => {
                                              //     console.log(params.row)
                                              //     handleClickOpen(params.row as User, 1)
                                              //   }}
                                              
                                              disableSelectionOnClick
                                          />
                                        </Grid>
                                      </Grid>

                          </React.Fragment>
                          :<div></div>
                      }
                    
                        

                </Container>
              }  
            </Card>
            </Container>
        </Box>
    );
}

export { FinancialStateHistoricPage };
