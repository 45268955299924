import Dialog from '@mui/material/Dialog';
import { Box, Button, Checkbox, CircularProgress, DialogContent, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, ListItemText, MenuItem, Select, Slide, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataDigaloProps } from '../../models/data_digalo_props';
import { DialogTitle } from '../../_components/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import { User } from '../user/models/user';
import { Spent } from './models/spent';
import { spentActions } from './actions/spent.action';
import { SpentType } from './models/spent_type';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export interface DialogAddressProps {
    onClose: (value: string) => void;
  }
  
  function DialogEditSpentType(props: DialogAddressProps) {
    const { onClose} = props;
    const dispatch = useDispatch();
    const loading = useSelector((state: any) => state.spent.loading);
    const openSpentType  = useSelector((state: any) => state.spent.openSpentType as DataDigaloProps<SpentType>);
    const user = useSelector((state:any) => state.authentication.user as User);
    const [inputs, setInputs] = React.useState<SpentType>(openSpentType?.select);
    const [submitted, setSubmitted] = React.useState(false);
    
     useEffect(() => {
         setInputs(openSpentType?.select)
         setSubmitted(false)
    }, [openSpentType]);


    const handleClose = () => {
      setInputs(new Spent())
      dispatch(spentActions.setOpenModalSpentTypeScreen({
        open : false,
        select : new Spent(),
        type : 0,
        loading: false
      }));
      setSubmitted(false)
    };
  
  

    function handleChangeCheck(e:any)  {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: e.target.checked  }));
    };


    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      //TODO 
      if(inputs.description ){
        if(openSpentType?.type ===0){
          inputs.createBy =user.userID;
          dispatch(spentActions.addType(inputs));
        }
        if(openSpentType?.type ===1){
          dispatch(spentActions.editType(inputs));
        }
      }
    }

 

  function handleChange(e:any) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  

    return (
      <Dialog  TransitionComponent={Transition} id="inventoryDialog"  fullWidth={true} maxWidth="xs" aria-labelledby="simple-dialog-title" open={openSpentType?.open??false}>
        <DialogTitle onClose={handleClose}  id="simple-dialog-title" > {openSpentType?.type ===0 ? "Agregar": "Editar"} Tipo </DialogTitle>
        <DialogContent dividers>
        {
          openSpentType?.loading ?  <CircularProgress />:
        <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
        <Grid container spacing={3}>

        {/* <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
            <Box  >
                    <Button type="submit" variant="contained" color="primary" onClick={(e)=>{
                    handleSubmit(e);
                    }}>
                    {openSpentType?.type ===0 ? "Agregar": "Editar"} Gasto 
                    </Button>
                  </Box>
          </Grid> */}


{/* 
        <Grid item xs={3}>
              <TextField
                    id="spentId"
                    label="No"
                    type="text"
                    placeholder="No"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    name="spentId"
                    disabled={ true }
                    value={inputs?.spentTypeId}
                   
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>  */}
        <Grid item xs={8}>
              <TextField
                    id="description"
                    label="Descripción"
                    type="text"
                    placeholder="Descripción"
                    fullWidth
                    size="small"    
                    onChange={handleChange}
                    name="description"
                    value={inputs?.description}
                    error={(submitted && !inputs?.description)}   helperText={(submitted && !inputs?.description) ? "Es Requerido":""}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid> 
            {
              openSpentType.type ==1?
                <Grid item xs={12}>    
                      <FormControl required component="fieldset">
                        <FormControlLabel
                          control={
                            <Checkbox color="primary" name="active" checked={inputs?.active } onChange={handleChangeCheck}  />
                          }
                          label="Activo"
                        />
                      
                      </FormControl>
                  </Grid>
                :<Box></Box>
            }
            </Grid>
                  <Box my={3} >
                    <Button type="submit" variant="contained" color="primary">
                    {openSpentType?.type ===0 ? "Agregar": "Editar"} Tipo 
                    </Button>
                  </Box>
                

           
                  </form>        
              }
        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { DialogEditSpentType };
