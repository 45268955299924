import axios from 'axios';
import environment from '../environment';

export const userService = {
    login,
    logout,
};


 function login(username:string, password:string) {
    return axios.post(environment.apiUri + "/auth", { userName: username, password: password, })
  };
  

function logout() {
    localStorage.removeItem('user');
}
