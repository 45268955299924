import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container , CircularProgress, Grid, Box, Button} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { Publish } from './models/publish';
import { publishActions } from './actions/publish.action';
import { DialogEditPublish } from './dialog.edit.publish';

function PublishPage() {
    const dispatch = useDispatch();
    const  loading  = useSelector((state: any) => state.publishs.loading);
    const  publishs  = useSelector((state: any) => state.publishs.publishs as Publish[]);
    const  alert  = useSelector((state: any) => state.alert.message);
    const [submitted, setSubmitted] = React.useState(false);

    const handleClickOpen = (row: Publish, type: number) => {
      dispatch(publishActions.setOpenModalPublishScreen(
        {
        open : true,
        select :row,
        type : type,
        loading:false
      }));
    };

    useEffect(() => {
      if((publishs??[]).length ==0){
        dispatch(publishActions.getAll());
      }
    }, []);
    
    
    const handleClose = (value: string) => {
      // dispatch(inventoryActions.setInventoryScreen({
      //   open : false,
      //   select :new Inventory(),
      //   type : 0,
      //   loading:false
      // }));
    };

    const columns: GridColDef[] = [
     // { field: 'inventoryID', headerName: 'ID', width: 130 },
      {
        field: 'versionID',
        headerName: 'No versión',
        width: 110,
        align:'center'
      },
      {
        field:'createDate',
        headerName:'Fecha',
        width: 140,
        valueFormatter: (params) => {
          if(params?.value){
            return new Date(params?.value as string).toLocaleDateString().substr(0,10)
          } 
       },
      },
      {
        field: 'description',
        headerName: 'Descripción',
        width: 210,
      },
      {
        field: 'active',
        headerName: 'Activo',
        width: 140,
        valueFormatter: (params) => {
           if(params?.value){
             return "Si"
           } 
           else{
             return "No";
           }
        },
      },
      {
        field:'edit',
        headerName:'Editar',
        width:130,
        renderCell: (params: GridRenderCellParams) => (
          <Button color="primary" size="small"onClick={()=>{
              handleClickOpen(params.row as Publish, 1)
              }
            }>
          Editar
          </Button>
        )
      },
    ];
  


    return (
        <Box my={2}>
            <Container  >
            <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
                    <Box my={2} >
                        <Button variant="contained"  color="primary" type="submit" onClick={()=>{
                                var publish = new Publish();
                                handleClickOpen( publish, 0);
                                }
                                }>
                              Agregar
                          </Button>
                      </Box>
              </Grid>

              
                {alert && <Box my={2} >{alert}</Box>}
                    {
                        loading?  
                        <Box my={2}>
                           <CircularProgress title="Cargando..." />
                        </Box>
                        :<Grid container spacing={3}>
                            <Grid item xs={12} >
                        
                              <DataGrid autoHeight 
                                  rows={publishs}
                                  getRowId={(r) => r.versionID}
                                  columns={columns}
                                  pageSize={100}
                                  rowsPerPageOptions={[5]}
                                  nonce="No se encontraron resultados"
                                  density="compact"
                                  checkboxSelection={false}
                                  // onCellClick={(params, event) => {
                                  //     console.log(params.row)
                                  //     handleClickOpen(params.row as User, 1)
                                  //   }}
                                  
                                  disableSelectionOnClick
                              />
                            </Grid>
                          </Grid>
                    }
             <DialogEditPublish onClose={handleClose} /> 
            </Container>
        </Box>
    );
}

export { PublishPage };
