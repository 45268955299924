import MuiDialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, DialogContent, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customerActions } from '../../../_actions/customer.actions';
import { PersonalReferent } from '../../../models/customer/personal_referent';

import { DataDigaloProps } from '../../../models/data_digalo_props';


export interface DialogTitleProps{
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = ((props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle   {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close"  onClick={onClose}
            sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}

>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});




export interface DialogAddressProps {
    onClose: (value: string) => void;
  }
  
  function DialogReferent(props: DialogAddressProps) {
    const { onClose} = props;
    const dispatch = useDispatch();
    
    const  openReferent  = useSelector((state: any) => state.customers.openReferent as DataDigaloProps<PersonalReferent>);
    const [inputs, setInputs] = React.useState<PersonalReferent>(openReferent?.select);
   
    // const { active, address, city,  contact,latitude, longitude,name,phone} = inputs;
     const [submitted, setSubmitted] = React.useState(false);
     useEffect(() => {
         setInputs(openReferent?.select)
         setSubmitted(false)
    }, [openReferent]);



    
    const handleClose = () => {
      setInputs(new PersonalReferent())
      dispatch(customerActions.setOpenReferentScreen({
        open : false,
        select : new PersonalReferent(),
        type : 0,
        loading: false
      }));
      onClose("Hola");
      setSubmitted(false)
    };
  

    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if (inputs.name && inputs.address &&  inputs.phone && inputs.relation) {
        inputs.customerID = openReferent?.select?.customerID;
          if(openReferent?.type ===0){

            dispatch(customerActions.addReferent(inputs));
          }
          if(openReferent?.type ===1){
            inputs.referentID = openReferent?.select?.referentID;
            dispatch(customerActions.editReferent(inputs));
          }
      }
    }

    // const handleListItemClick = (value: string) => {
    //   onClose(value);
    // };
    // const handleChangeActive = (e:any,value: boolean) => {
    //   setInputs(inputs => ({ ...inputs, active: value }));
    // };

    function handleChange(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  
    return (
      <Dialog  id="customerAddressDialog"  fullWidth={true} maxWidth="sm" aria-labelledby="simple-dialog-title" open={openReferent?.open??false}>
        <DialogTitle onClose={handleClose}  id="simple-dialog-title" > {openReferent?.type ===0 ? "Agregar": "Editar"} Referencia </DialogTitle>
        <DialogContent dividers>
        {
          openReferent?.loading ?  <CircularProgress />:
   
        <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
        <Grid container spacing={3}>

   
            

            <Grid item xs={7}>
              <TextField
                    id="name"
                    label="Nombre"
                    type="text"
                    placeholder="Nombre"
                    fullWidth
                    name="name"
                    value={inputs?.name}
                    error={(submitted && !inputs?.name)}   helperText={(submitted && !inputs?.name) ? "Es Requerido":""}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>

            <Grid item xs={5}>
              <TextField
                    id="phone"
                    label="Teléfono"
                    type="text"
                    placeholder="Teléfono"
                    fullWidth
                    name="phone"
                    value={inputs?.phone}
                    error={(submitted && !inputs?.phone)}   helperText={(submitted && !inputs?.phone) ? "Es Requerido":""}
                    onChange={handleChange}
                    // InputProps={{
                    //   inputComponent: TextMaskPhone as any,
                    // }}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>
            <Grid item xs={12}>    
                  <TextField
                    id="address"
                    label="Dirección"
                    type="text"
                    fullWidth
                    name="address"
                    placeholder="Dirección"
                    value={inputs?.address}
                    error={(submitted && !inputs?.address)}   helperText={(submitted && !inputs?.address) ? "Es Requerido":""}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>
  
            {/* <Grid item xs={6}>
                <TextField
                    id="dni"
                    label="Identidad"
                    type="text"
                    fullWidth
                    name="dni"
                    placeholder="Identidad"
                    error={(submitted && !inputs?.dni)}   helperText={(submitted && !inputs?.dni) ? "Es Requerido":""}
                    value={inputs?.dni}
                    onChange={handleChange}
                    margin="normal"
                    // InputProps={{
                    //   inputComponent: TextMaskCustom as any,
                    // }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid> */}

            <Grid item xs={12}>
            <TextField
                    id="relation"
                    label="Parentesco"
                    type="text"
                    fullWidth
                    name="relation"
                    placeholder="Parentesco"
                    error={(submitted && !inputs?.relation)}   helperText={(submitted && !inputs?.relation) ? "Es Requerido":""}
                    value={inputs?.relation}
                    onChange={handleChange}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>
          

           


          
          </Grid>
            <Box my={3} >
              <Button type="submit" variant="contained" color="primary">
              {openReferent?.type ===0 ? "Guardar": "Editar"} Referencia 
              </Button>
            </Box>
            </form>

             
}
         
       
        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { DialogReferent };