export class Publish {
    versionID?: string;
    description?: string;
    createDate?: Date;
    userID?: number;
    apkName?: string;
    active?: boolean;
    file? : File;
    
    constructor(datos?: Publish) {
      if (datos != null) {
        this.versionID = datos.versionID;
        this.description = datos.description;
        this.createDate = datos.createDate;
        this.userID = datos.userID;
        this.apkName = datos.apkName;
        this.active = datos.active;
        this.file = datos.file;
        return;
      }
    }
  }
