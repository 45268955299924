import { ToastSwall } from "../../../_helpers/common";
import { spentConstants } from "../constants/spent.constants";
import { Spent } from "../models/spent";
import { SpentStateModel } from "../models/spent_state_model";
import { SpentType } from "../models/spent_type";


const initialState: SpentStateModel = { 
    loading: false, 
    error: "",
    spents:  Array<Spent>(),
    spentTypes:  Array<SpentType>(),
    openSpent: {
        open: false,
        select: {},
        type: 0,
        loading: false,
    },
    openSpentType: {
        open: false,
        select: {},
        type: 0,
        loading: false,
    },
} ;

export function spent(state = initialState, action:any) :SpentStateModel {
    switch (action.type) {

        case spentConstants.SET_OPEN_SPENT_SUCCESS:
            return {
                ...state,
                openSpent:action.openSpent
            };

        case spentConstants.SET_OPEN_SPENT_TYPE_SUCCESS:
            return {
                ...state,
                openSpentType:action.openSpentType
            };

        case spentConstants.GET_SPENTS_BY_DATE_REQUEST:     
            return {
                ...state,
                loading: true,
                spents: [],
            };
        case spentConstants.GET_SPENTS_BY_DATE_SUCCESS:
            return {
                ...state,
                loading: false,
                spents: action.spents
            };
        case spentConstants.GET_SPENTS_BY_DATE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        
        case spentConstants.GET_SPENT_TYPE_REQUEST:  
            return {
                ...state,
                loading: true,
                spentTypes: [],
            };
        case spentConstants.GET_SPENT_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                spentTypes: action.spentTypes
            };
        case spentConstants.GET_SPENT_TYPE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
               
            };

        case spentConstants.ADD_SPENT_REQUEST:
            return {
                ...state,
                openSpent:{
                    open: true,
                    select:state.openSpent.select,
                    type:0,
                    loading: true
                },
        };


        case spentConstants.ADD_SPENT_SUCCESS:
            ToastSwall.fire({
                icon: 'success',
                title: 'Gasto Agregado'
                })
            return {
                ...state,
                spents: action.spents,
                openSpent:{
                    open: false,
                    select : state.openSpent.select,
                    type: 0,
                    loading:false
                },
            };
        case spentConstants.ADD_SPENT_FAILURE:
            return {
                ...state,
                openSpent:{
                    open: true,
                    select:action.spent,
                    type:0,
                    loading: false
                },
                error: action.error,
            };

        case spentConstants.EDIT_SPENT_REQUEST:
            return {
                ...state,
                openSpent:{
                    open: true,
                    select:state.openSpent.select,
                    type:1,
                    loading: true
                },
            };

        case spentConstants.EDIT_SPENT_SUCCESS:
            ToastSwall.fire({
                icon: 'success',
                title: 'Gasto Editado'
                })
            return {
                ...state,
                spents: action.spents,
                openSpent:{
                    open: false,
                    select : state.openSpent.select,
                    type: 1,
                    loading:false
                },
            };
        case spentConstants.EDIT_SPENT_FAILURE:
            return {
                ...state,
                openSpent:{
                    open: true,
                    select:action.spent,
                    type:1,
                    loading: false
                },
                error: action.error,
            };

            case spentConstants.ADD_SPENT_TYPE_REQUEST:
                return {
                    ...state,
                    openSpentType:{
                        open: true,
                        select:state.openSpentType.select,
                        type:0,
                        loading: true
                    },
            };

            case spentConstants.ADD_SPENT_TYPE_SUCCESS:
                ToastSwall.fire({
                    icon: 'success',
                    title: 'Tipo Agregado'
                    })
                return {
                    ...state,
                    spentTypes: action.spentTypes,
                    openSpentType:{
                        open: false,
                        select : state.openSpentType.select,
                        type: 0,
                        loading:false
                    },
                };
            case spentConstants.ADD_SPENT_TYPE_FAILURE:
                return {
                    ...state,
                    openSpentType:{
                        open: true,
                        select:action.spentType,
                        type:0,
                        loading: false
                    },
                    error: action.error,
                };
    
            case spentConstants.EDIT_SPENT_TYPE_REQUEST:
                return {
                    ...state,
                    openSpentType:{
                        open: true,
                        select:state.openSpentType.select,
                        type:1,
                        loading: true
                    },
                };
    
            case spentConstants.EDIT_SPENT_TYPE_SUCCESS:
                ToastSwall.fire({
                    icon: 'success',
                    title: 'Tipo Editado'
                    })
                return {
                    ...state,
                    spentTypes: action.spentTypes,
                    openSpentType:{
                        open: false,
                        select : state.openSpentType.select,
                        type: 1,
                        loading:false
                    },
                };
            case spentConstants.EDIT_SPENT_TYPE_FAILURE:
                return {
                    ...state,
                    openSpentType:{
                        open: true,
                        select:action.spentType,
                        type:1,
                        loading: false
                    },
                    error: action.error,
                };

        default:
            return state
    }
}