import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container , Button, Grid, Box, Card, TextField, InputAdornment} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { customerActions } from '../../_actions/customer.actions';
import { SimpleDialog } from './components/dialog.customer';
import { Customer } from '../../models/customer/customer';
import SearchIcon from '@mui/icons-material/Search';
import { DebounceInput } from 'react-debounce-input';
import { filter } from '../../_helpers/filter';

function CustomerPage() {

    const dispatch = useDispatch();
    const  loading  = useSelector((state: any) => state.customers.loading);
    const  customers  = useSelector((state: any) => state.customers.items as Customer[]);
    const  alert  = useSelector((state: any) => state.alert.message);
    //  const [customersFilters, setCustomersFilters] = useState<Customer[]>(customers);
    const handleClickOpen = (row: Customer) => {
      dispatch(customerActions.setPrincipalCustomerScreen({
        open : true,
        select :row,
        type : 1,
        loading:false
      }));
    };


  //  useEffect(() => { 
  //      setCustomersFilters(customers)
  //   },[customers]);

    // reset login status
    useEffect(() => {
      if(customers.length == 0){
        dispatch(customerActions.getAll());
      }
    }, []);
    

    
    const handleClose = (value: string) => {
      dispatch(customerActions.setPrincipalCustomerScreen({
        open : false,
        select :new Customer(),
        type : 0,
        loading:false
      }));
    };

    const columns: GridColDef[] = [
      { field: 'customerID', headerName: 'Cliente ID', width: 130 },
      {
        field: 'name',
        headerName: 'Nombre',
        width: 200
      },
      {
        field: 'dni',
        headerName: 'Identidad',
        width: 160,
      },
      {
        field: 'phone',
        headerName: 'Teléfono',
        width: 140,
      },

      {
        field: 'income',
        headerName: 'Ingresos',
        width: 140,
      },
      {
        field: 'canPay',
        headerName: 'Puede Pagar',
        width: 160,
      },
    ];
    


    return (
        <Box my={2}>
            <Container  maxWidth="xl">
        <Card  variant="outlined" style={{padding:"5px 20px 25px 20px"}} >

            <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
                
                    <Box my={2} >
                                    <Button variant="contained" color="primary" onClick={()=>{
                                          var addressNew = new Customer();
                                          dispatch(customerActions.setPrincipalCustomerScreen({
                                            open: true,
                                            select :addressNew,
                                            type : 0,
                                            loading:false
                                          }));
                                        }
                                      }>
                                          Agregar
                                      </Button>
                                  </Box>
               
              </Grid>
           

                {alert && <Box my={2} >{alert}</Box>}

                  <Box my={1}>
                    <DebounceInput  style={{width:"100%"}} id="standard-basic" label="Buscador" 
                    variant="filled" size="small" debounceTimeout={400} element={TextField as any} 
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={event => {
                          let textSearch = (event.target?.value??"");
                          if(textSearch.length > 1){
                            dispatch(customerActions.searchByDecription(textSearch));
                          }
                      }} 

                    />
                </Box>
                <DataGrid autoHeight 
                    rows={customers}
                    loading= {loading}
                    getRowId={(r) => r.customerID}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    nonce="No se encontraron resultados"
                    density="compact"
                    
                    checkboxSelection={false}
                    onCellClick={(params, event) => {
                        console.log(params.row)
                        handleClickOpen(params.row as Customer)
                      }}
                      
                    disableSelectionOnClick
                />  
                   
         
        

              <SimpleDialog  onClose={handleClose} />

           </Card>
            </Container>
        </Box>
    );
}

export { CustomerPage };