import Dialog from '@mui/material/Dialog';
import { Box, Button, Checkbox, CircularProgress, DialogContent, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, ListItemText, MenuItem, Select, Slide, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataDigaloProps } from '../../models/data_digalo_props';
import { DialogTitle } from '../../_components/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import { User } from '../user/models/user';
import { Spent } from './models/spent';
import { spentActions } from './actions/spent.action';
import { format } from '../../_helpers/format';
import { NoRowsOverlay } from '../../_components/NoRowsOverlay';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { SpentType } from './models/spent_type';
import { SpentDetail } from './models/spent_detail';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export interface DialogAddressProps {
    onClose: (value: string) => void;
  }
  
  function DialogEditSpent(props: DialogAddressProps) {
    const { onClose} = props;
    const dispatch = useDispatch();
    const loading = useSelector((state: any) => state.spent.loading);
    const openSpent  = useSelector((state: any) => state.spent.openSpent as DataDigaloProps<Spent>);
    const spentTypes = useSelector((state:any)=> state.spent.spentTypes as SpentType[])
    const user = useSelector((state:any) => state.authentication.user as User);
    const [inputs, setInputs] = React.useState<Spent>(openSpent?.select);
    const [submitted, setSubmitted] = React.useState(false);
    
     useEffect(() => {
         setInputs(openSpent?.select)
         setSubmitted(false)
    }, [openSpent]);

    useEffect(() => {
      if((spentTypes??[]).length == 0){
        dispatch(spentActions.getSpentType);
      }
    }, []);

    const handleClose = () => {
      setInputs(new Spent())
      dispatch(spentActions.setOpenModalSpentScreen({
        open : false,
        select : new Spent(),
        type : 0,
        loading: false
      }));
      setSubmitted(false)
    };
  
  

    
    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      //TODO 
      if(inputs.description  && (inputs.detail??[]).length > 0){
        if(openSpent?.type ===0){
          inputs.createBy =user.userID;
          dispatch(spentActions.add(inputs));
        }
        if(openSpent?.type ===1){
          dispatch(spentActions.edit(inputs));
        }
      }
    }

    function addNewLine(e:any){
      e.preventDefault();
      var lineNumber = (inputs.detail??[]).length > 0? ((inputs.detail??[])[(inputs.detail??[]).length -1]?.lineNumber??0) +1 :1;
      inputs.detail = [...inputs.detail??[],new SpentDetail(
          {
            lineId: 0,
            lineNumber: lineNumber,
            spentId: 0,
            description: "",
            spentTypeId: 0,
            spentType:"",
            referent:"",
            createDate: new Date(),
            amount: 0,
          }
          ) ];
          let inputsLine = new Spent(inputs);
              setInputs(inputs => ({ ...inputs, "detail": inputsLine.detail??[] }));
          }



  function handleChange(e:any) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  const columns: GridColDef[] = [
    { field: 'lineNumber', headerName: '#', width: 50 },
    {
      field: 'description',
      headerName: 'Descripción',
      width: 300,
     renderCell: (params) => {
        const handleChange = (prop:any) =>{
            let index = (inputs.detail??[]).findIndex((x)=> x.lineNumber == params.row.lineNumber);
            if(index> -1){
            (inputs.detail??[])[index].description   = prop.target.value;
            setInputs(inputs => ({ ...inputs, "detail": inputs?.detail??[] }));
            }
        };
        return (
            <> <FormControl> <TextField id={params.row.lineNumber} value={params.row.description}  multiline={true} fullWidth={true}  sx={{ minWidth: 350 }}  variant="standard" onChange={handleChange}size="small"/></FormControl></>
        );
        },
    },
   
    {
      field: 'spentTypeId',
      headerName: 'Tipo/Categoría',
      width: 180,
      renderCell: (params) => {
        let currentRow = params.row as SpentDetail;
        const handleChange = (prop:any) =>{
          params.row.spentTypeId = prop.target.value;
          let amount : number = 0;
          (inputs.detail??[]).forEach((item)=>{
            amount += parseFloat((item?.amount??0).toString());
          })
          setInputs(inputs => ({ ...inputs, "amount": amount??0 }));

        };
        return (
            <> 
             <FormControl fullWidth>
              {/* <InputLabel  >
                  Tipo/Categoría
              </InputLabel> */}
                  <Select
                    value={currentRow.spentTypeId}
                    onChange={handleChange}
                    
                    label="Tipo"
                    size="small"
                    placeholder="Seleccione un Tipo"
                    name="spentTypeId"
                    error={(submitted && (currentRow.spentTypeId == undefined))}   
                    // renderValue={(selected) => ((selected ??[]) as SpentType[]).map((item: SpentType)=>{
                    //   return item.description
                    // }).join(', ')}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {
                      spentTypes.map((item: SpentType)=>{
                        return <MenuItem dense={true} value={item.spentTypeId}>
                            <ListItemText primary={item.description } />
                          </MenuItem>
                      })
                    }
                  </Select>
                  {
                    
                   (submitted && !currentRow?.spentTypeId) ? <FormHelperText error >Es requerido</FormHelperText>:""
                  }
                </FormControl>
            
            </>
        );
        },
    },
 
 

     {
        field: 'amount',
        headerName: 'Total',
        width: 120,
        //    valueFormatter: (params) => {
        //     if(params?.value){
        //     var data: number = parseFloat(params?.value as string);
        //     return "L. "+ format( data,2)
        //     } 
        // },
        renderCell: (params) => {
          const handleChange = (prop:any) =>{
              let index = (inputs.detail??[]).findIndex((x)=> x.lineNumber == params.row.lineNumber);
              if(index> -1){
              (inputs.detail??[])[index].amount   = prop.target.value;
              setInputs(inputs => ({ ...inputs, "detail": inputs?.detail??[] }));
              let amount: number = 0;
              (inputs.detail??[]).forEach((item)=>{
                amount += parseFloat((item?.amount??0).toString());
              })
              amount = parseFloat(amount.toString());
              setInputs(inputs => ({ ...inputs, "amount": amount??0 }));
              }
          };
          return (
              <> <FormControl> <TextField  id={params.row.lineNumber.toString()} type="number" value={params.row.amount} variant="standard" onChange={handleChange}size="small"/></FormControl></>
          );
          },
    },
    {
      field: 'referent',
      headerName: 'Referencia',
      width: 260,
     renderCell: (params) => {
        const handleChange = (prop:any) =>{
            let index = (inputs.detail??[]).findIndex((x)=> x.lineNumber == params.row.lineNumber);
            if(index> -1){
            (inputs.detail??[])[index].referent   = prop.target.value;
            setInputs(inputs => ({ ...inputs, "detail": inputs?.detail??[] }));
            }
        };
        return (
            <> <FormControl> <TextField id={params.row.lineNumber} value={params.row.referent}  multiline={true} fullWidth={true}  sx={{ minWidth: 350 }}  variant="standard" onChange={handleChange}size="small"/></FormControl></>
        );
        },
    },

    {
      field: 'createDate',
      headerName: 'Fecha',
      width: 160,
     renderCell: (params) => {
        const handleChange = (prop:any) =>{
            let index = (inputs.detail??[]).findIndex((x)=> x.lineNumber == params.row.lineNumber);
            if(index> -1){
            (inputs.detail??[])[index].createDate   = prop.target.value;
            setInputs(inputs => ({ ...inputs, "detail": inputs?.detail??[] }));
            }
        };
        return (
            <> <FormControl> <TextField id={params.row.lineNumber} type="date" value={params.row.createDate.toString().substr(0,10)} fullWidth={true}  sx={{ minWidth:150 }}  variant="standard" onChange={handleChange}size="small"/></FormControl></>
        );
        },
    },

      {
        field: 'status',
        headerName: 'Acción',
        width: 80,
        type:'text',
        renderCell: (params) => {
            const handleDeleteLine = (prop:any) =>{
                let index = (inputs.detail??[]).findIndex((x)=> x.lineNumber == params.row.lineNumber);
                if(index> -1){
                let inputsDetail = (inputs.detail??[]).filter(x=> x.lineNumber != params.row.lineNumber);
                //inputsDetail.splice(index, 1)
                setInputs(inputs => ({ ...inputs, "detail":inputsDetail }));
                }
            };
            return (
                <>
                    {/* <IconButton aria-label="delete"  color="primary"  onClick={handleDeleteLine} ><SaveIcon /></IconButton> */}
                    <IconButton aria-label="delete"  color="primary"  onClick={handleDeleteLine} ><DeleteIcon /></IconButton>
                </>
            );
            },
      },
  ];

    return (
      <Dialog  TransitionComponent={Transition} id="inventoryDialog"  fullWidth={true} maxWidth="lg" aria-labelledby="simple-dialog-title" open={openSpent?.open??false}>
        <DialogTitle onClose={handleClose}  id="simple-dialog-title" > {openSpent?.type ===0 ? "Agregar": "Editar"} Gasto </DialogTitle>
        <DialogContent dividers>
        {
          openSpent?.loading ?  <CircularProgress />:
        <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
        <Grid container spacing={3}>

        <Grid item  container direction="row"  justifyContent="flex-end" alignItems="flex-start"  >
            <Box  >
                    <Button type="submit" variant="contained" color="primary" onClick={(e)=>{
                    handleSubmit(e);
                    }}>
                    {openSpent?.type ===0 ? "Agregar": "Editar"} Gasto 
                    </Button>
                  </Box>
          </Grid>



        <Grid item xs={3}>
              <TextField
                    id="spentId"
                    label="No"
                    type="text"
                    placeholder="No"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    name="spentId"
                    disabled={ true }
                    value={inputs?.spentId}
                   
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid> 
        <Grid item xs={8}>
              <TextField
                    id="description"
                    label="Descripción"
                    type="text"
                    placeholder="Descripción"
                    fullWidth
                    size="small"    
                    onChange={handleChange}
                    name="description"
                    value={inputs?.description}
                    error={(submitted && !inputs?.description)}   helperText={(submitted && !inputs?.description) ? "Es Requerido":""}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid> 
            <Grid item xs={12}>
            <Stack sx={{ width: '100%', mb: 1 }} direction="row" alignItems="flex-start" columnGap={1} >
                    <Button size="small" onClick={(e)=>{ addNewLine(e);}}>
                    Nueva Linea
                    </Button>
                </Stack>
                <DataGrid autoHeight 
                    rows={inputs?.detail??[]}
                    loading= {loading}
                    getRowId={(r) => r.lineNumber}
                    columns={columns}
                    components={{
                        NoRowsOverlay: NoRowsOverlay,
                      }}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    nonce="No se encontraron resultados"
                    density="compact"
                    checkboxSelection={false}
                    // onCellClick={(params, event) => {
                    //     console.log(params.row)
                    //     handleClickOpen(params.row as Customer)
                    //   }}
                      
                    disableSelectionOnClick
                />
                    
            </Grid>
            <Grid item xs={3} my={2}> 
                        <div className="resume-total">
                            <div className="total">
                                   <Typography variant="h6" component="h2">
                                   Total
                                    </Typography>
                          
                                <span className="description">
                                    <Typography variant="h6" component="h6">
                                    {"L. "+ format(inputs?.amount??0,2)} 
                                    </Typography>
                                </span>              
                            </div>
                        </div>
                        </Grid>
          
            {/* <Grid item xs={12}>
                {
                inputs?.apkName?
                <Box>
                  <img width={40} height={40} src="https://upload.wikimedia.org/wikipedia/commons/4/41/APK_format_icon_%282014-2019%29.png"/>
                  <br/>
                    <Button variant="text" component="label">
                          Cambiar APK
                          <input onChange={handleCapture} type="file" accept=".apk" hidden />
                    </Button>
                </Box>
                  :
                  <Button variant="text" component="label">
                        Agregar Apk
                        <input onChange={handleCapture} type="file" accept=".apk" hidden />
                  </Button>
              }
            </Grid> 
            */}

            </Grid>
                  
                  </form>        
              }
        </DialogContent>
      </Dialog>
    );
  }
  
  
  export { DialogEditSpent };
