export const settingConstants = {

    GETALL_REQUEST: 'GETALL_REQUEST',
    GETALL_SUCCESS: 'GETALL_SUCCESS',
    GETALL_FAILURE: 'GETALL_FAILURE',
    
    EDIT_SETTING_REQUEST: 'EDIT_SETTING_REQUEST',
    EDIT_SETTING_SUCCESS: 'EDIT_SETTING_SUCCESS',
    EDIT_SETTING_FAILURE: 'EDIT_SETTING_FAILURE',

    SET_OPEN_SETTING_SUCCESS: 'SET_OPEN_SETTING_SUCCESS' , 

};