import { combineReducers } from "redux";
import {alert} from "./alert.reducer";
import {customers} from "./customers.reducer";
import {authentication} from "./authentication.reducer";
import { inscriptions } from "./inscription.reducer";
import { payments } from "./payment.reducer";
import { users } from "../pages/user/reducers/user.reducer";
import { hierarchy } from "../pages/hierarchy/reducers/hierarchy.reducer";
import { setting } from "../pages/general_settings/reducers/setting.reducer";
import { report } from "../pages/reports/reducers/report.reducer";
import { publishs } from "../pages/publish/reducers/publish.reducer";
import { spent } from "../pages/spents/reducers/spent.reducer";

export default combineReducers({
    inscriptions,
    customers,
    alert,
    authentication,
    payments,
    hierarchy,
    setting,
    report,
    users,
    publishs,
    spent
});
