const dev = {
    //apiUri: "http://localhost:50209"
    apiUri: "https://api.rapicredit.app"
  };
  
  const prod = {
    apiUri: "https://api.rapicredit.app"
  };
  
  const environment = process.env.NODE_ENV === 'production'
    ? prod
    : dev;
  
  export default {
    ...environment
  };
