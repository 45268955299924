import { AddressImage } from "./address_image";
import { CustomerAddress } from "./customer_address";
import { Guarantee } from "./guarantee";
import { PersonalReferent } from "./personal_referent";

export class Customer {
    customerID?: string;
    name?: string;
    dni?: string;
    birthday?: Date;
    income?: number;
    canPay?: number;
    phone?: number;
    email?: string;
    photo?: string;
    createDate?: number;
    active?: boolean;
    personalReferent?: PersonalReferent[];
    address?: CustomerAddress[];
    guarantee?: Guarantee[]
    images?: AddressImage[];
    
    constructor(datos?: Customer) {
      if (datos != null) {
        this.customerID = datos.customerID;
        this.name = datos.name;
        this.dni = datos.dni;
        this.birthday = datos.birthday;
        this.address = datos.address;
        this.income = datos.income;
        this.canPay = datos.canPay;
        this.phone = datos.phone;
        this.email = datos.email;
        this.photo = datos.photo;
        this.createDate = datos.createDate;
        this.active = datos.active;
        this.personalReferent = datos.personalReferent;
        this.guarantee = datos.guarantee;
        this.images = datos.images;
        return;
      }
       
    }
  }
