export class SpentType {
    spentTypeId?: number;
    description?: string;
    createBy?: number;
    active?: boolean;

    
    constructor(datos?: SpentType) {
      if (datos != null) {
        this.spentTypeId = datos.spentTypeId;
        this.description = datos.description;
        this.createBy = datos.createBy;
        this.active = datos.active;
        return;
      }
    }
  }
