import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container , CircularProgress, Box, TextField, Grid, Button, InputAdornment, Typography, Card} from '@mui/material';
import React from 'react';
import { settingActions } from './actions/setting.action';
import { Setting } from './models/setting';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import PhoneIcon from '@mui/icons-material/Phone';
import PolicyIcon from '@mui/icons-material/Policy';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import FeedIcon from '@mui/icons-material/Feed';

function SettingPage() {
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.setting.loading);
  const setting = useSelector((state: any) => state.setting.setting as Setting);
  const alert = useSelector((state: any) => state.alert.message);
  const [submitted, setSubmitted] = React.useState(false);
  const [inputs, setInputs] = React.useState<Setting>(setting);

    useEffect(() => {
      setInputs(setting)
      setSubmitted(false)
    }, [setting]);

    useEffect(() => {
      dispatch(settingActions.getAll());
      setSubmitted(false)
    }, []);
     
    function handleChange(e:any) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e:any) {
      e.preventDefault();
      setSubmitted(true);
      if(inputs.name){
        dispatch(settingActions.edit(inputs));
      }
    }
   
    return (
        <Box my={2}>
            <Container maxWidth="md" >
                <Card  variant="outlined" style={{padding:"5px 20px 25px 20px"}} >

              {alert && <Box my={2} >{alert}</Box>}
              {
                loading? 
                <Box my={2}>
                    <CircularProgress title="Cargando..." />
                </Box>
                :
                <Container >
                    
                    <Box my={3}>
                      <Typography variant="h5" gutterBottom component="div">
                        Datos Generales
                      </Typography>
                    </Box>
                    <form  onSubmit={handleSubmit}  noValidate autoComplete="off">
                      <Grid container spacing={3}>
                        <Grid item xs={8}>
                            <TextField
                                  id="name"
                                  label="Nombre"
                                  type="text"
                                  placeholder="Nombre"
                                  fullWidth
                                  size="small"
                                  onChange={handleChange}
                                  name="name"
                                  value={inputs?.name}
                                  error={(submitted && !inputs?.name)}   helperText={(submitted && !inputs?.name) ? "Es Requerido":""}
                                  margin="normal"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <BusinessIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                          </Grid> 
                          <Grid item xs={4}>
                                <TextField
                                      id="phone"
                                      label="Teléfono"
                                      type="text"
                                      placeholder="Teléfono"
                                      fullWidth
                                      size="small"    
                                      onChange={handleChange}
                                      name="phone"
                                      value={inputs?.phone}
                                      error={(submitted && !inputs?.phone)}   helperText={(submitted && !inputs?.phone) ? "Es Requerido":""}
                                      margin="normal"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <PhoneIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                            </Grid> 
                            <Grid item xs={4}>
                                <TextField
                                      id="rtn"
                                      label="RTN"
                                      type="text"
                                      placeholder="RTN"
                                      fullWidth
                                      size="small"    
                                      onChange={handleChange}
                                      name="rtn"
                                      value={inputs?.rtn}
                                      error={(submitted && !inputs?.rtn)}   helperText={(submitted && !inputs?.rtn) ? "Es Requerido":""}
                                      margin="normal"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <PolicyIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                            </Grid> 
                            <Grid item xs={4}>
                                <TextField
                                      id="email"
                                      label="Correo"
                                      type="text"
                                      placeholder="Correo"
                                      fullWidth
                                      size="small"    
                                      onChange={handleChange}
                                      name="email"
                                      value={inputs?.email}
                                      error={(submitted && !inputs?.email)}   helperText={(submitted && !inputs?.email) ? "Es Requerido":""}
                                      margin="normal"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <EmailIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                            </Grid> 
                            <Grid item xs={4}>
                                <TextField
                                      id="interestPercent"
                                      label="Porcentaje de Interes"
                                      type="number"
                            
                                      placeholder="Porcentaje de Interes"
                                      fullWidth
                                      size="small"    
                                      onChange={handleChange}
                                      name="interestPercent"
                                      value={inputs?.interestPercent}
                                      error={(submitted && !inputs?.interestPercent)}   helperText={(submitted && !inputs?.interestPercent) ? "Es Requerido":""}
                                      margin="normal"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <AutoGraphIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                      id="address"
                                      label="Dirección"
                                      type="text"
                                      rows={2}
                                      multiline={true}
                                      placeholder="Dirección"
                                      fullWidth
                                      size="small"    
                                      onChange={handleChange}
                                      name="address"
                                      value={inputs?.address}
                                      error={(submitted && !inputs?.address)}   helperText={(submitted && !inputs?.address) ? "Es Requerido":""}
                                      margin="normal"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <EditLocationAltIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                            </Grid> 
                            <Grid item xs={4}>
                                <TextField
                                      id="fine"
                                      label="Multa"
                                      type="number"
                            
                                      placeholder="Multa"
                                      fullWidth
                                      size="small"    
                                      onChange={handleChange}
                                      name="fine"
                                      value={inputs?.fine}
                                      error={(submitted && !inputs?.fine)}   helperText={(submitted && !inputs?.fine) ? "Es Requerido":""}
                                      margin="normal"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <FeedIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                            </Grid>
                     
                          </Grid>
                        <Box my={3} >
                          <Button type="submit" variant="contained" color="primary">
                              Guardar 
                          </Button>
                         </Box>
                    </form>   


                </Container>
              }
             {/* <DialogEditAdviser onClose={handleClose} /> 
             <DialogEditSupervisor onClose={handleClose} />  */}
            </Card>
            </Container>
        </Box>
    );
}

export { SettingPage };
