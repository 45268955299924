import { Payment } from "../../../models/payment/payment";
import { ToastSwall } from "../../../_helpers/common";
import { reportConstants } from "../constants/report.constants";
import { FinancialState } from "../models/financial_state";
import { Placing } from "../models/placing";
import { RecoverPay } from "../models/recover_pay";
import { ReportStateModel } from "../models/report_state_model";


const initialState: ReportStateModel = { 
    loading: false, 
    error: "",
    reportPayment:  Array<Payment>(),
    reportPlacing:  Array<Placing>(),
    reportRecoverPay:  Array<RecoverPay>(),
    financialState: new FinancialState()

    // setting: new Setting(),
    // openSetting: {
    //     open: false,
    //     select: {},
    //     type: 0,
    //     loading: false,
    // },
} ;

export function report(state = initialState, action:any) :ReportStateModel {
    switch (action.type) {

        // case settingConstants.SET_OPEN_SETTING_SUCCESS:
        //     return {
        //         ...state,
        //         openSetting:action.openSetting
        //     };


        case reportConstants.GET_FINANCIAL_STATE_BY_DATE_REQUEST:     
            return {
                ...state,
                loading: true,
            };
        case reportConstants.GET_FINANCIAL_STATE_BY_DATE_SUCCESS:
            return {
                ...state,
                loading: false,
                financialState: action.financialState
            };
        case reportConstants.GET_FINANCIAL_STATE_BY_DATE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };

        case reportConstants.GET_PAYMENT_BY_DATE_REQUEST:     
            return {
                ...state,
                loading: true,
                reportPayment: [],
            };
        case reportConstants.GET_PAYMENT_BY_DATE_SUCCESS:
            return {
                ...state,
                loading: false,
                reportPayment: action.reportPayment
            };
        case reportConstants.GET_PAYMENT_BY_DATE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };


        
        case reportConstants.GET_PLACING_BY_DATE_REQUEST:     
            return {
                ...state,
                loading: true,
                reportPlacing: [],
            };
        case reportConstants.GET_PLACING_BY_DATE_SUCCESS:
            return {
                ...state,
                loading: false,
                reportPlacing: action.reportPlacing
            };
        case reportConstants.GET_PLACING_BY_DATE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };


        case reportConstants.GET_RECOVER_BY_DATE_REQUEST:     
            return {
                ...state,
                loading: true,
                reportRecoverPay: [],
            };
        case reportConstants.GET_RECOVER_BY_DATE_SUCCESS:
            return {
                ...state,
                loading: false,
                reportRecoverPay: action.reportRecoverPay
            };
        case reportConstants.GET_RECOVER_BY_DATE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };


        default:
            return state
    }
}